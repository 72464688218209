import { LaundryUnitForm } from "@/components/LaundryUnitForm";
import { Action } from "@/models/actions/Action";
import { ActionControls } from "@/models/actions/ActionControls";
import { LaundryUnit } from "@/models/LaundryUnit";
import { notification } from "antd";

export class EditLaundryUnitAction implements Action {
	constructor(
		private readonly laundryUnit?: LaundryUnit,
		private readonly onLaundryUnitEdited?: (user: LaundryUnit) => void
	) {}

	getOptions() {
		return {
			width: "60vw",
			title: this.laundryUnit ? "Editar unidade" : "Adicionar unidade",
		};
	}

	render(controls: ActionControls) {
		return (
			<LaundryUnitForm
				laundryUnit={this.laundryUnit}
				onSubmitting={() => controls.setCloseable(false)}
				onSubmitted={(laundryUnit) => this.onSuccess(laundryUnit, controls)}
				onSubmissionFailed={(error) => this.onFailure(error, controls)}
			/>
		);
	}

	private onSuccess(laundryUnit: LaundryUnit, controls: ActionControls) {
		controls.setCloseable(true);
		controls.close();

		notification.success({ message: "Unidade salva com sucesso!" });

		this.onLaundryUnitEdited?.(laundryUnit);
	}

	private onFailure(_error: Error, controls: ActionControls) {
		controls.setCloseable(true);

		notification.error({ message: "Erro ao salvar unidade" });
	}
}
