import { EditLaundryAction } from "@/actions/EditLaundryAction";
import { useHttp } from "@/application/HttpClient";
import { useStatusContext } from "@/contexts/Status";
import { Laundry } from "@/models/Laundry";
import { laundryService } from "@/services/laundry";
import { notification } from "antd";
import { useCallback, useState } from "react";
import { LaundryListProps } from "./types";
import { LaundryListView } from "./view";
import { ShowUnitsByLaundryAction } from "@/actions/ShowUnitsByLaundryAction";

export const LaundryList = ({
	onLaundryEdited,
	onLaundryDeleted,
	handleSortChange,
	handleFilterChange,
	...props
}: LaundryListProps) => {
	const { request } = useHttp();
	const { onActionChange } = useStatusContext();

	const [submitting, setSubmitting] = useState<boolean>(false);

	const onEditLaundry = useCallback(
		(laundry: Laundry) => {
			onActionChange({
				action: new EditLaundryAction(laundry),
				onActionEnd: () => onLaundryEdited?.(),
			});
		},
		[onActionChange, onLaundryEdited]
	);

	const onSort = useCallback((field: string, sortOrder: string) => {
		handleSortChange(field, sortOrder);
		onLaundryEdited?.();
	}, []);

	const onFilter = useCallback((field: string, text: string) => {
		handleFilterChange(field, text);
		onLaundryEdited?.();
	}, []);


	const onDeleteLaundry = useCallback(
		(laundry: Laundry) => {
			setSubmitting(true);
			request(laundryService.delete, { laundryId: laundry.id })
				.then(() => onLaundryDeleted?.())
				.catch(() => notification.error({ message: "Erro ao remover Franquia" }))
				.finally(() => setSubmitting(false));
		},
		[onLaundryDeleted, request]
	);

	const onShowUnits = useCallback(
		(laundry: Laundry) => {
			onActionChange({
				action: new ShowUnitsByLaundryAction(laundry),
				onActionEnd: () => onLaundryEdited?.(),
			});
		},
		[onActionChange, onLaundryEdited]
	);


	return (
		<LaundryListView
			onShowUnits={onShowUnits}
			loading={props.loading || submitting}
			laundries={props.laundries}
			onEditLaundry={onEditLaundry}
			onDeleteLaundry={onDeleteLaundry}
			onSort={onSort}
			onFilter={onFilter}
		/>
	);
};
