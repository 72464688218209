import { useHttp } from "@/application/HttpClient";
import { LaundryUnit } from "@/models/LaundryUnit";
import { useCallback, useEffect, useMemo, useState } from "react";
import { LaundryUnitFormData } from "../types";
import { DeviceIdentification } from "@/models/DeviceIdentification";
import { LaundryUnitIdentification } from "@/pages/RegisterClientPage/types";
import { customerService } from "@/services/customer";

interface SubmitHookProps {
	readonly businessTimes?: DeviceIdentification;
	readonly identification?: LaundryUnitIdentification;

	readonly onSubmitting?: () => void;
	readonly onSubmitted?: (laundryUnit: LaundryUnit) => void;
	readonly onSubmissionFailed?: (error: Error) => void;
}

export const useSubmit = (props: SubmitHookProps) => {
	const { request } = useHttp();
	const { onSubmissionFailed, onSubmitted, onSubmitting } = props;

	const [submitting, setSubmitting] = useState<boolean>(false);
	const [submitRequested, setSubmitRequested] = useState<boolean>(false);

	const formData = useMemo<LaundryUnitFormData | undefined>(() => {

		const businessTimesIsInvalid = props.businessTimes === undefined;
		const identificationIsInvalid = props.identification === undefined;

		if (businessTimesIsInvalid || identificationIsInvalid) return undefined;

		return {
			...props.identification,
			deviceIdentification: props.businessTimes,
		};
	}, [props.businessTimes, props.identification]);

	const submit = useCallback(() => setSubmitRequested(true), []);

	useEffect(() => {
		if (formData !== undefined && submitRequested) {
			setSubmitting(true);
			setSubmitRequested(false);
			onSubmitting?.();

			const doRequest = () => request(customerService.preRegister, formData);

			doRequest()
				.then((laundryUnit) => onSubmitted?.(laundryUnit))
				.catch((error) => {
					console.log({error})
					onSubmissionFailed?.(error)
				})
				.finally(() => setSubmitting(false));
		}
	}, [formData, onSubmissionFailed, onSubmitted, onSubmitting, request, submitRequested]);

	return { submit, submitting };
};
