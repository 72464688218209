import styled from "styled-components";

export const Wrapper = styled.div`
	height: 95px;
	padding: 10px 2em;
	box-sizing: border-box;
	background-color: #fafafa;
	border-top: 1px solid #f2f2f2;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
`;
