import { RecipientForm } from "@/components/RecipientForm";
import { Action } from "@/models/actions/Action";
import { ActionControls } from "@/models/actions/ActionControls";
import { ChemicalRecipient } from "@/models/ChemicalRecipient";
import { notification } from "antd";

export class EditRecipientAction implements Action {
	constructor(private readonly recipient?: ChemicalRecipient) {}

	getOptions() {
		return {
			width: "40vw",
			title: this.recipient ? "Editar Recipiente" : "Adicionar Recipiente",
		};
	}

	render(controls: ActionControls) {
		return (
			<RecipientForm
				recipient={this.recipient}
				onSubmitting={() => controls.setCloseable(false)}
				onSubmitted={(recipient) => this.onSuccess(recipient, controls)}
				onSubmissionFailed={(error) => this.onFailure(error, controls)}
			/>
		);
	}

	private onSuccess(_pump: ChemicalRecipient, controls: ActionControls) {
		controls.setCloseable(true);
		controls.close();

		notification.success({ message: "Recipiente salva com sucesso!" });
	}

	private async onFailure(error: any, controls: ActionControls) {

		if (error?.response?.status === 406) {
			const errorMessage = await error?.response.json()
			controls.setCloseable(true);
			notification.error({ message: errorMessage.message });
			return
		}
		controls.setCloseable(true);

		notification.error({ message: "Erro ao salvar Recipiente" });
	}
}
