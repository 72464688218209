import { LaundrySelectInput } from "@/components/LaundrySelectInput";
import { Laundry } from "@/models/Laundry";
import { required } from "@/models/rules";
import { Button, Form, Input, Row, Space } from "antd";
import { LaundryUnitIdentificationFormViewProps } from "./types";
import { useIdentityContext } from "@/contexts/Identity";
import { PhoneNumberInput } from "@/components/PhoneNumberInput";

type ComponentType = React.FC<LaundryUnitIdentificationFormViewProps>;
export const LaundryUnitIdentificationFormView: ComponentType = (props) => {
	const { user } = useIdentityContext();

	const validateEmail = (rule: any, value: any, callback: any) => {
		if (value && !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
			callback("Por favor, insira um email válido.");
		} else {
			callback();
		}
	};

	const validatePhone = (rule: any, value: any, callback: any) => {

		const numericValue = value?.replace(/\D/g, '');

		if (numericValue?.length === 10 || numericValue?.length === 11) {
			callback();
		}else if (value && !/^\(\d{2}\)\s\d{4,5}-\d{4}$/.test(value)) {
			console.log({value})
			callback("Por favor, insira um telefone válido.");
		} else {
			callback();
		}
	};

	return (
		<Form
			layout="vertical"
			form={props.form}
			onFinish={props.onSubmit}
			initialValues={props.initialValue}
		>
			<Form.Item name="name" label="Nome" rules={[required]}>
				<Input />
			</Form.Item>

			<Form.Item
				name="laundryId"
				label="Franquia"
				normalize={(laundry?: Laundry) => laundry?.id}
				rules={[required]}
			>
				<LaundrySelectInput customerId={user?.scopedCustomer?.id} />
			</Form.Item>

			<Form.Item
				name="emailReport"
				label="Email Relatório"
				rules={[{ validator: validateEmail }]}
			>
				<Input />
			</Form.Item>

			<Form.Item name="phone" label="Telefone" rules={[{ validator: validatePhone }]}>
				<PhoneNumberInput />
			</Form.Item>

			<Form.Item name="secondaryPhone" label="Telefone Secundário" rules={[{ validator: validatePhone }]}>
				<PhoneNumberInput />
			</Form.Item>

			<Row justify="end">
				<Space>
					{props.onCancel && <Button onClick={props.onCancel}>Cancelar</Button>}

					<Button type="primary" htmlType="submit">
						Salvar
					</Button>
				</Space>
			</Row>
		</Form>
	);
};
