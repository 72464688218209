import {
	Button,
	Card,
	Input,
	Space,
	Table,
	Select,
	DatePicker,
	Checkbox,
	Divider,
	Pagination,
	Tag,
} from "antd";
import { DashboardDeviceModuleContentViewProps } from "./types";
import { useState } from "react";
import { formatDateTime } from "@/utilities/format/date";
import { emailTermStatusToString } from "@/utilities/format/optional";
import locale from "antd/es/date-picker/locale/pt_BR";
import { DeviceStatusTagDefinition } from "@/components/DeviceList/types";
import { DeviceStatus } from "@/models/DeviceStatus";
import { DeviceInfo } from "@/models/DeviceInfo";
import {
	CheckCircleOutlined,
	CloseCircleOutlined,
	InfoCircleOutlined,
	MinusCircleOutlined,
	SearchOutlined,
} from "@ant-design/icons";
const { Option } = Select;

const tags: DeviceStatusTagDefinition = {
	[DeviceStatus.Idle]: (
		<Tag color="warning" icon={<MinusCircleOutlined />}>
			Sem comunicação
		</Tag>
	),
	[DeviceStatus.Available]: (
		<Tag color="default" icon={<InfoCircleOutlined />}>
			Pronto para associar
		</Tag>
	),
	[DeviceStatus.NotConfigured]: (
		<Tag color="warning" icon={<InfoCircleOutlined />}>
			Pronto para configurar
		</Tag>
	),
	[DeviceStatus.Operational]: (
		<Tag color="success" icon={<CheckCircleOutlined />}>
			Em funcionamento
		</Tag>
	),
	[DeviceStatus.Error]: (
		<Tag color="error" icon={<CloseCircleOutlined />}>
			Erro
		</Tag>
	),
};

type ComponentType = React.FC<DashboardDeviceModuleContentViewProps>;
export const DashboardDeviceModuleContentView: ComponentType = (props) => {
	const { devices, loading, total } = props;

	const [sortOrder, setSortOrder] = useState<any | null>(null);

	const [searchText, setSearchText] = useState("");

	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		props.onFilter(dataIndex, selectedKeys[0]);
	};

	const handleSearchEnum = (selectedKeys, confirm, dataIndex) => {
		confirm();
		props.onFilter(dataIndex, selectedKeys);
	};

	const handleReset = (clearFilters) => {
		clearFilters();
		setSearchText("");
		props.onFilter("", "");
	};

	const handleSort = (field: string, order: "ascend" | "descend") => {
		if (sortOrder?.field === field && sortOrder?.order === order) {
			setSortOrder({ field, order: order === "ascend" ? "descend" : "ascend" });
		} else {
			setSortOrder({ field, order });
		}

		props.onSort(field, order === "descend" ? "desc" : "asc");
	};

	const columns = [
		{
			title: "Código",
			dataIndex: "address",
			key: "1",
		},

		{
			title: "Status",
			dataIndex: "address",
			key: "2",
		},

		{
			title: "Qtd Acionamentos",
			dataIndex: "address",
			key: "3",
		},
		{
			title: "Média Acionamentos",
			dataIndex: "address",
			key: "4",
		},
		{
			title: "Média Volume",
			dataIndex: "address",
			key: "5",
		},
		{
			title: "Última Calibração",
			dataIndex: "address",
			key: "6",
		},
		{
			title: "Último Acionamento",
			dataIndex: "address",
			key: "7",
		},
		{
			title: "Nome",
			dataIndex: "nome",
			key: "8",
		},
		{
			title: "É trial?",
			dataIndex: "address",
			key: "9",
		},

		{
			title: "Unidade",
			dataIndex: "address",
			key: "10",
		},
		{
			title: "Franquia",
			dataIndex: "address",
			key: "11",
		},
		{
			title: "Cliente",
			dataIndex: "address",
			key: "12",
		},
		{
			title: "Email",
			dataIndex: "address",
			key: "13",
		},
	];

	const options = columns.map(({ key, title }) => ({
		label: title,
		value: key,
	}));

	const defaultCheckedList = columns.map((item) => item.key);

	const [checkedList, setCheckedList] = useState<string[]>(defaultCheckedList.slice(0, 7));

	const newColumns = columns.map((item) => ({
		...item,
		hidden: !checkedList.includes(item.key),
	}));

	const handlePageChange = (page: number) => {
		props.onPagination(page);
	};

	return (
		<div style={{ height: "calc(100vh - 170px)", overflow: "auto" }}>
			<Card style={{ height: "100%" }} bodyStyle={{ height: "100%" }}>
				<Divider>Colunas exibidas</Divider>
				<Checkbox.Group
					value={checkedList}
					onChange={(value) => {
						setCheckedList(value.map(String));
					}}
					style={{ width: "100%" }}
				>
					<div style={{ columnCount: 2 }}>
						{options.map((option, index) => (
							<Checkbox key={index} value={option.value}>
								{option.label}
							</Checkbox>
						))}
					</div>
				</Checkbox.Group>

				<Divider></Divider>

				<Table
					rowKey="id"
					title={() => "Equipamentos"}
					dataSource={devices}
					loading={loading}
					style={{ width: "100%" }}
					pagination={false}
				>
					{!newColumns[0].hidden && (
						<Table.Column
							showSorterTooltip={false}
							dataIndex="serialCode"
							title="Código"
							sorter={true}
							onHeaderCell={() => ({
								onClick: () =>
									handleSort("serialCode", sortOrder?.order || "ascend"),
							})}
							filterDropdown={({
								setSelectedKeys,
								selectedKeys,
								confirm,
								clearFilters,
							}) => (
								<div style={{ padding: 8 }}>
									<Input
										placeholder={`Buscar por código`}
										value={selectedKeys[0]?.toString()}
										onChange={(e) =>
											setSelectedKeys(e.target.value ? [e.target.value] : [])
										}
										onPressEnter={() =>
											handleSearch(selectedKeys, confirm, "serialCode")
										}
										style={{ width: 188, marginBottom: 8, display: "block" }}
									/>
									<Space>
										<Button
											type="primary"
											onClick={() =>
												handleSearch(selectedKeys, confirm, "serialCode")
											}
											icon={<SearchOutlined />}
											size="small"
											style={{ width: 90 }}
										>
											Buscar
										</Button>
										<Button
											onClick={() => handleReset(clearFilters)}
											size="small"
											style={{ width: 90 }}
										>
											Limpar
										</Button>
									</Space>
								</div>
							)}
							filterIcon={() => {
								return (
									<SearchOutlined
										style={{ color: searchText ? "#1890ff" : "#00000073" }}
									/>
								);
							}}
						/>
					)}

					{!newColumns[7].hidden && (
						<Table.Column
							showSorterTooltip={false}
							dataIndex="name"
							title="Nome"
							sorter={true}
							onHeaderCell={() => ({
								onClick: () => handleSort("name", sortOrder?.order || "ascend"),
							})}
							filterDropdown={({
								setSelectedKeys,
								selectedKeys,
								confirm,
								clearFilters,
							}) => (
								<div style={{ padding: 8 }}>
									<Input
										placeholder={`Buscar por nome`}
										value={selectedKeys[0]?.toString()}
										onChange={(e) =>
											setSelectedKeys(e.target.value ? [e.target.value] : [])
										}
										onPressEnter={() =>
											handleSearch(selectedKeys, confirm, "name")
										}
										style={{ width: 188, marginBottom: 8, display: "block" }}
									/>
									<Space>
										<Button
											type="primary"
											onClick={() =>
												handleSearch(selectedKeys, confirm, "name")
											}
											icon={<SearchOutlined />}
											size="small"
											style={{ width: 90 }}
										>
											Buscar
										</Button>
										<Button
											onClick={() => handleReset(clearFilters)}
											size="small"
											style={{ width: 90 }}
										>
											Limpar
										</Button>
									</Space>
								</div>
							)}
							filterIcon={() => {
								return (
									<SearchOutlined
										style={{ color: searchText ? "#1890ff" : "#00000073" }}
									/>
								);
							}}
						/>
					)}
					{!newColumns[1].hidden && (
						<Table.Column
							showSorterTooltip={false}
							sorter={true}
							title="Status"
							// dataIndex="status"
							render={(status, record: DeviceInfo) => {
								if (record?.isTrial) {
									return record.statusEmail
										? emailTermStatusToString(record.statusEmail)
										: "Desconhecido";
								} else {
									return (
										tags[record.deviceStatus] ?? (
											<Tag color="error" icon={<CloseCircleOutlined />}>
												{status}
											</Tag>
										)
									);
								}
							}}
							onHeaderCell={() => ({
								onClick: () => handleSort("status", sortOrder?.order || "ascend"),
							})}
							filterDropdown={({
								setSelectedKeys,
								selectedKeys,
								confirm,
								clearFilters,
							}) => (
								<div style={{ padding: 8 }}>
									<Space>
										<Select
											placeholder="Selecionar status"
											style={{
												width: 350,
												marginBottom: 8,
												display: "block",
											}}
											value={selectedKeys[0]}
											onChange={(value) => {
												setSelectedKeys(value ? [value] : []);
												handleSearchEnum(value, confirm, "status");
											}}
										>
											<Option value="WAITING">Aguardando</Option>
											<Option value="ACCEPT">Aceito</Option>
											<Option value="REFUSE">Recusado</Option>
											<Option value="DONT_REQUEST">Não solicitei</Option>
											<Option value="UNKNOWN">Desconhecido</Option>
										</Select>
										<Button
											onClick={() => handleReset(clearFilters)}
											size="small"
											style={{ width: 90 }}
										>
											Limpar
										</Button>
									</Space>
								</div>
							)}
							filterIcon={() => {
								return (
									<SearchOutlined
										style={{ color: searchText ? "#1890ff" : "#00000073" }}
									/>
								);
							}}
						/>
					)}

					{!newColumns[8].hidden && (
						<Table.Column
							showSorterTooltip={false}
							sorter={true}
							title="É trial?"
							dataIndex="isTrial"
							render={(value) => {
								return value ? "Sim" : "Não";
							}}
							// onHeaderCell={() => ({
							// 	onClick: () =>
							// 		handleSort("status", sortOrder?.order || "ascend"),
							// })}
							filterDropdown={({
								setSelectedKeys,
								selectedKeys,
								confirm,
								clearFilters,
							}) => (
								<div style={{ padding: 8 }}>
									<Space>
										<Select
											placeholder="Selecionar"
											style={{
												width: 350,
												marginBottom: 8,
												display: "block",
											}}
											value={selectedKeys[0]}
											onChange={(value) => {
												setSelectedKeys(value ? [value] : []);
												handleSearchEnum(value, confirm, "isTrial");
											}}
										>
											<Option value="Sim">Sim</Option>
											<Option value="Não">Não</Option>
										</Select>
										<Button
											onClick={() => handleReset(clearFilters)}
											size="small"
											style={{ width: 90 }}
										>
											Limpar
										</Button>
									</Space>
								</div>
							)}
							filterIcon={() => {
								return (
									<SearchOutlined
										style={{ color: searchText ? "#1890ff" : "#00000073" }}
									/>
								);
							}}
						/>
					)}

					{!newColumns[9].hidden && (
						<Table.Column
							showSorterTooltip={false}
							dataIndex="unitName"
							title="Unidade"
							sorter={true}
							onHeaderCell={() => ({
								onClick: () => handleSort("unitName", sortOrder?.order || "ascend"),
							})}
							filterDropdown={({
								setSelectedKeys,
								selectedKeys,
								confirm,
								clearFilters,
							}) => (
								<div style={{ padding: 8 }}>
									<Input
										placeholder={`Buscar por unidade`}
										value={selectedKeys[0]?.toString()}
										onChange={(e) =>
											setSelectedKeys(e.target.value ? [e.target.value] : [])
										}
										onPressEnter={() =>
											handleSearch(selectedKeys, confirm, "unitName")
										}
										style={{ width: 188, marginBottom: 8, display: "block" }}
									/>
									<Space>
										<Button
											type="primary"
											onClick={() =>
												handleSearch(selectedKeys, confirm, "unitName")
											}
											icon={<SearchOutlined />}
											size="small"
											style={{ width: 90 }}
										>
											Buscar
										</Button>
										<Button
											onClick={() => handleReset(clearFilters)}
											size="small"
											style={{ width: 90 }}
										>
											Limpar
										</Button>
									</Space>
								</div>
							)}
							filterIcon={() => {
								return (
									<SearchOutlined
										style={{ color: searchText ? "#1890ff" : "#00000073" }}
									/>
								);
							}}
						/>
					)}

					{!newColumns[10].hidden && (
						<Table.Column
							showSorterTooltip={false}
							dataIndex="franchiseeName"
							title="Franquia"
							sorter={true}
							onHeaderCell={() => ({
								onClick: () =>
									handleSort("franchiseeName", sortOrder?.order || "ascend"),
							})}
							filterDropdown={({
								setSelectedKeys,
								selectedKeys,
								confirm,
								clearFilters,
							}) => (
								<div style={{ padding: 8 }}>
									<Input
										placeholder={`Buscar franquia`}
										value={selectedKeys[0]?.toString()}
										onChange={(e) =>
											setSelectedKeys(e.target.value ? [e.target.value] : [])
										}
										onPressEnter={() =>
											handleSearch(selectedKeys, confirm, "franchiseeName")
										}
										style={{ width: 188, marginBottom: 8, display: "block" }}
									/>
									<Space>
										<Button
											type="primary"
											onClick={() =>
												handleSearch(
													selectedKeys,
													confirm,
													"franchiseeName"
												)
											}
											icon={<SearchOutlined />}
											size="small"
											style={{ width: 90 }}
										>
											Buscar
										</Button>
										<Button
											onClick={() => handleReset(clearFilters)}
											size="small"
											style={{ width: 90 }}
										>
											Limpar
										</Button>
									</Space>
								</div>
							)}
							filterIcon={() => {
								return (
									<SearchOutlined
										style={{ color: searchText ? "#1890ff" : "#00000073" }}
									/>
								);
							}}
						/>
					)}

					{!newColumns[11].hidden && (
						<Table.Column
							showSorterTooltip={false}
							dataIndex="customerName"
							title="Cliente"
							sorter={true}
							onHeaderCell={() => ({
								onClick: () =>
									handleSort("customerName", sortOrder?.order || "ascend"),
							})}
							filterDropdown={({
								setSelectedKeys,
								selectedKeys,
								confirm,
								clearFilters,
							}) => (
								<div style={{ padding: 8 }}>
									<Input
										placeholder={`Buscar por cliente`}
										value={selectedKeys[0]?.toString()}
										onChange={(e) =>
											setSelectedKeys(e.target.value ? [e.target.value] : [])
										}
										onPressEnter={() =>
											handleSearch(selectedKeys, confirm, "customerName")
										}
										style={{ width: 188, marginBottom: 8, display: "block" }}
									/>
									<Space>
										<Button
											type="primary"
											onClick={() =>
												handleSearch(selectedKeys, confirm, "customerName")
											}
											icon={<SearchOutlined />}
											size="small"
											style={{ width: 90 }}
										>
											Buscar
										</Button>
										<Button
											onClick={() => handleReset(clearFilters)}
											size="small"
											style={{ width: 90 }}
										>
											Limpar
										</Button>
									</Space>
								</div>
							)}
							filterIcon={() => {
								return (
									<SearchOutlined
										style={{ color: searchText ? "#1890ff" : "#00000073" }}
									/>
								);
							}}
						/>
					)}

					{!newColumns[12].hidden && (
						<Table.Column
							showSorterTooltip={false}
							dataIndex="email"
							title="Email"
							sorter={true}
							onHeaderCell={() => ({
								onClick: () => handleSort("email", sortOrder?.order || "ascend"),
							})}
							filterDropdown={({
								setSelectedKeys,
								selectedKeys,
								confirm,
								clearFilters,
							}) => (
								<div style={{ padding: 8 }}>
									<Input
										placeholder={`Buscar por email`}
										value={selectedKeys[0]?.toString()}
										onChange={(e) =>
											setSelectedKeys(e.target.value ? [e.target.value] : [])
										}
										onPressEnter={() =>
											handleSearch(selectedKeys, confirm, "email")
										}
										style={{ width: 188, marginBottom: 8, display: "block" }}
									/>
									<Space>
										<Button
											type="primary"
											onClick={() =>
												handleSearch(selectedKeys, confirm, "email")
											}
											icon={<SearchOutlined />}
											size="small"
											style={{ width: 90 }}
										>
											Buscar
										</Button>
										<Button
											onClick={() => handleReset(clearFilters)}
											size="small"
											style={{ width: 90 }}
										>
											Limpar
										</Button>
									</Space>
								</div>
							)}
							filterIcon={() => {
								return (
									<SearchOutlined
										style={{ color: searchText ? "#1890ff" : "#00000073" }}
									/>
								);
							}}
						/>
					)}

					{!newColumns[2].hidden && (
						<Table.Column
							showSorterTooltip={false}
							dataIndex="numberOfActivations"
							title="Qtd Acionamentos"
							sorter={true}
							onHeaderCell={() => ({
								onClick: () =>
									handleSort("numberOfActivations", sortOrder?.order || "ascend"),
							})}
							filterDropdown={({
								setSelectedKeys,
								selectedKeys,
								confirm,
								clearFilters,
							}) => (
								<div style={{ padding: 8 }}>
									<Input
										placeholder={`Buscar por quantidade`}
										value={selectedKeys[0]?.toString()}
										onChange={(e) =>
											setSelectedKeys(e.target.value ? [e.target.value] : [])
										}
										onPressEnter={() =>
											handleSearch(
												selectedKeys,
												confirm,
												"numberOfActivations"
											)
										}
										style={{ width: 188, marginBottom: 8, display: "block" }}
									/>
									<Space>
										<Button
											type="primary"
											onClick={() =>
												handleSearch(
													selectedKeys,
													confirm,
													"numberOfActivations"
												)
											}
											icon={<SearchOutlined />}
											size="small"
											style={{ width: 90 }}
										>
											Buscar
										</Button>
										<Button
											onClick={() => handleReset(clearFilters)}
											size="small"
											style={{ width: 90 }}
										>
											Limpar
										</Button>
									</Space>
								</div>
							)}
							filterIcon={() => {
								return (
									<SearchOutlined
										style={{ color: searchText ? "#1890ff" : "#00000073" }}
									/>
								);
							}}
						/>
					)}

					{!newColumns[3].hidden && (
						<Table.Column
							showSorterTooltip={false}
							dataIndex="averageActivations7Days"
							title="Média Acionamentos"
							sorter={true}
							onHeaderCell={() => ({
								onClick: () =>
									handleSort(
										"averageActivations7Days",
										sortOrder?.order || "ascend"
									),
							})}
							filterDropdown={({
								setSelectedKeys,
								selectedKeys,
								confirm,
								clearFilters,
							}) => (
								<div style={{ padding: 8 }}>
									<Input
										placeholder={`Buscar`}
										value={selectedKeys[0]?.toString()}
										onChange={(e) =>
											setSelectedKeys(e.target.value ? [e.target.value] : [])
										}
										onPressEnter={() =>
											handleSearch(
												selectedKeys,
												confirm,
												"averageActivations7Days"
											)
										}
										style={{ width: 188, marginBottom: 8, display: "block" }}
									/>
									<Space>
										<Button
											type="primary"
											onClick={() =>
												handleSearch(
													selectedKeys,
													confirm,
													"averageActivations7Days"
												)
											}
											icon={<SearchOutlined />}
											size="small"
											style={{ width: 90 }}
										>
											Buscar
										</Button>
										<Button
											onClick={() => handleReset(clearFilters)}
											size="small"
											style={{ width: 90 }}
										>
											Limpar
										</Button>
									</Space>
								</div>
							)}
							filterIcon={() => {
								return (
									<SearchOutlined
										style={{ color: searchText ? "#1890ff" : "#00000073" }}
									/>
								);
							}}
						/>
					)}

					{!newColumns[4].hidden && (
						<Table.Column
							showSorterTooltip={false}
							dataIndex="averageVolume7Days"
							title="Média Volume"
							sorter={true}
							onHeaderCell={() => ({
								onClick: () =>
									handleSort("averageVolume7Days", sortOrder?.order || "ascend"),
							})}
							filterDropdown={({
								setSelectedKeys,
								selectedKeys,
								confirm,
								clearFilters,
							}) => (
								<div style={{ padding: 8 }}>
									<Input
										placeholder={`Buscar`}
										value={selectedKeys[0]?.toString()}
										onChange={(e) =>
											setSelectedKeys(e.target.value ? [e.target.value] : [])
										}
										onPressEnter={() =>
											handleSearch(
												selectedKeys,
												confirm,
												"averageVolume7Days"
											)
										}
										style={{ width: 188, marginBottom: 8, display: "block" }}
									/>
									<Space>
										<Button
											type="primary"
											onClick={() =>
												handleSearch(
													selectedKeys,
													confirm,
													"averageVolume7Days"
												)
											}
											icon={<SearchOutlined />}
											size="small"
											style={{ width: 90 }}
										>
											Buscar
										</Button>
										<Button
											onClick={() => handleReset(clearFilters)}
											size="small"
											style={{ width: 90 }}
										>
											Limpar
										</Button>
									</Space>
								</div>
							)}
							filterIcon={() => {
								return (
									<SearchOutlined
										style={{ color: searchText ? "#1890ff" : "#00000073" }}
									/>
								);
							}}
						/>
					)}

					{!newColumns[5].hidden && (
						<Table.Column
							showSorterTooltip={false}
							sorter={true}
							dataIndex="startCalibrationDateTime"
							title="Última calibração"
							render={formatDateTime}
							onHeaderCell={() => ({
								onClick: () =>
									handleSort(
										"startCalibrationDateTime",
										sortOrder?.order || "ascend"
									),
							})}
							filterDropdown={({
								setSelectedKeys,
								selectedKeys,
								confirm,
								clearFilters,
							}) => (
								<div style={{ padding: 8 }}>
									<DatePicker
										locale={locale}
										placeholder="Selecionar data"
										style={{ width: 188, marginBottom: 8, display: "block" }}
										format="DD/MM/YYYY"
										value={selectedKeys[0] as any}
										onChange={(value: any) => {
											if (value) {
												handleSearch(
													[value?.format().split("T")[0]],
													confirm,
													"startCalibrationDateTime"
												);
											}
											setSelectedKeys(value ? [value] : []);
										}}
									/>
									<Space>
										<Button
											onClick={() => {
												setSelectedKeys([]);
												handleReset(clearFilters);
											}}
											size="small"
											style={{ width: 90 }}
										>
											Limpar
										</Button>
									</Space>
								</div>
							)}
							filterIcon={() => {
								return (
									<SearchOutlined
										style={{ color: searchText ? "#1890ff" : "#00000073" }}
									/>
								);
							}}
						/>
					)}

					{!newColumns[6].hidden && (
						<Table.Column
							showSorterTooltip={false}
							sorter={true}
							dataIndex="lastAcionamento"
							title="Último acionamento"
							render={formatDateTime}
							onHeaderCell={() => ({
								onClick: () =>
									handleSort("lastAcionamento", sortOrder?.order || "ascend"),
							})}
							filterDropdown={({
								setSelectedKeys,
								selectedKeys,
								confirm,
								clearFilters,
							}) => (
								<div style={{ padding: 8 }}>
									<DatePicker
										locale={locale}
										placeholder="Selecionar data"
										style={{ width: 188, marginBottom: 8, display: "block" }}
										format="DD/MM/YYYY"
										value={selectedKeys[0] as any}
										onChange={(value: any) => {
											if (value) {
												handleSearch(
													[value?.format().split("T")[0]],
													confirm,
													"lastAcionamento"
												);
											}
											setSelectedKeys(value ? [value] : []);
										}}
									/>
									<Space>
										<Button
											onClick={() => {
												setSelectedKeys([]);
												handleReset(clearFilters);
											}}
											size="small"
											style={{ width: 90 }}
										>
											Limpar
										</Button>
									</Space>
								</div>
							)}
							filterIcon={() => {
								return (
									<SearchOutlined
										style={{ color: searchText ? "#1890ff" : "#00000073" }}
									/>
								);
							}}
						/>
					)}
				</Table>
				<Pagination
					style={{ marginTop: "16px", textAlign: "center" }}
					total={total}
					pageSize={5}
					onChange={handlePageChange}
				/>
			</Card>
		</div>
	);
};
