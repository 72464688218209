
import { useHttp } from "@/application/HttpClient";
import { deviceService } from "@/services/device";
import { notification } from "antd";
import { useState } from "react";
import { RangeDateLogSheetProps } from "./types";
import { RangeDateLogSheetView } from "./view";

type ComponentType = React.FC<RangeDateLogSheetProps>;
export const RangeDateLogSheet: ComponentType = ({ deviceId, ...props }) => {
	const [startDate, setStartDate] = useState('');
    const [finishDate, setFinishDate] = useState('');

	const { request } = useHttp();

	const onGetLogSheet = (url: any) => {

		request(url, { device: deviceId, finish: finishDate, start: startDate })
			.then((res) => {})
			.catch((err: any) => {
				console.log(err);
				notification.error({ message: "Erro ao baixar planilha de logs" })
			})
			.finally(() => {});
	};

	return (
		<>
            <RangeDateLogSheetView
                deviceId={deviceId}
                onClickDownload={() => onGetLogSheet(deviceService.getLogSheet)}
				onClickDownloadRaw={() => onGetLogSheet(deviceService.getRawSheet)}

                onDateChange={(value: any[]) => {
					console.log(value[0]?.format("DD-MM-YYYY"));
					console.log(value[1]?.format("DD-MM-YYYY"));

					setStartDate(value[0]?.format("DD-MM-YYYY"));
					setFinishDate(value[1]?.format("DD-MM-YYYY"));
				}}
            ></RangeDateLogSheetView>
		</>
	);
};
