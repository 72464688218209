import { Button } from "antd";
import { Wrapper } from "./style";
import { CustomerManagementModuleSubHeaderViewProps } from "./types";

type ComponentType = React.FC<CustomerManagementModuleSubHeaderViewProps>;
export const CustomerManagementModuleSubHeaderView: ComponentType = (props) => {
	return (
		<Wrapper>
			<Button disabled={props.addDisabled} onClick={() => props.onAddCustomer()}>
				Novo cliente
			</Button>
		</Wrapper>
	);
};
