import { useTrialManagementModuleContext } from "../TrialManagementModuleContext";
import { TrialManagementModuleContentProps } from "./types";
import { TrialManagementModuleContentView } from "./view";

type ComponentType = React.FC<TrialManagementModuleContentProps>;
export const TrialManagementModuleContent: ComponentType = (props) => {
	const { devices, loading, onUpdateList, handleSortChange, handleFilterChange } =
		useTrialManagementModuleContext();

	return (
		<TrialManagementModuleContentView
			devices={devices}
			loading={loading}
			onDeviceEdited={onUpdateList}
			onDeviceDeleted={onUpdateList}
			handleSortChange={handleSortChange}
			handleFilterChange={handleFilterChange}
		/>
	);
};
