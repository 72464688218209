import { useDashboardDeviceManagementModuleContext } from "../DashboardDeviceContext";
import { DashboardDeviceModuleContentProps } from "./types";
import { DashboardDeviceModuleContentView } from "./view";

type ComponentType = React.FC<DashboardDeviceModuleContentProps>;
export const DashboardDeviceModuleContent: ComponentType = (props) => {
	const { loading, devices, handleSortChange, handleFilterChange, handlePagination, total } =
		useDashboardDeviceManagementModuleContext();
	return (
		<DashboardDeviceModuleContentView
			onSort={handleSortChange}
			onFilter={handleFilterChange}
			onPagination={handlePagination}
			devices={devices}
			loading={loading}
			total={total}
		/>
	);
};
