import { EditDeviceAction } from "@/actions/EditDeviceAction";
import { useAuthorizationContext } from "@/contexts/Authorization";
import { useStatusContext } from "@/contexts/Status";
import { useCallback, useMemo } from "react";
import { TrialManagementModuleSubHeaderProps } from "./types";
import { useTrialManagementModuleContext } from "../TrialManagementModuleContext";
import { TrialManagementModuleSubHeaderView } from "./view";
import { NewTrialDeviceAction } from "@/actions/NewTrialDeviceAction";

type ComponentType = React.FC<TrialManagementModuleSubHeaderProps>;
export const TrialManagementModuleSubHeader: ComponentType = (props) => {
	const { onActionChange } = useStatusContext();
	const { onUpdateList } = useTrialManagementModuleContext();
	const authorization = useAuthorizationContext();

	const addDisabled = useMemo(() => !authorization.canCreate("Device"), [authorization]);

	const onAddDevice = useCallback(() => {
		onActionChange({
			action: new NewTrialDeviceAction(),
			onActionEnd: () => onUpdateList(),
		});
	}, [onActionChange, onUpdateList]);

	return (
		<TrialManagementModuleSubHeaderView addDisabled={addDisabled} onAddDevice={onAddDevice} />
	);
};
