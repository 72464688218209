import { DeviceList } from "@/components/DeviceList";
import { DeviceManagementModuleContentViewProps } from "./types";

type ComponentType = React.FC<DeviceManagementModuleContentViewProps>;
export const DeviceManagementModuleContentView: ComponentType = (props) => {
	return (
		<DeviceList
			devices={props.devices}
			loading={props.loading}
			onDeviceEdited={props.onDeviceEdited}
			onDeviceDeleted={props.onDeviceDeleted}
			handleSortChange={props.handleSortChange}
			handleFilterChange={props.handleFilterChange}
			total={props.total}
			onPagination={props.onPagination}
		/>
	);
};
