import { useAuthorizationContext } from "@/contexts/Authorization";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { modules } from "../../modules";
import { buildModuleRoute } from "../../utilities/buildModuleRoute";
import { ContextProps } from "./types";

export const Context = (props: ContextProps) => {
	const authorization = useAuthorizationContext();

	return (
		<Routes>
			{modules.map((module) =>
				buildModuleRoute({
					module,
					authorization,
					render: (module) => {
						const Context = module.context || React.Fragment;
						return <Context key={module.path}>{props.children(module)}</Context>;
					},
				})
			)}

			<Route path="*" element={<Navigate to="/not-found" />} />
		</Routes>
	);
};
