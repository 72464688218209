import { useCustomerManagementModuleContext } from "../CustomerManagementModuleContext";
import { CustomerManagementModuleContentProps } from "./types";
import { CustomerManagementModuleContentView } from "./view";

type ComponentType = React.FC<CustomerManagementModuleContentProps>;
export const CustomerManagementModuleContent: ComponentType = (props) => {
	const { customers, loading, onUpdateList, handleSortChange, handleFilterChange } =
		useCustomerManagementModuleContext();

	return (
		<CustomerManagementModuleContentView
			loading={loading}
			customers={customers}
			onCustomerEdited={onUpdateList}
			onCustomerDeleted={onUpdateList}
			handleSortChange={handleSortChange}
			handleFilterChange={handleFilterChange}
		/>
	);
};
