import { Customer } from "@/models/Customer";
import { formatCustomerRole } from "@/utilities/format/customerRole";
import { DeleteOutlined, EditOutlined, SelectOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Input, Popconfirm, Row, Space, Table } from "antd";

import { CustomerListViewProps } from "./types";
import { useState } from "react";

export const CustomerListView = (props: CustomerListViewProps) => {
	const [sortOrder, setSortOrder] = useState<any | null>(null);

	const [searchText, setSearchText] = useState("");

	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		props.onFilter(dataIndex, selectedKeys[0]);
	};

	const handleSearchEnum = (selectedKeys, confirm, dataIndex) => {
		confirm();
		props.onFilter(dataIndex, selectedKeys);
	};

	const handleReset = (clearFilters) => {
		clearFilters();
		setSearchText("");
		props.onFilter("", "");
	};

	const handleSort = (field: string, order: "ascend" | "descend") => {
		if (sortOrder?.field === field && sortOrder?.order === order) {
			setSortOrder({ field, order: order === "ascend" ? "descend" : "ascend" });
		} else {
			setSortOrder({ field, order });
		}

		props.onSort(field, order === "descend" ? "desc" : "asc");
	};

	return (
		<Table
			rowKey="id"
			title={() => "Clientes"}
			dataSource={props.customers}
			loading={props.loading}
			style={{ width: "100%" }}
		>
			<Table.Column
				showSorterTooltip={false}
				sorter={true}
				onHeaderCell={() => ({
					onClick: () => handleSort("name", sortOrder?.order || "ascend"),
				})}
				filterDropdown={({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
					<div style={{ padding: 8 }}>
						<Input
							placeholder={`Buscar nome`}
							value={selectedKeys[0]?.toString()}
							onChange={(e) =>
								setSelectedKeys(e.target.value ? [e.target.value] : [])
							}
							onPressEnter={() => handleSearch(selectedKeys, confirm, "name")}
							style={{ width: 188, marginBottom: 8, display: "block" }}
						/>
						<Space>
							<Button
								type="primary"
								onClick={() => handleSearch(selectedKeys, confirm, "name")}
								icon={<SearchOutlined />}
								size="small"
								style={{ width: 90 }}
							>
								Buscar
							</Button>
							<Button
								onClick={() => handleReset(clearFilters)}
								size="small"
								style={{ width: 90 }}
							>
								Limpar
							</Button>
						</Space>
					</div>
				)}
				filterIcon={() => {
					return (
						<SearchOutlined style={{ color: searchText ? "#1890ff" : "#00000073" }} />
					);
				}}
				dataIndex="name"
				title="Nome"
			/>
			<Table.Column
				dataIndex="email"
				title="Email"
				showSorterTooltip={false}
				sorter={true}
				onHeaderCell={() => ({
					onClick: () => handleSort("email", sortOrder?.order || "ascend"),
				})}
				filterDropdown={({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
					<div style={{ padding: 8 }}>
						<Input
							placeholder={`Buscar email`}
							value={selectedKeys[0]?.toString()}
							onChange={(e) =>
								setSelectedKeys(e.target.value ? [e.target.value] : [])
							}
							onPressEnter={() => handleSearch(selectedKeys, confirm, "email")}
							style={{ width: 188, marginBottom: 8, display: "block" }}
						/>
						<Space>
							<Button
								type="primary"
								onClick={() => handleSearch(selectedKeys, confirm, "email")}
								icon={<SearchOutlined />}
								size="small"
								style={{ width: 90 }}
							>
								Buscar
							</Button>
							<Button
								onClick={() => handleReset(clearFilters)}
								size="small"
								style={{ width: 90 }}
							>
								Limpar
							</Button>
						</Space>
					</div>
				)}
				filterIcon={() => {
					return (
						<SearchOutlined style={{ color: searchText ? "#1890ff" : "#00000073" }} />
					);
				}}
			/>

			<Table.Column
				dataIndex="legalEntityId"
				title="CNPJ"
				showSorterTooltip={false}
				sorter={true}
				onHeaderCell={() => ({
					onClick: () => handleSort("legalEntityId", sortOrder?.order || "ascend"),
				})}
				filterDropdown={({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
					<div style={{ padding: 8 }}>
						<Input
							placeholder={`Buscar CNPJ`}
							value={selectedKeys[0]?.toString()}
							onChange={(e) =>
								setSelectedKeys(e.target.value ? [e.target.value] : [])
							}
							onPressEnter={() => handleSearch(selectedKeys, confirm, "legalEntityId")}
							style={{ width: 188, marginBottom: 8, display: "block" }}
						/>
						<Space>
							<Button
								type="primary"
								onClick={() => handleSearch(selectedKeys, confirm, "legalEntityId")}
								icon={<SearchOutlined />}
								size="small"
								style={{ width: 90 }}
							>
								Buscar
							</Button>
							<Button
								onClick={() => handleReset(clearFilters)}
								size="small"
								style={{ width: 90 }}
							>
								Limpar
							</Button>
						</Space>
					</div>
				)}
				filterIcon={() => {
					return (
						<SearchOutlined style={{ color: searchText ? "#1890ff" : "#00000073" }} />
					);
				}}
			/>
			<Table.Column dataIndex="role" title="Permissão" render={formatCustomerRole} />

			<Table.Column<Customer>
				key="actions"
				title="Ações"
				render={(_, customer) => {
					const editable = props.isCustomerEditable(customer);
					const scopable = props.isCustomerScopable(customer);
					const deletable = props.isCustomerDeletable(customer);

					return (
						<Row justify="center">
							<Space>
								<Button
									ghost
									disabled={!editable}
									icon={<EditOutlined />}
									onClick={() => props.onEditCustomer(customer)}
								/>

								<Button
									ghost
									disabled={!scopable}
									icon={<SelectOutlined />}
									onClick={() => props.onScopeCustomer(customer)}
								/>

								<Popconfirm
									placement="topRight"
									disabled={!deletable}
									title="Tem certeza que deseja remover esse cliente? Essa ação é irreversível."
									onConfirm={() => props.onDeleteCustomer(customer)}
								>
									<Button
										ghost
										danger
										disabled={!deletable}
										icon={<DeleteOutlined />}
									/>
								</Popconfirm>
							</Space>
						</Row>
					);
				}}
			/>
		</Table>
	);
};
