import { Layout } from "antd";
import styled from "styled-components";

export const PageWrapper = styled(Layout)`
	min-height: 100vh;
	/* background-color: #0044ff; */
	background-image: url("/image/bg-system-desktop-color.jpg"); /* Substitua 'sua-imagem.jpg' pelo caminho da sua imagem de fundo */
	background-size: cover; /* Cobrir toda a tela */
	background-position: center; /* Centralizar a imagem */
	background-repeat: no-repeat; /* Evitar repetição da imagem */
	overflow: hidden; /* Impede a exibição de barras de rolagem */
`;

export const Content = styled(Layout.Content)`
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const Footer = styled(Layout.Footer)`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
