import { useHttp } from "@/application/HttpClient";
import { AuthenticatedUser } from "@/models/AuthenticatedUser";
import { authenticationService } from "@/services/authentication";
import { useStatusContext } from "@contexts/Status";
import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { IdentityContextValue, IdentityProviderProps } from "./types";

const IdentityContext = createContext<IdentityContextValue>({} as any);

export const IdentityProvider = (props: IdentityProviderProps) => {
	const [user, setUser] = useState<AuthenticatedUser>();

	const { request } = useHttp();
	const { onIdentityContextReady } = useStatusContext();

	const updateUser = useCallback(() => {
		return request(authenticationService.getCurrentUser, {})
			.then((user) => setUser(user))
			.catch(() => setUser(undefined));
	}, [request]);

	const logout = useCallback(() => {
		return request(authenticationService.logout, {})
			.then(() => setUser(undefined))
			.catch(() => setUser(undefined));
	}, [request]);

	const authenticate = useCallback(
		(username: string, password: string) => {
			return request(authenticationService.login, { username, password }).then((user) => {
				setUser(user);
				return user;
			});
		},
		[request]
	);

	useEffect(() => {
		updateUser().then(() => {
			onIdentityContextReady();
		});
	}, [request, onIdentityContextReady, updateUser]);

	return (
		<IdentityContext.Provider value={{ user, logout, authenticate, updateUser }}>
			{props.children}
		</IdentityContext.Provider>
	);
};

export const useIdentityContext = () => {
	return useContext(IdentityContext);
};
