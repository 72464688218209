import { Input } from "antd";
import { ChangeEvent, useCallback, useMemo } from "react";
import { normalize } from "./normalize";
import { NumberInputProps } from "./types";

type ComponentType = React.FC<NumberInputProps>;
export const NumberInput: ComponentType = ({ value, onChange, ...props }) => {
	const formattedValue = useMemo(() => value?.toLocaleString(), [value]);
	const onInputChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => onChange?.(normalize(event.target.value)),
		[onChange]
	);

	return <Input {...props} value={formattedValue} onChange={onInputChange} />;
};
