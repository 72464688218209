import { Option } from "@/models/application/Option";

type Enumerable = { [key: string]: string };
type StringFactory<T> = (key: T[keyof T]) => string;

function enumToOptions<T extends Enumerable>(enumerable: T, labelAccessor?: StringFactory<T>) {
	const keys = Object.keys(enumerable) as (keyof T)[];
	const values = keys.map((key) => enumerable[key]);
	const options = values.map<Option<string>>((value) => ({
		value,
		label: labelAccessor?.(value) || value,
	}));

	return options;
}

export { enumToOptions };
