import { legalEntityPattern } from "@/models/rules/legalEntityPattern";
import { required } from "@/models/rules/required";
import { Button, Form, Input, Row } from "antd";
import { LegalEntityNumberInput } from "../LegalEntityNumberInput";
import { LaundryFormViewProps } from "./types";
import { PhoneNumberInput } from "../PhoneNumberInput";

export const LaundryFormView = (props: LaundryFormViewProps) => {
	const validateEmail = (rule: any, value: any, callback: any) => {
		if (value && !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
			callback("Por favor, insira um email válido.");
		} else {
			callback();
		}
	};

	const validatePhone = (rule: any, value: any, callback: any) => {

		const numericValue = value?.replace(/\D/g, '');

		if (numericValue?.length === 10 || numericValue?.length === 11) {
			callback();
		}else if (value && !/^\(\d{2}\)\s\d{4,5}-\d{4}$/.test(value)) {
			console.log({value})
			callback("Por favor, insira um telefone válido.");
		} else {
			callback();
		}
	};

	return (
		<Form
			layout="vertical"
			form={props.form}
			onFinish={props.onSubmit}
			initialValues={props.initialValue}
		>
			<Form.Item name="name" label="Nome" rules={[required]}>
				<Input disabled={props.submitting} />
			</Form.Item>

			<Form.Item
				name="emailReport"
				label="Email Relatório"
				rules={[{ validator: validateEmail }]}
			>
				<Input disabled={props.submitting} />
			</Form.Item>

			<Form.Item name="phone" label="Telefone" rules={[{ validator: validatePhone }]}>
				<PhoneNumberInput disabled={props.submitting} />
			</Form.Item>

			<Form.Item name="secondaryPhone" label="Telefone Secundário" rules={[{ validator: validatePhone }]}>
				<PhoneNumberInput disabled={props.submitting} />
			</Form.Item>

			<Form.Item name="legalEntityId" label="CNPJ" rules={[required, legalEntityPattern]}>
				<LegalEntityNumberInput disabled={props.submitting} />
			</Form.Item>

			<Form.Item name="customerId" label="Cliente" rules={[required]} hidden>
				<Input disabled={props.submitting} />
			</Form.Item>

			<Row justify="end">
				<Button
					type="primary"
					htmlType="submit"
					disabled={props.submitting}
					loading={props.submitting}
				>
					Salvar
				</Button>
			</Row>
		</Form>
	);
};
