export const createUrl = (base: string, path: string) => {
	const endsWithSlashPattern = /\/$/;
	const startsWithSlashPattern = /^\//;

	const sanitizedBase = base.replace(endsWithSlashPattern, "");
	const sanitizedPath = path.replace(startsWithSlashPattern, "");
	const url = `${sanitizedBase}/${sanitizedPath}`;

	return url;
};
