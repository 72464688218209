import { Action } from "@/models/actions/Action";
import { Table, Typography } from "antd";
import { ActionControls } from "@/models/actions/ActionControls";
import { Laundry } from "@/models/Laundry";
import { LaundryUnit } from "@/models/LaundryUnit";

export class ShowUnitsByLaundryAction implements Action {
	constructor(
		private readonly laundry?: Laundry,
		private readonly onDeleteChemicalRecipientRefill?: Function
	) {
		console.log(laundry?.units);
	}

	dataSource = this.laundry?.units
		? this.laundry?.units.map((item: LaundryUnit) => {
				return {
					name: item.name ? item.name : "",
				};
		  })
		: [];

	columns = [
		{
			title: "Unidade",
			dataIndex: "name",
			key: "unidade",
		},
	];

	getOptions() {
		return {
			width: "40vw",
			title: `Franquia: ${this.laundry?.name}`,
		};
	}

	render(controls: ActionControls) {
		return (
			<>
				<Typography.Title level={3}>Unidades:</Typography.Title>
				<Table pagination={false} dataSource={this.dataSource} columns={this.columns} />
			</>
		);
	}
}
