import { Action } from "@/models/application/Action";
import { Authorization } from "@/models/application/Authorization";
import { Subject } from "@/models/application/Subject";
import { AbilityTuple, createMongoAbility, MongoAbility, MongoQuery } from "@casl/ability";
import { AnyObject } from "@casl/ability/dist/types/types";
import { createContext, PropsWithChildren, useContext, useMemo } from "react";
import { useIdentityContext } from "../Identity";

type Tuple = AbilityTuple<Action, Subject>;
type Ability = MongoAbility<Tuple, MongoQuery<AnyObject>>;

const AuthorizationContext = createContext<Ability>({} as any);
export const AuthorizationContextConsumer = AuthorizationContext.Consumer;

export const AuthorizationProvider = (props: PropsWithChildren) => {
	const { user } = useIdentityContext();

	const rules = useMemo(() => user?.rules || [], [user]);
	const ability = useMemo(
		() => createMongoAbility<Tuple>(rules, { detectSubjectType: (subject) => subject.kind }),
		[rules]
	);

	return (
		<AuthorizationContext.Provider value={ability}>
			{props.children}
		</AuthorizationContext.Provider>
	);
};

export const useAuthorizationContext = () => {
	const ability = useContext(AuthorizationContext);
	const authorization = useMemo(() => new Authorization(ability), [ability]);

	return authorization;
};
