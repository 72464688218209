import { Action } from "@/models/actions/Action";
import { Button, Divider, Popconfirm, Row, Space, Table, Typography, notification } from "antd";
import { ChemicalRecipient } from "@/models/ChemicalRecipient";
import { Pump } from "@/models/Pump";
import { DeleteOutlined } from "@ant-design/icons";
import { formatDateTime } from "@/utilities/format/date";
import { formatMilliliters } from "@/utilities/format/milliliters";
import { ActionControls } from "@/models/actions/ActionControls";

export class ShowDevicesWithPumpAction implements Action {
	constructor(
		private readonly recipient?: ChemicalRecipient,
		private readonly onDeleteChemicalRecipientRefill?: Function
	) {
		console.log(recipient);
	}

	dataSourcePumps = this.recipient?.pumps
		? this.recipient?.pumps.map((item: Pump) => {
				return {
					deviceName: `${item.device.name} - ${item.device.serialCode}`,
					index: item?.index,
				};
		  })
		: [];

	columnsPumps = [
		{
			title: "Equipamento",
			dataIndex: "deviceName",
			key: "device",
		},
		{
			title: "Bomba",
			dataIndex: "index",
			key: "index",
			render: (value: number) => {
				return value + 1;
			},
		},
	];

	dataSourceRefils = this.recipient?.refills
		? this.recipient?.refills.map((item: any) => {
				return {
					data: item.createdAt,
					produto: item?.produto,
					quantidade: `${formatMilliliters(item?.volume)}`,
					id: item.id,
				};
		  })
		: [];

	columnsRefils = [
		{
			title: "Data e Hora",
			dataIndex: "data",
			key: "data",
			render: (value: any) => {
				return formatDateTime(value);
			},
		},
		{
			title: "Produto",
			dataIndex: "produto",
			key: "produto",
		},
		{
			title: "Quantidade",
			dataIndex: "quantidade",
			key: "quantidade",
		},
		{
			key: "actions",
			title: "Ações",
			render: (_, refil) => (
				<Row justify="center">
					<Space>
						<Popconfirm
							placement="topRight"
							title="Tem certeza que deseja remover esse refil? Essa ação é irreversível."
							onConfirm={() => {
								if (this.onDeleteChemicalRecipientRefill) {
									this.onDeleteChemicalRecipientRefill(refil);
									notification.success({
										message: "Refil removido com sucesso!",
									});
								}
							}}
						>
							<Button ghost danger icon={<DeleteOutlined />} />
						</Popconfirm>
					</Space>
				</Row>
			),
		},
	];

	getOptions() {
		return {
			width: "40vw",
			title: `Recipiente: ${this.recipient?.produto} - ${formatMilliliters(
				this.recipient?.volume ?? 0
			)}`,
		};
	}

	render(controls: ActionControls) {
		return (
			<>
				<Typography.Title level={3}>Bomba:</Typography.Title>
				<Table
					pagination={false}
					dataSource={this.dataSourcePumps}
					columns={this.columnsPumps}
				/>
				<Divider />
				<Typography.Title level={3}>Refils:</Typography.Title>
				<Table dataSource={this.dataSourceRefils} pagination={false} bordered size="small">
					<Table.Column dataIndex="data" title="Data e Hora" render={formatDateTime} />
					<Table.Column dataIndex="produto" title="Produto" />
					<Table.Column dataIndex="quantidade" title="Quantidade" />
					<Table.Column
						key="actions"
						title="Ações"
						render={(_, refil) => (
							<Row justify="center">
								<Space>
									<Popconfirm
										placement="topRight"
										title="Tem certeza que deseja remover esse refil? Essa ação é irreversível."
										onConfirm={() => {
											if (this.onDeleteChemicalRecipientRefill) {
												this.onDeleteChemicalRecipientRefill(refil);
												notification.success({
													message: "Refil removido com sucesso!",
												});
												controls.close();
											}
										}}
									>
										<Button ghost danger icon={<DeleteOutlined />} />
									</Popconfirm>
								</Space>
							</Row>
						)}
					/>
				</Table>
			</>
		);
	}
}
