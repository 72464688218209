import { Module } from "@/models/modules/Module";
import { ClusterOutlined } from "@ant-design/icons";
import { MachineModuleContent } from "./MachineModuleContent";
import { MachineModuleContextProvider } from "./MachineModuleContext";
import { MachineModuleSubHeader } from "./MachineModuleSubHeader";

export const MachineModule: Module = {
	path: "/machines",
	title: "Máquinas",
	resource: "Device",
	icon: <ClusterOutlined />,
	content: <MachineModuleContent />,
	subHeader: <MachineModuleSubHeader />,
	context: MachineModuleContextProvider,
};
