import { EditLaundryUnitAction } from "@/actions/EditLaundryUnitAction";
import { useAuthorizationContext } from "@/contexts/Authorization";
import { useStatusContext } from "@/contexts/Status";
import { useCallback, useMemo } from "react";
import { useLaundryUnitManagementModuleContext } from "../LaundryUnitManagementModuleContext";
import { LaundryUnitManagementModuleSubHeaderProps } from "./types";
import { LaundryUnitManagementModuleSubHeaderView } from "./view";

type ComponentType = React.FC<LaundryUnitManagementModuleSubHeaderProps>;
export const LaundryUnitManagementModuleSubHeader: ComponentType = (props) => {
	const { onActionChange } = useStatusContext();
	const { onUpdateList } = useLaundryUnitManagementModuleContext();
	const authorization = useAuthorizationContext();

	const addDisabled = useMemo(() => !authorization.canCreate("LaundryUnit"), [authorization]);

	const onAddLaundryUnit = useCallback(() => {
		onActionChange({
			action: new EditLaundryUnitAction(),
			onActionEnd: () => onUpdateList(),
		});
	}, [onActionChange, onUpdateList]);

	return (
		<LaundryUnitManagementModuleSubHeaderView
			addDisabled={addDisabled}
			onAddLaundryUnit={onAddLaundryUnit}
		/>
	);
};
