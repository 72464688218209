import { LaundryUnitService } from "./types";

export const laundryUnitService: LaundryUnitService = {

	list: ({ sortField, sortOrder, filterField, filterText }) => ({
		method: "GET",
		path: `/v1/laundry-unit?sortField=${sortField}&sortOrder=${sortOrder}&filterField=${filterField}&filterText=${filterText}`,
	}),
	available: () => ({
		method: "GET",
		path: "/v1/laundry-unit/unit-available",
	}),
	create: (json) => ({
		json,
		method: "POST",
		path: `/v1/laundry-unit`,
	}),
	edit: ({ laundryUnitId, ...json }) => ({
		json,
		method: "PUT",
		path: `/v1/laundry-unit/${laundryUnitId}`,
	}),
	filter: ({ laundryUnitId }) => ({
		method: "GET",
		path: `/v1/laundry-unit/${laundryUnitId}/unit-available`,
	}),
	delete: ({ laundryUnitId }) => ({
		method: "DELETE",
		path: `/v1/laundry-unit/${laundryUnitId}`,
	}),
};
