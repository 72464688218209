import { Loading } from "../Loading";
import { OverlayPanel, OverlayWrapper } from "./style";
import { OverlayProps } from "./types";

type ComponentType = React.FC<OverlayProps>;
export const Overlay: ComponentType = (props) => {
	const content = props.content || <Loading />;

	return (
		<OverlayWrapper style={props.wrapperStyle}>
			{props.visible && <OverlayPanel>{content}</OverlayPanel>}
			{props.children}
		</OverlayWrapper>
	);
};
