import { useStatusContext } from "@/contexts/Status";
import { ActionControls } from "@/models/actions/ActionControls";
import { Drawer, DrawerProps } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ActionsComponentProps } from "./types";

export const ActionsComponent = (props: ActionsComponentProps) => {
	const { action, onActionChange } = useStatusContext();
	const [closeable, setCloseable] = useState<boolean>(true);

	const close = useCallback(() => {
		onActionChange(undefined);
	}, [onActionChange]);

	const closeAndTriggerActionEnd = useCallback(() => {
		close();
		action?.onActionEnd?.();
	}, [action, close]);

	const drawerOptions = useMemo<DrawerProps | undefined>(() => action?.getOptions(), [action]);
	const actionControls = useMemo<ActionControls>(
		() => ({ close: closeAndTriggerActionEnd, setCloseable }),
		[closeAndTriggerActionEnd]
	);

	useEffect(() => setCloseable(true), [action]);

	return (
		<Drawer
			{...drawerOptions}
			visible={!!action}
			maskClosable={false}
			closable={closeable}
			onClose={close}
		>
			{action && action.render(actionControls)}
		</Drawer>
	);
};
