import { Form } from "antd";
import React, { useCallback, useMemo } from "react";
import { BusinessTimesFormData, BusinessTimesFormProps } from "./types";
import { BusinessTimesFormView } from "./view";

type ComponentType = React.FC<BusinessTimesFormProps>;
export const BusinessTimesForm: ComponentType = ({ onSubmit, ...props }) => {
	const [form] = Form.useForm<BusinessTimesFormData>();

	const initialValue = useMemo(
		() => ({ businessTimes: props.businessTimes || [] }),
		[props.businessTimes]
	);

	const onFormSubmit = useCallback(() => {
		const formData = form.getFieldsValue();
		onSubmit?.(formData.businessTimes);
	}, [form, onSubmit]);

	return (
		<BusinessTimesFormView
			form={form}
			initialValue={initialValue}
			onSubmit={onFormSubmit}
			onCancel={props.onCancel}
		/>
	);
};
