import { LaundryUnit } from "@/models/LaundryUnit";
import { required } from "@/models/rules/required";
import { Button, Form, Input, Row, Table, notification } from "antd";
import { LaundryUnitSelectInput } from "../LaundryUnitSelectInput";
import { MachineFormViewProps } from "./types";
import { Device } from "@/models/Device";
import { DeviceAllSelectInput } from "../DeviceAllSelectInput";
import React, { useEffect, useState } from "react";
import { PumpSelectInput } from "../PumpSelectInput";
import { CloseOutlined } from "@ant-design/icons";
import { PlusOutlined } from "@ant-design/icons";
import Title from "antd/lib/typography/Title";

type ComponentType = React.FC<MachineFormViewProps>;
export const MachineFormView: ComponentType = (props) => {
	const [showSecondForm, setShowSecondForm] = useState<boolean>(false);

	const toggleSecondForm = () => {
		setShowSecondForm(!showSecondForm);
	};

	const [form] = Form.useForm();
	const [dataSource, setDataSource] = useState<any>(
		props.initialValue?.machinePumps?.map((item, index) => {
			return {
				key: index,
				deviceId: item.pump.device,
				pumpsId: {
					...item.pump,
					value: item.pump.id,
				},
				// pumpsId: {
				// 	...item.pump,
				// 	value: item.pump.id,
				// },
				labelDevice: `${item.pump.device.serialCode} - ${item.pump.device.name}`,
				labelPump: `Bomba - ${item.pump.index + 1}`,
			};
		}) || []
	);

	const onFinish = (values) => {
		const isDuplicate = dataSource.some(
			(item) =>
				item.deviceId.id === values.deviceId.id &&
				item.pumpsId.value === values.pumpsId.value
		);

		if (isDuplicate) {
			notification.error({ message: "Esta bomba já foi adicionada." });
			return;
		}

		setDataSource([
			...dataSource.map((item) => {
				return {
					...item,
					labelDevice: `${item.deviceId.serialCode} - ${item.deviceId.name}`,
					labelPump: `Bomba - ${item.pumpsId.index + 1}`,
				};
			}),
			{
				...values,
				key: dataSource.length,
				labelDevice: `${values.deviceId.serialCode}-${values.deviceId.name}`,
				labelPump: `Bomba - ${values.pumpsId.index + 1}`,
			},
		]);
		form.resetFields();
		toggleSecondForm();
	};

	const [laundryUnitId, setLaundryUnitId] = useState<string>();
	const [deviceId, setDeviceId] = useState<string>();

	useEffect(() => {
		setLaundryUnitId(props.initialValue?.laundryUnitId);
	}, [laundryUnitId, props.initialValue?.laundryUnitId, deviceId]);

	const handleRemove = (key) => {
		setDataSource(dataSource.filter((item) => item.key !== key));
	};

	return (
		<>
			<Form
				layout="vertical"
				form={props.form}
				onFinish={props.onSubmit}
				initialValues={props.initialValue}
			>
				<Form.Item name="name" label="Nome" rules={[required]}>
					<Input disabled={props.submitting} />
				</Form.Item>

				<Form.Item style={{ display: "none" }} name="pumpsId" label="" rules={[required]}>
					<Input />
				</Form.Item>

				<Form.Item
					rules={[required]}
					name="laundryUnitId"
					label="Unidade"
					normalize={(laundryUnit?: LaundryUnit) => laundryUnit?.id}
				>
					<LaundryUnitSelectInput
						laundryUnitId={props.initialValue?.laundryUnitId}
						disabled={props.submitting}
						onChange={(laundryUnit: LaundryUnit | undefined) => {
							setLaundryUnitId(laundryUnit?.id);
							props.form.setFieldValue("laundryUnitId", laundryUnit?.id);
						}}
					/>
				</Form.Item>

				<Row justify="end">
					<Button
						ghost
						icon={<PlusOutlined />}
						type="primary"
						disabled={props.submitting}
						loading={props.submitting}
						onClick={toggleSecondForm}
					></Button>
				</Row>
			</Form>
			{showSecondForm && (
				<Form layout="vertical" form={form} onFinish={onFinish}>
					<Form.Item
						rules={[required]}
						name="deviceId"
						label="Equipamento"
						normalize={(device?: Device) => device?.id}
					>
						<DeviceAllSelectInput
							laundryUnitId={props.form.getFieldValue("laundryUnitId")}
							style={{ width: "100%" }}
							onChange={(value) => {
								setDeviceId(value?.id);
								form.setFieldValue("deviceId", value);
							}}
						/>
					</Form.Item>

					<Form.Item rules={[required]} label="Bombas" name="pumpsId">
						<PumpSelectInput
							deviceId={deviceId}
							onChange={(payload) => {
								form.setFieldValue("pumpsId", payload);
							}}
						/>
					</Form.Item>

					<Row justify="end">
						<Button
							type="primary"
							htmlType="submit"
							disabled={props.submitting}
							loading={props.submitting}
						>
							Salvar bomba
						</Button>
					</Row>
				</Form>
			)}
			<Title level={5}> Bombas </Title>
			<Table dataSource={dataSource} pagination={false} bordered size="small">
				<Table.Column dataIndex={"labelDevice"} title="Equipamento" />
				<Table.Column dataIndex={"labelPump"} title="Bomba" />
				<Table.Column
					render={(businessTime) => (
						<Button
							ghost
							icon={<CloseOutlined />}
							onClick={() => {
								handleRemove(businessTime.key);
							}}
						/>
					)}
				/>
			</Table>

			<Row justify="end" style={{ paddingTop: "16px" }}>
				<Button
					type="primary"
					htmlType="submit"
					disabled={!dataSource.length}
					loading={props.submitting}
					onClick={() => {
						const ids = dataSource.map((item) => item.pumpsId.value);
						props.form.setFieldsValue({ pumpsId: ids });
						props.form.setFieldValue("pumpsId", ids);
						props.onSubmit();
					}}
				>
					Salvar
				</Button>
			</Row>
		</>
	);
};
