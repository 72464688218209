import { Logo, Wrapper } from "./styles";
import { SidebarHeaderViewProps } from "./types";

export const SidebarHeaderView = (props: SidebarHeaderViewProps) => {
	return (
		<Wrapper>
			<Logo src={props.logoUrl} />
		</Wrapper>
	);
};
