import { useHttp } from "@/application/HttpClient";
import { LaundryUnit } from "@/models/LaundryUnit";
import { laundryUnitService } from "@/services/laundryUnit";
import { useCollection } from "@/utilities/collections/useCollection";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { LaundryUnitManagementModuleContextValue } from "./types";
import { Filter } from "@/utilities/interface/Filter";

const LaundryUnitManagementModuleContext =
	React.createContext<LaundryUnitManagementModuleContextValue>({} as any);

export const LaundryUnitManagementModuleContextProvider = (props: React.PropsWithChildren) => {
	const { request } = useHttp();

	const [order, setOrder] = useState<Filter>({
		sortField: "createdAt",
		sortOrder: "desc",
	});

	const [filter, setFilter] = useState<any>({
		filterField: "",
		filterText: "",
	});

	const handleFilterChange = (field: string, text: string) =>
		setFilter({
			filterField: field,
			filterText: text,
		});

	const handleSortChange = (field: string, sortOrder: string) =>
		setOrder({
			sortField: field,
			sortOrder: sortOrder,
		});

	const [loading, setLoading] = useState<boolean>(true);
	const [units, setUnits] = useCollection<LaundryUnit>([]);

	const onUpdateList = useCallback(() => setLoading(true), []);

	useEffect(() => {
		if (loading) {
			request(laundryUnitService.list, { ...order, ...filter })
				.then((units) => setUnits(units))
				.catch(() => setUnits([]))
				.finally(() => setLoading(false));
		}
	}, [loading, request, setUnits]);

	return (
		<LaundryUnitManagementModuleContext.Provider
			value={{ loading, onUpdateList, units, handleSortChange, handleFilterChange }}
		>
			{props.children}
		</LaundryUnitManagementModuleContext.Provider>
	);
};

export const useLaundryUnitManagementModuleContext = () => {
	return useContext(LaundryUnitManagementModuleContext);
};
