import { useHttp } from "@/application/HttpClient";
import { LaundryUnit } from "@/models/LaundryUnit";
import { laundryUnitService } from "@/services/laundryUnit";
import { useCollection } from "@/utilities/collections/useCollection";
import { Select } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { LaundrySelectInputProps, OptionType } from "./types";

type ComponentType = React.FC<LaundrySelectInputProps>;
export const LaundryUnitSelectInput: ComponentType = ({ onChange, ...props }) => {
	const { request } = useHttp();

	const [loading, setLoading] = useState<boolean>(true);
	const [laundries, setLaundries] = useCollection<LaundryUnit>([]);

	const [selectedValue, setSelectedValue] = useState<string | undefined>(undefined);

	useEffect(() => {
		if (!loading) {
			if (typeof props.value !== "string") {
				setSelectedValue(props.value?.id);
			} else {
				setSelectedValue(props.value);
			}
		} else {
			setSelectedValue(undefined);
		}
	}, [loading, props.value, props.laundryId]);

	useEffect(() => {
		if (!loading) {
			handleClear();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.laundryId]);

	function handleClear() {
		setSelectedValue(undefined);
	}

	const options = useMemo<OptionType[]>(
		() =>
			laundries
				.map((payload) => ({ value: payload.id, label: payload.name, payload }))
				.filter((l) => props?.laundryId ? l.payload.laundryId === props?.laundryId : true),
		[laundries, props?.laundryId]
	);

	const onFilter = useCallback((value: string, option?: OptionType) => {
		const reference = option?.label.toLowerCase();
		const test = value.toLowerCase();

		return reference?.includes(test) || false;
	}, []);

	const onChildChange = useCallback(
		(option?: OptionType) => onChange?.(option?.payload),
		[onChange]
	);

	useEffect(() => {
		if (loading) {
			request(laundryUnitService.list, { sortField: "createdAt", sortOrder: "desc", filterField: "", filterText: "" })
				.then((laundries) => {
					setLaundries(laundries);
				})
				.catch(() => {
					setLaundries([]);
				})
				.finally(() => setLoading(false));
		}
	}, [loading, request, setLaundries, props.laundryUnitId]);

	return (
		<>
			<Select
				style={props.style}
				allowClear
				showSearch
				value={selectedValue}
				disabled={props.disabled}
				loading={loading}
				options={options}
				filterOption={onFilter}
				onChange={(_value, option) => onChildChange(option as OptionType)}
				placeholder='UNIDADE'
			/>
		</>
	);
};
