import { CustomerRole } from "@/models/CustomerRole";
import { legalEntityPattern } from "@/models/rules/legalEntityPattern";
import { required } from "@/models/rules/required";
import { enumToOptions } from "@/utilities/collections/enumToOptions";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, Input, Row, Select, Upload } from "antd";
import { LegalEntityNumberInput } from "../LegalEntityNumberInput";
import { CustomerFormViewProps } from "./types";

type ComponentType = React.FC<CustomerFormViewProps>;
export const CustomerFormView: ComponentType = (props) => {
	const validateEmail = (rule: any, value: any, callback: any) => {
		if (value && !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
			callback("Por favor, insira um email válido.");
		} else {
			callback();
		}
	};

	return (
		<Form
			layout="vertical"
			form={props.form}
			onFinish={props.onSubmit}
			initialValues={props.initialValue}
		>
			<Form.Item name="name" label="Nome" rules={[required]}>
				<Input disabled={props.submitting} />
			</Form.Item>

			<Form.Item name="email" label="Email" rules={[{ validator: validateEmail }]}>
				<Input disabled={props.submitting} />
			</Form.Item>

			<Form.Item name="emailReport" label="Email Relatório" rules={[{ validator: validateEmail }]}>
				<Input disabled={props.submitting} />
			</Form.Item>

			<Form.Item name="role" label="Papel do cliente" rules={[required]} hidden>
				<Select disabled={props.submitting} options={enumToOptions(CustomerRole)} />
			</Form.Item>

			<Form.Item name="legalEntityId" label="CNPJ" rules={[required, legalEntityPattern]}>
				<LegalEntityNumberInput disabled={props.submitting} />
			</Form.Item>

			<Form.Item
				name="logo"
				label="Logo"
				normalize={(file) => file?.file}
				rules={[]}
			>
				<Upload beforeUpload={() => false} disabled={props.submitting} accept="image/*">
					<Button ghost block icon={<UploadOutlined />}>
						Selecionar arquivo
					</Button>
				</Upload>
			</Form.Item>

			<Row justify="end">
				<Button
					type="primary"
					htmlType="submit"
					disabled={props.submitting}
					loading={props.submitting}
				>
					Salvar
				</Button>
			</Row>
		</Form>
	);
};
