import { RootProvider } from "@application/RootProvider";
import { Router } from "@application/Router";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";

import "./index.less";
import { Environment } from "./models/application/Environment";
import { createUrl } from "./utilities/text/createUrl";

declare global {
	let environment: Environment;
}

environment = Object.assign(environment, {
	baseUrl: environment.baseUrl || createUrl(window.location.origin, "/api"),
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<RootProvider>
				<Router />
			</RootProvider>
		</BrowserRouter>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
