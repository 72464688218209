import { EditCustomerAction } from "@/actions/EditCustomerAction";
import { useAuthorizationContext } from "@/contexts/Authorization";
import { useStatusContext } from "@/contexts/Status";
import { useCallback, useMemo } from "react";
import { useCustomerManagementModuleContext } from "../CustomerManagementModuleContext";
import { CustomerManagementModuleSubHeaderProps } from "./types";
import { CustomerManagementModuleSubHeaderView } from "./view";

type ComponentType = React.FC<CustomerManagementModuleSubHeaderProps>;
export const CustomerManagementModuleSubHeader: ComponentType = (props) => {
	const { onActionChange } = useStatusContext();
	const { onUpdateList } = useCustomerManagementModuleContext();
	const authorization = useAuthorizationContext();

	const addDisabled = useMemo(() => !authorization.canCreate("Customer"), [authorization]);

	const onAddCustomer = useCallback(() => {
		onActionChange({
			action: new EditCustomerAction(),
			onActionEnd: () => onUpdateList(),
		});
	}, [onActionChange, onUpdateList]);

	return (
		<CustomerManagementModuleSubHeaderView
			addDisabled={addDisabled}
			onAddCustomer={onAddCustomer}
		/>
	);
};
