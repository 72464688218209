import { AbilityTuple, MongoAbility } from "@casl/ability";
import { Action } from "./Action";
import { Subject } from "./Subject";

export class Authorization {
	constructor(private readonly ability?: MongoAbility<AbilityTuple<Action, Subject>>) {}

	canManage<T extends Subject>(subject: T, field?: string) {
		return this.isAllowed("manage", subject, field);
	}

	canRead<T extends Subject>(subject: T, field?: string) {
		return this.isAllowed("read", subject, field);
	}

	canCreate<T extends Subject>(subject: T, field?: string) {
		return this.isAllowed("create", subject, field);
	}

	canUpdate<T extends Subject>(subject: T, field?: string) {
		return this.isAllowed("update", subject, field);
	}

	canDelete<T extends Subject>(subject: T, field?: string) {
		return this.isAllowed("delete", subject, field);
	}

	private isAllowed<T extends Subject>(action: Action, subject: T, field?: string) {
		return this.ability?.can(action, subject, field) || false;
	}
}
