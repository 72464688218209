import { Module } from "@/models/modules/Module";
import { UserOutlined } from "@ant-design/icons";
import { UserManagementModuleContent } from "./UserManagementModuleContent";
import { UserManagementModuleContextProvider } from "./UserManagementModuleContext";
import { UserManagementModuleSubHeader } from "./UserManagementModuleSubHeader";

export const UserManagementModule: Module = {
	path: "/users",
	title: "Usuários",
	resource: "User",
	icon: <UserOutlined />,
	headerIcon: <UserOutlined />,
	context: UserManagementModuleContextProvider,
	subHeader: <UserManagementModuleSubHeader />,
	content: <UserManagementModuleContent />,
};
