import { StatusEmailTerms } from "@/models/Trial";

export const formatOptional = (value?: string) => value || "-";

export const emailTermStatusToString = (status: StatusEmailTerms) => {
    const translateObj = {
        [StatusEmailTerms.ACCEPT_TERMS]: "Aceito",
        [StatusEmailTerms.REFUSE]: "Recusado",
        [StatusEmailTerms.WAITING]: "Aguardando",
		[StatusEmailTerms.DONT_REQUEST]: "Não solicitei",
		[StatusEmailTerms.UNKNOWN]: "Desconhecido",

    }
    return translateObj[status];
};
