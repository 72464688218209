import { CityInput } from "@/components/CityInput";
import { CountryInput } from "@/components/CountryInput";
import { StateInput } from "@/components/StateInput";
import { ZipCodeInput } from "@/components/ZipCodeInput";
import { City } from "@/models/location/City";
import { Country } from "@/models/location/Country";
import { State } from "@/models/location/State";
import { zipCodePattern } from "@/models/rules";
import { Button, Checkbox, Col, Form, Input, Row, Space } from "antd";
import { AddressFormData, AddressFormViewProps } from "./types";

type ComponentType = React.FC<AddressFormViewProps>;
export const AddressFormView: ComponentType = (props) => {
	return (
		<Form
			layout="vertical"
			form={props.form}
			initialValues={props.initialValue}
			onFinish={props.onSubmit}
		>
			<Form.Item
				name="zipCode"
				label="CEP"
				hasFeedback
				validateStatus={props.zipCodeFeedback?.status}
				help={props.zipCodeFeedback?.message}
				rules={[zipCodePattern]}
			>
				<ZipCodeInput />
			</Form.Item>

			<Form.Item name="street" label="Logradouro">
				<Input disabled />
			</Form.Item>

			<Form.Item<AddressFormData>
				hidden
				name="country"
				label="País"
				normalize={(country?: Country) => country?.isoCode}
			>
				<CountryInput disabled />
			</Form.Item>

			<Row gutter={16}>
				<Col xs={12}>
					<Form.Item<AddressFormData> noStyle dependencies={["country"]}>
						{({ getFieldValue }) => (
							<Form.Item
								name="state"
								label="Estado"
								normalize={(state?: State) => state?.isoCode}
							>
								<StateInput disabled countryCode={getFieldValue("country")} />
							</Form.Item>
						)}
					</Form.Item>
				</Col>

				<Col xs={12}>
					<Form.Item<AddressFormData> noStyle dependencies={["country", "state"]}>
						{({ getFieldValue }) => (
							<Form.Item
								name="city"
								label="Cidade"
								normalize={(city?: City) => city?.name}
							>
								<CityInput
									disabled
									stateCode={getFieldValue("state")}
									countryCode={getFieldValue("country")}
								/>
							</Form.Item>
						)}
					</Form.Item>
				</Col>
			</Row>

			<Form.Item name="neighborhood" label="Bairro">
				<Input disabled />
			</Form.Item>

			<Row gutter={16}>
				<Col xs={12}>
					<Form.Item noStyle dependencies={["noStreetNumber"]}>
						{({ getFieldValue }) => {
							const noStreetNumber = getFieldValue("noStreetNumber");

							return (
								<Form.Item name="streetNumber" label="Número">
									<Input
										disabled={noStreetNumber}
										addonAfter={
											<Form.Item
												noStyle
												name="noStreetNumber"
												valuePropName="checked"
											>
												<Checkbox>N/A</Checkbox>
											</Form.Item>
										}
									/>
								</Form.Item>
							);
						}}
					</Form.Item>
				</Col>

				<Col xs={12}>
					<Form.Item noStyle dependencies={["noComplement"]}>
						{({ getFieldValue }) => {
							const noComplement = getFieldValue("noComplement");

							return (
								<Form.Item name="complement" label="Complemento">
									<Input
										disabled={noComplement}
										addonAfter={
											<Form.Item
												noStyle
												name="noComplement"
												valuePropName="checked"
											>
												<Checkbox>N/A</Checkbox>
											</Form.Item>
										}
									/>
								</Form.Item>
							);
						}}
					</Form.Item>
				</Col>
			</Row>

			<br />

			<Form.Item>
				<Row justify="end">
					<Space>
						{props.onCancel && <Button onClick={props.onCancel}>Cancelar</Button>}
						<Button type="primary" htmlType="submit">
							Salvar
						</Button>
					</Space>
				</Row>
			</Form.Item>
		</Form>
	);
};
