import { MachineList } from "@/components/MachineList";
import { MachineModuleContentViewProps } from "./types";

type ComponentType = React.FC<MachineModuleContentViewProps>;
export const MachineModuleContentView: ComponentType = (props) => {
	return (
		<MachineList
			machines={props.machines}
			loading={props.loading}
			onMachineEdited={props.onDeviceEdited}
			onMachineDeleted={props.onDeviceDeleted}
			handleSortChange={props.handleSortChange}
			handleFilterChange={props.handleFilterChange}
		/>
	);
};
