import { EditLaundryAction } from "@/actions/EditLaundryAction";
import { useAuthorizationContext } from "@/contexts/Authorization";
import { useStatusContext } from "@/contexts/Status";
import { useCallback, useMemo } from "react";
import { useLaundryManagementModuleContext } from "../LaundryManagementModuleContext";
import { LaundryManagementModuleSubHeaderProps } from "./types";
import { LaundryManagementModuleSubHeaderView } from "./view";

type ComponentType = React.FC<LaundryManagementModuleSubHeaderProps>;
export const LaundryManagementModuleSubHeader: ComponentType = (props) => {
	const { onActionChange } = useStatusContext();
	const { onUpdateList } = useLaundryManagementModuleContext();
	const authorization = useAuthorizationContext();

	const addDisabled = useMemo(() => !authorization.canCreate("Laundry"), [authorization]);

	const onAddLaundry = useCallback(() => {
		onActionChange({
			action: new EditLaundryAction(),
			onActionEnd: () => onUpdateList(),
		});
	}, [onActionChange, onUpdateList]);

	return (
		<LaundryManagementModuleSubHeaderView
			addDisabled={addDisabled}
			onAddLaundry={onAddLaundry}
		/>
	);
};
