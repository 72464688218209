import styled from "styled-components";

export const Wrapper = styled.div`
	background-color: rgba(0, 0, 0, 0.1);
	height: 48px;
	padding: 16px;
	margin-bottom: 2em;
	box-sizing: content-box;
`;

export const Logo = styled.img.attrs({ alt: "Logo do cliente" })`
	object-fit: contain;
	width: 100%;
	height: 100%;
`;
