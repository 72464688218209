import { useHttp } from "@/application/HttpClient";
import { State } from "@/models/location/State";
import { locationService } from "@/services/location";
import { Select } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { StateInputProps, StateOption } from "./types";

type ComponentType = React.FC<StateInputProps>;
export const StateInput: ComponentType = ({ onChange, ...props }) => {
	const { request } = useHttp();
	const [loading, setLoading] = useState<boolean>(false);
	const [states, setStates] = useState<State[] | undefined>([]);

	const disabled = useMemo(() => props.disabled || loading, [loading, props.disabled]);

	const childValue = useMemo(() => {
		if (props.value === undefined || typeof props.value === "string") return props.value;
		return props.value?.isoCode;
	}, [props.value]);

	const options = useMemo(() => {
		if (states === undefined) return [];

		return states.map<StateOption>((state) => ({
			value: state?.isoCode,
			label: state?.name,
			payload: state,
		}));
	}, [states]);

	const filterOption = useCallback((value: string, option?: StateOption) => {
		if (option === undefined) return false;

		const referenceName = option.payload?.name?.toLowerCase();
		const userInput = value.toLowerCase();

		return referenceName.includes(userInput);
	}, []);

	const onChildChange = useCallback(
		(_value: string, option?: StateOption | StateOption[]) => {
			const changedValue = option !== undefined ? (option as StateOption).payload : undefined;

			onChange?.(changedValue);
		},
		[onChange]
	);

	useEffect(() => {
		setStates(undefined);
	}, [props?.countryCode]);

	useEffect(() => {
		if (states === undefined) {
			setLoading(true);
			request(locationService.listStates, { countryId: props?.countryCode })
				.then((countries) => setStates(countries))
				.catch(() => setStates([]))
				.finally(() => setLoading(false));
		}
	}, [props?.countryCode, request, states]);

	return (
		<Select<string, StateOption>
			showSearch
			allowClear
			loading={loading}
			value={childValue}
			disabled={disabled}
			options={options}
			filterOption={filterOption}
			onChange={onChildChange}
		/>
	);
};
