import { formatDateTime } from "./format/date";

export const formatAlert = (alertJson: string, alertObject: any) => {
	if (alertJson?.toLowerCase().includes("lockedButton".toLowerCase())) {
		return `Botão preso: ${alertObject?.lockedButton}`;
	}
	if (alertJson?.toLowerCase().includes("unknownFirmwareVersion".toLowerCase())) {
		return `Vesão de software desconhecida`;
	}
	if (alertJson?.toLowerCase().includes("updateVersion".toLowerCase())) {
		return `Nova versão de firmware instalada`;
	}
	if (alertJson?.toLowerCase().includes("tryAccessTecnicalMode".toLowerCase())) {
		return `Tentativa de acesso em modo tecnico`;
	}
	// TODO: ajustar isso aqui
	if (alertJson?.toLowerCase().includes("tryAccessTecnicalMode".toLowerCase())) {
		return `Tentativa de mudança de senha fisica`;
	}
	if (alertJson?.toLowerCase().includes("equipmentBlocked".toLowerCase())) {
		return `Equipamento bloqueado`;
	}
	// OK
	if (alertJson?.toLowerCase().includes("startedMode".toLowerCase())) {
		return `Iniciado em modo: ${alertObject?.startedMode}`;
	}
	if (alertJson?.toLowerCase().includes("fakeTecnical".toLowerCase())) {
		return `Iniciado em modo: "Pseudo Técnico"`;
	}
	if (alertJson?.toLowerCase().includes("tecnical".toLowerCase())) {
		return `Iniciado em modo: "Operador"`;
	}
	if (alertJson?.toLowerCase().includes("factoryReset".toLowerCase())) {
		return `Padrão de fábrica restaurado`;
	}
	if (alertJson?.toLowerCase().includes("finishedTimeTecnicalMode".toLowerCase())) {
		return `tempo de modo técnico encerrado`;
	}
	if (alertJson?.toLowerCase().includes("errorInputMachine".toLowerCase())) {
		return `Sinal de maquina travado: ${alertObject.errorInputMachine}`;
	}
	if (alertJson?.toLowerCase().includes("errorInputMaxTimeMachine".toLowerCase())) {
		return `Tempo maximo de sinal atingido: ${JSON.stringify(
			alertObject?.errorInputMaxTimeMachine,
			null,
			2
		)}`;
	}
	if (alertJson?.toLowerCase().includes("errorPumpMaxTimeMachine".toLowerCase())) {
		return `Tempo maximo de bomba atingido : ${JSON.stringify(
			alertObject?.errorPumpMaxTimeMachine,
			null,
			2
		)}`;
	}
	if (alertJson?.toLowerCase().includes("equipmentStarted".toLowerCase())) {
		return `Equipamento iniciado`;
	}
	if (alertJson?.toLowerCase().includes("changeFlushTime".toLowerCase())) {
		return `Tempo de flush alterado: ${alertObject?.changeFlushTime}`;
	}
	if (alertJson?.toLowerCase().includes("changePrimeTime".toLowerCase())) {
		return `Tempo de prime alterado: ${alertObject?.changePrimeTime}`;
	}
	if (alertJson?.toLowerCase().includes("changeSignalTime".toLowerCase())) {
		return `Tempo de sinal de maquina alterado: ${JSON.stringify(
			alertObject?.changeSignalTime,
			null,
			2
		)}`;
	}
	if (alertJson?.toLowerCase().includes("startCalibration".toLowerCase())) {
		return `Calibração iniciada`;
	}
	if (alertJson?.toLowerCase().includes("changeCalibrationPump".toLowerCase())) {
		return `Calibração de bomba alterada: ${alertObject?.changeCalibrationPump}`;
	}
	if (alertJson?.toLowerCase().includes("finishedCalibration".toLowerCase())) {
		return `Calibração finalizada`;
	}
	if (alertJson?.toLowerCase().includes("changePumpName".toLowerCase())) {
		return `Nome da bomba alterado: ${JSON.stringify(alertObject?.changePumpName, null, 2)}`;
	}
	if (alertJson?.toLowerCase().includes("changePassWordApMode".toLowerCase())) {
		return `Senha do Modo AP alterada`;
	}
	if (alertJson?.toLowerCase().includes("changeFisicalPassWord".toLowerCase())) {
		return `Senha fisica alterada`;
	}
	if (alertJson?.toLowerCase().includes("changeSsidWifiClient".toLowerCase())) {
		return `SSID WiFi cliente alterado`;
	}
	if (alertJson?.toLowerCase().includes("changePassWifiClient".toLowerCase())) {
		return `Senha do WiFi cliente alterada`;
	}
	if (alertJson?.toLowerCase().includes("changeDateTime".toLowerCase())) {
		return `Data e hora modificada: ${formatDateTime(alertObject?.changeDateTime)}`;
	}
	if (alertJson?.toLowerCase().includes("changeLanguage".toLowerCase())) {
		return `Idioma Modificado: ${alertObject?.changeLanguage}`;
	}
	if (alertJson?.toLowerCase().includes("resetPrimeTotalizer".toLowerCase())) {
		return `totalizador de prime restaurado:  ${JSON.stringify(
			alertObject?.resetPrimeTotalizer,
			null,
			2
		)}`;
	}
	if (alertJson?.toLowerCase().includes("resetSignalTotalizer".toLowerCase())) {
		return `totalizador de sinal de bomba restaurado: ${alertObject?.resetSignalTotalizer}`;
	}

	return alertJson;
};
