import { useHttp } from "@/application/HttpClient";
import { useIdentityContext } from "@/contexts/Identity";
import { customerService } from "@/services/customer";
import { useMemo } from "react";
import { SidebarHeaderProps } from "./types";
import { SidebarHeaderView } from "./view";

export const SidebarHeader = (props: SidebarHeaderProps) => {
	const { endpoint } = useHttp();
	const { user } = useIdentityContext();

	const logoUrl = useMemo(() => {
		if (!user) return "";

		return endpoint(customerService.getLogo, {
			customer: user.scopedCustomer,
			timestamp: user.scopedCustomer.updatedAt.getTime(),
		});
	}, [endpoint, user]);

	return <SidebarHeaderView logoUrl={logoUrl} />;
};
