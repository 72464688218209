import { useHttp } from "@/application/HttpClient";
import { trialService } from "@/services/trial";
import { Form } from "antd";
import { useCallback, useMemo, useState } from "react";
import { TrialDeviceFormData, TrialDeviceFormProps } from "./types";
import { TrialDeviceFormView } from "./view";

type ComponentType = React.FC<TrialDeviceFormProps>;
export const TrialDeviceForm: ComponentType = (props) => {
	const { request } = useHttp();
	const { onSubmitting, onSubmitted, onSubmissionFailed } = props;

	const [form] = Form.useForm<TrialDeviceFormData>();
	const [submitting, setSubmitting] = useState<boolean>(false);

	const initialValue = useMemo(
		() => true && {
			email: props.trialDevice?.trialEmailEmail,
			serialCode: props.trialDevice?.serialCode,
			tempoTrial: props?.trialDevice?.deviceTrialInfoTempoTrial,
		},
		[props.trialDevice]
	);

	const onSubmit = useCallback(() => {
		if (submitting) return;

		const formData: TrialDeviceFormData = form.getFieldsValue();
		if(formData.email === ""){
			delete formData.email;
		}

		const doRequest = () =>
			props.trialDevice
				? request(trialService.edit, {
						deviceId: props.trialDevice.id,
						...formData,
						tempoTrial: Number(formData?.tempoTrial),
				  })
				: request(trialService.create, formData);

		onSubmitting?.();
		setSubmitting(true);

		doRequest()
			.then(() => {
				onSubmitted?.()
			 })
			.catch((error) => {
				console.log(error);
				onSubmissionFailed?.(error)
			})
			.finally(() => setSubmitting(false));
	}, [form, onSubmissionFailed, onSubmitted, onSubmitting, props.trialDevice, request, submitting]);

	return (
		<TrialDeviceFormView
			form={form}
			submitting={submitting}
			isEditMode={!!props.trialDevice}
			initialValue={initialValue}
			onSubmit={onSubmit}
		/>
	);
};
