import { MachineService } from "./types";

export const machineService: MachineService = {
	list: ({ sortField, sortOrder, filterField, filterText }) => ({
		method: "GET",
		path: `/v1/machines?sortField=${sortField}&sortOrder=${sortOrder}&filterField=${filterField}&filterText=${filterText}`,
	}),
	get: ({ machineId }) => ({
		method: "GET",
		path: `/v1/machines/${machineId}`,
	}),
	create: (json) => ({
		json,
		method: "POST",
		path: `/v1/machines`,
	}),
	edit: ({ machineId, ...json }) => ({
		json,
		method: "PUT",
		path: `/v1/machines/${machineId}`,
	}),
	delete: ({ machineId }) => ({
		method: "DELETE",
		path: `/v1/machines/${machineId}`,
	}),
};
