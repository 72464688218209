import { NewChemicalRecipientRefillForm } from "@/components/NewChemicalRecipientRefillForm";
import { Action } from "@/models/actions/Action";
import { ActionControls } from "@/models/actions/ActionControls";
import { ChemicalRecipient } from "@/models/ChemicalRecipient";
import { ChemicalRecipientRefill } from "@/models/ChemicalRecipientRefill";
import { notification } from "antd";

export class NewChemicalRecipientRefillAction implements Action {
	constructor(private readonly refill?: ChemicalRecipientRefill, private readonly recipient?: ChemicalRecipient) {}

	getOptions() {
		return {
			width: "40vw",
			title: `Recipiente: ${this.recipient?.produto} - ${this.recipient?.volume}`,
		};
	}

	render(controls: ActionControls) {
		return (
			<NewChemicalRecipientRefillForm
				refill={this.refill}
				recipientId={this.recipient?.id}
				recipient={this.recipient}
				onSubmitting={() => controls.setCloseable(false)}
				onSubmitted={(refill) => this.onSuccess(refill, controls)}
				onSubmissionFailed={(error) => this.onFailure(error, controls)}
			/>
		);
	}

	private onSuccess(_refill: ChemicalRecipientRefill, controls: ActionControls) {
		controls.setCloseable(true);
		controls.close();

		notification.success({ message: "Refil salvo com sucesso!" });
	}

	private onFailure(_error: Error, controls: ActionControls) {
		controls.setCloseable(true);

		notification.error({ message: "Erro ao salvar refil" });
	}
}
