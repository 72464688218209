import { compareDesc } from "date-fns";

type ItemWithName = { name: string };
type ItemWithUpdateTimestamp = { updatedAt: Date };
type ItemWithCreationTimestamp = { createdAt: Date };

export const compareByName = <T extends ItemWithName>(a: T, b: T) => a.name.localeCompare(b.name);

export const compareByUpdatedDateTime = <T extends ItemWithUpdateTimestamp>(a: T, b: T) =>
	compareDesc(a.updatedAt, b.updatedAt);

export const compareByCreatedDateTime = <T extends ItemWithCreationTimestamp>(a: T, b: T) =>
	compareDesc(a.createdAt, b.createdAt);
