import { useHttp } from "@/application/HttpClient";
import { Form } from "antd";
import { useCallback, useMemo, useState } from "react";
import { MachineFormData, MachineFormProps } from "./types";
import { MachineFormView } from "./view";
import { machineService } from "@/services/machine";

type ComponentType = React.FC<MachineFormProps>;
export const MachineForm: ComponentType = (props) => {
	const { request } = useHttp();
	const { onSubmitting, onSubmitted, onSubmissionFailed } = props;

	const [form] = Form.useForm<MachineFormData>();
	const [submitting, setSubmitting] = useState<boolean>(false);

	const initialValue = useMemo(() => props.machine && { ...props.machine }, [props.machine]);

	const onSubmit = useCallback(() => {
		if (submitting) return;

		const formData = form.getFieldsValue();

		if (!formData.laundryUnitId) {
			formData.laundryUnitId = undefined;
		}

		const doRequest = () =>
			props.machine
				? request(machineService.edit, {
						machineId: props.machine.id,
						...formData,
				  })
				: request(machineService.create, formData);

		onSubmitting?.();
		setSubmitting(true);

		doRequest()
			.then((machine) => onSubmitted?.(machine))
			.catch((error) => onSubmissionFailed?.(error))
			.finally(() => setSubmitting(false));
	}, [form, onSubmissionFailed, onSubmitted, onSubmitting, props.machine, request, submitting]);

	return (
		<MachineFormView
			form={form}
			submitting={submitting}
			isEditMode={!!props.machine}
			initialValue={initialValue}
			onSubmit={onSubmit}
		/>
	);
};
