import { Input } from "antd";
import { ChangeEvent, useCallback } from "react";
import MaskedInput from "react-text-mask";
import { ZipCodeInputProps } from "./types";

const mask = [/\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/];

type ComponentType = React.FC<ZipCodeInputProps>;
export const ZipCodeInput: ComponentType = ({ value, onChange, ...props }) => {
	const onInputChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => onChange?.(event.target.value),
		[onChange]
	);

	return (
		<MaskedInput
			mask={mask}
			value={value}
			onChange={onInputChange}
			render={(ref, innerProps) => (
				<Input
					{...props}
					{...innerProps}
					ref={(inputRef) => inputRef?.input && ref(inputRef.input)}
					value={value}
				/>
			)}
		/>
	);
};
