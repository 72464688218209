import { useHttp } from "@/application/HttpClient";
import { City } from "@/models/location/City";
import { locationService } from "@/services/location";
import { Select } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { CityInputProps, CityOption } from "./types";

type ComponentType = React.FC<CityInputProps>;
export const CityInput: ComponentType = ({ onChange, ...props }) => {
	const { request } = useHttp();
	const [loading, setLoading] = useState<boolean>(false);
	const [cities, setCities] = useState<City[] | undefined>([]);

	const disabled = useMemo(() => props.disabled || loading, [loading, props.disabled]);

	const childValue = useMemo(() => {
		if (props.value === undefined || typeof props.value === "string") return props.value;
		return props.value?.name;
	}, [props.value]);

	const options = useMemo(() => {
		if (cities === undefined) return [];

		return cities.map<CityOption>((state) => ({
			value: state?.name,
			label: state?.name,
			payload: state,
		}));
	}, [cities]);

	const filterOption = useCallback((value: string, option?: CityOption) => {
		if (option === undefined) return false;

		const referenceName = option.payload?.name.toLowerCase();
		const userInput = value?.toLowerCase();

		return referenceName.includes(userInput);
	}, []);

	const onChildChange = useCallback(
		(_value: string, option?: CityOption | CityOption[]) => {
			const changedValue = option !== undefined ? (option as CityOption)?.payload : undefined;

			onChange?.(changedValue);
		},
		[onChange]
	);

	useEffect(() => {
		setCities(undefined);
	}, [props?.countryCode, props?.stateCode]);

	useEffect(() => {
		if (cities === undefined) {
			const body = {
				countryId: props?.countryCode,
				stateId: props?.stateCode,
			};

			setLoading(true);
			request(locationService.listCities, body)
				.then((countries) => setCities(countries))
				.catch(() => setCities([]))
				.finally(() => setLoading(false));
		}
	}, [cities, props?.countryCode, props?.stateCode, request]);

	return (
		<Select<string, CityOption>
			showSearch
			allowClear
			loading={loading}
			value={childValue}
			disabled={disabled}
			options={options}
			filterOption={filterOption}
			onChange={onChildChange}
		/>
	);
};
