import { Module } from "@/models/modules/Module";
import { ClusterOutlined } from "@ant-design/icons";
import { TrialManagementModuleContent } from "./TrialManagementModuleContent";
import { TrialManagementModuleContextProvider } from "./TrialManagementModuleContext";
import { TrialManagementModuleSubHeader } from "./TrialManagementModuleSubHeader";

export const TrialManagementModule: Module = {
	path: "/trial",
	title: "Trial",
	resource: "Trial",
	icon: <ClusterOutlined />,
	content: <TrialManagementModuleContent />,
	subHeader: <TrialManagementModuleSubHeader />,
	context: TrialManagementModuleContextProvider,
};
