import { LaundryUnitList } from "@/components/LaundryUnitList";
import { LaundryUnitManagementModuleContentViewProps } from "./types";

type ComponentType = React.FC<LaundryUnitManagementModuleContentViewProps>;
export const LaundryUnitManagementModuleContentView: ComponentType = (props) => {
	return (
		<LaundryUnitList
			units={props.units}
			loading={props.loading}
			onLaundryUnitEdited={props.onLaundryUnitEdited}
			onLaundryUnitDeleted={props.onLaundryUnitDeleted}
			handleSortChange={props.handleSortChange}
			handleFilterChange={props.handleFilterChange}

		/>
	);
};
