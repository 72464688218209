import { useHttp } from "@/application/HttpClient";
import { useIdentityContext } from "@/contexts/Identity";
import { User } from "@/models/User";
import { userService } from "@/services/user";
import { useCollection } from "@/utilities/collections/useCollection";
import { useComponentDidUpdate } from "@/utilities/hooks/useComponentDidUpdate";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { UserManagementModuleContextValue } from "./types";
import { Filter } from "@/utilities/interface/Filter";

const UserManagementModuleContext = React.createContext<UserManagementModuleContextValue>(
	{} as any
);

export const UserManagementModuleContextProvider = (props: React.PropsWithChildren) => {
	const { request } = useHttp();
	const { user } = useIdentityContext();

	const [loading, setLoading] = useState<boolean>(true);

	const [order, setOrder] = useState<Filter>({
		sortField: "createdAt",
		sortOrder: "desc",
	});

	const [filter, setFilter] = useState<any>({
		filterField: "",
		filterText: "",
	});

	const handleSortChange = (field: string, sortOrder: string) =>
		setOrder({
			sortField: field,
			sortOrder: sortOrder,
		});

	const handleFilterChange = (field: string, text: string) =>
		setFilter({
			filterField: field,
			filterText: text,
		});

	const [users, setUsers] = useCollection<User>([]);

	const onUpdateList = useCallback(() => setLoading(true), []);

	useEffect(() => {
		if (loading) {
			request(userService.list, {...order, ...filter})
				.then((users) => setUsers(users))
				.catch(() => setUsers([]))
				.finally(() => setLoading(false));
		}
	}, [loading, request, setUsers]);

	useComponentDidUpdate(() => onUpdateList(), [user]);

	return (
		<UserManagementModuleContext.Provider value={{ loading, users, onUpdateList, handleSortChange, handleFilterChange }}>
			{props.children}
		</UserManagementModuleContext.Provider>
	);
};

export const useUserManagementModuleContext = () => {
	return useContext(UserManagementModuleContext);
};
