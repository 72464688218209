import { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react";

type Identifiable = { id: string };
type Comparer<T> = (a: T, b: T) => number;
type Modifier<T> = (...items: T[]) => void;
type CollectionControls<T> = [T[], Dispatch<SetStateAction<T[]>>, Modifier<T>, Modifier<T>];

export function useCollection<T extends Identifiable>(
	initialValue: T[],
	comparer?: Comparer<T>
): CollectionControls<T> {
	const [value, setValue] = useState<T[]>(() => initialValue);
	const collection = useMemo(() => (comparer ? value.sort(comparer) : value), [comparer, value]);

	const remove = useCallback<Modifier<T>>((...items) => {
		setValue((value) => {
			const itemsIdCollection = items.map((item) => item.id);
			const valueWithoutItems = value.filter((item) => !itemsIdCollection.includes(item.id));

			return valueWithoutItems;
		});
	}, []);

	const add = useCallback<Modifier<T>>((...items) => {
		setValue((value) => {
			const itemsIdCollection = items.map((item) => item.id);
			const valueWithoutItems = value.filter((item) => !itemsIdCollection.includes(item.id));
			const valueWithNewItems = valueWithoutItems.concat(items);

			return valueWithNewItems;
		});
	}, []);

	return [collection, setValue, add, remove];
}
