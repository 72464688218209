import { useHttp } from "@/application/HttpClient";
import { deviceService } from "@/services/device";
import { Form } from "antd";
import { useCallback, useMemo, useState } from "react";
import { DeviceFormData, DeviceFormProps } from "./types";
import { DeviceFormView } from "./view";

type ComponentType = React.FC<DeviceFormProps>;
export const DeviceForm: ComponentType = (props) => {
	const { request } = useHttp();
	const { onSubmitting, onSubmitted, onSubmissionFailed } = props;

	const [form] = Form.useForm<DeviceFormData>();
	const [submitting, setSubmitting] = useState<boolean>(false);

	const initialValue = useMemo(
		() => props.device && { ...props.device, customerId: props.device.customer?.id },
		[props.device]
	);

	const onSubmit = useCallback(() => {
		if (submitting) return;

		const formData = form.getFieldsValue();

		if (!formData.customerId) {
			formData.customerId = undefined;
		}

		if (!formData.laundryId) {
			formData.laundryId = undefined;
		}


		if (!formData.laundryUnitId) {
			formData.laundryUnitId = undefined;
		}

		const doRequest = () =>
			props.device
				? request(deviceService.edit, {
						deviceId: props.device.id,
						...formData,
				  })
				: request(deviceService.create, formData);

		onSubmitting?.();
		setSubmitting(true);

		doRequest()
			.then((customer) => onSubmitted?.(customer))
			.catch((error) => onSubmissionFailed?.(error))
			.finally(() => setSubmitting(false));
	}, [form, onSubmissionFailed, onSubmitted, onSubmitting, props.device, request, submitting]);

	return (
		<DeviceFormView
			form={form}
			submitting={submitting}
			isEditMode={!!props.device}
			initialValue={initialValue}
			onSubmit={onSubmit}
		/>
	);
};
