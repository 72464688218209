import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { LoginPageProps } from "./types";
import { LoginPageView } from "./view";

export const LoginPage = (props: LoginPageProps) => {
	const navigate = useNavigate();

	const onUserAuthenticated = useCallback(() => {
		navigate(props.redirectTo);
	}, [navigate, props.redirectTo]);

	return <LoginPageView onUserAuthenticated={onUserAuthenticated} />;
};
