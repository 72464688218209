import { minimumListLength } from "@/models/rules";
import { BusinessTime } from "@/models/time/BusinessTime";
import { Button, Divider, Form, Row, Space } from "antd";
import React from "react";
import { BusinessTimesTable } from "./components/BusinessTimesTable";
import { SingleBusinessTimeForm } from "./components/SingleBusinessTimeForm";
import { BusinessTimesFormViewProps } from "./types";

type ComponentType = React.FC<BusinessTimesFormViewProps>;
export const BusinessTimesFormView: ComponentType = (props) => {
	return (
		<Form
			layout="vertical"
			form={props.form}
			initialValues={props.initialValue}
			onFinish={props.onSubmit}
		>
			<Form.List name="businessTimes">
				{(_data, { add, remove }, { errors }) => {
					const onBusinessTimeDeleted = (businessTime: BusinessTime) => {
						const businessTimes = props.form.getFieldValue("businessTimes") || [];
						const indexOfExistingBusinessTime = businessTimes.findIndex(
							(t: BusinessTime) => t.dayOfWeek === businessTime.dayOfWeek
						);

						if (indexOfExistingBusinessTime >= 0) remove(indexOfExistingBusinessTime);
					};

					const onBusinessTimesChange = (addedBusinessTimes: BusinessTime[]) => {
						for (const addedBusinessTime of addedBusinessTimes) {
							const businessTimes = props.form.getFieldValue("businessTimes") || [];
							const indexOfExistingBusinessTime = businessTimes.findIndex(
								(t: BusinessTime) => t.dayOfWeek === addedBusinessTime.dayOfWeek
							);

							if (indexOfExistingBusinessTime >= 0)
								remove(indexOfExistingBusinessTime);
							add(addedBusinessTime);
						}
					};

					return (
						<React.Fragment>
							<SingleBusinessTimeForm onSubmit={onBusinessTimesChange} />
							<Divider />
							<BusinessTimesTable
								businessTimes={props.form.getFieldValue("businessTimes")}
								onBusinessTimeDeleted={onBusinessTimeDeleted}
							/>
							<Form.ErrorList errors={errors} />
						</React.Fragment>
					);
				}}
			</Form.List>

			<br />

			<Form.Item>
				<Row justify="end">
					<Space>
						{props.onCancel && <Button onClick={props.onCancel}>Cancelar</Button>}
						<Button type="primary" htmlType="submit">
							Salvar
						</Button>
					</Space>
				</Row>
			</Form.Item>
		</Form>
	);
};
