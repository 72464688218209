import { HeaderProps } from "./types";
import { HeaderView } from "./view";

export const Header = (props: HeaderProps) => {
	return (
		<HeaderView
			actions={[]}
			key={props.module.path}
			title={props.module.title}
			subHeader={props.module.subHeader}
			icon={props.module.headerIcon || props.module.icon}
		/>
	);
};
