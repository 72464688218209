import { emailPattern } from "@/models/rules/emailPattern";
import { minimumStringLength } from "@/models/rules/minimumLength";
import { required } from "@/models/rules/required";
import { Button, Col, Form, Input, Row } from "antd";
import { LaundrySelectInput } from "../LaundrySelectInput";
import { UserFormViewProps } from "./types";
import { LaundryUnitSelectInput } from "../LaundryUnitSelectInput";
import { LaundryUnit } from "@/models/LaundryUnit";
import { useEffect, useState } from "react";
import { Laundry } from "@/models/Laundry";

export const UserFormView = (props: UserFormViewProps) => {
	const [laundryId, setLaundryId] = useState<string | null | undefined>();
	const [customerId, setCustomerId] = useState<string>();

	const handlePasswordChange = (e) => {
		const passwordValue = e.target.value;
		const repasswordField = props.form.getFieldInstance("repassword");
		if (props.isEditMode) {
			if (passwordValue) {
				props.form.setFields([
					{
						name: "repassword",
						errors: repasswordField.errors,
					},
				]);
			} else {
				props.form.setFields([
					{
						name: "repassword",
						errors: [],
					},
				]);
			}
			props.form.validateFields(["repassword"]);
		}
	};

	useEffect(() => {
		setLaundryId(props.initialValue?.laundryId);
		setCustomerId(props.initialValue?.customerId);

		if (props.isEditMode) {
			const passwordValue = props.form.getFieldValue("password");
			if (passwordValue) {
				props.form.validateFields(["repassword"]);
			}
		}
	}, [
		props.initialValue?.laundryId,
		props.initialValue?.customerId,
		props.form,
		props.isEditMode,
	]);

	return (
		<Form
			layout="vertical"
			form={props.form}
			onFinish={props.onSubmit}
			initialValues={props.initialValue}
		>
			<Row gutter={16}>
				<Col span={12}>
					<Form.Item name="firstName" label="Nome" rules={[required]}>
						<Input disabled={props.submitting} />
					</Form.Item>
				</Col>

				<Col span={12}>
					<Form.Item name="lastName" label="Sobrenome" rules={[required]}>
						<Input disabled={props.submitting} />
					</Form.Item>
				</Col>
			</Row>

			<Form.Item name="email" label="E-mail" rules={[required, emailPattern]}>
				<Input disabled={props.submitting} type="email" />
			</Form.Item>

			<Form.Item
				name="password"
				label="Senha"
				rules={[...(props.isEditMode ? [] : [required]), minimumStringLength(8)]}
				extra={
					props.isEditMode &&
					"A senha do usuário será alterada caso esse campo seja preenchido."
				}
			>
				<Input
					disabled={props.submitting}
					type="password"
					onChange={handlePasswordChange}
				/>
			</Form.Item>

			<Form.Item
				name="repassword"
				label="Confirme a Senha"
				rules={[
					...(props.isEditMode ? [] : [required]),
					minimumStringLength(8),
					({ getFieldValue }) => ({
						validator(_, value) {
							const password = getFieldValue("password");
							if (props.isEditMode && password && !value) {
								return Promise.reject(new Error("Campo obrigatório"));
							}
							if (!value || password === value) {
								return Promise.resolve();
							}
							return Promise.reject(new Error("As senhas não coincidem"));
						},
					}),
				]}
			>
				<Input disabled={props.submitting} type="password" />
			</Form.Item>

			<Form.Item name="laundryId" label="Franquia">
				<LaundrySelectInput
					customerId={customerId}
					onChange={(laundry: Laundry | undefined) => {
						setLaundryId(laundry?.id);
						props.form.setFieldValue("laundryUnitId", undefined);
					}}
					disabled={props.submitting || props.isSelfEdit}
				/>
			</Form.Item>

			<Form.Item
				name="laundryUnitId"
				label="Unidade"
				normalize={(laundryUnit?: LaundryUnit) => laundryUnit?.id}
			>
				<LaundryUnitSelectInput
					laundryId={laundryId}
					laundryUnitId={props.initialValue?.laundryUnitId}
					disabled={props.submitting || !laundryId}
				/>
			</Form.Item>

			{/* <Form.Item dependencies={["laundryId"]}>
				{({ getFieldValue }) => (
					<Form.Item name="role" label="Perfil" rules={[required]}>
						<UserRoleSelectInput
							disabled={props.submitting || props.isSelfEdit}
							customer={props.authenticatedUser?.scopedCustomer}
							// consumerOnly={!!getFieldValue("laundryId")}
						/>
					</Form.Item>
				)}
			</Form.Item> */}

			<Form.Item name="customerId" label="Cliente" rules={[required]} hidden>
				<Input disabled={props.submitting} />
			</Form.Item>

			<Row justify="end">
				<Button
					type="primary"
					htmlType="submit"
					disabled={props.submitting}
					loading={props.submitting}
				>
					Salvar
				</Button>
			</Row>
		</Form>
	);
};
