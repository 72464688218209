import { Button } from "antd";
import { Wrapper } from "./style";
import { MachineModuleSubHeaderViewProps } from "./types";

type ComponentType = React.FC<MachineModuleSubHeaderViewProps>;
export const MachineModuleSubHeaderView: ComponentType = (props) => {
	return (
		<Wrapper>
			{!props.addDisabled && (
				<Button disabled={props.addDisabled} onClick={() => props.onAddMachine()}>
					Nova máquina
				</Button>
			)}
		</Wrapper>
	);
};
