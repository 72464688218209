import { Loading } from "@/components/Loading";
import { AuthorizationProvider } from "@/contexts/Authorization";
import { StatusProvider } from "@/contexts/Status";
import { IdentityProvider } from "@contexts/Identity";
import { ConfigProvider, notification, Spin } from "antd";
import { ApplicationGuard } from "../ApplicationGuard";
import { RootProviderProps } from "./types";

export const RootProvider = (props: RootProviderProps) => {
	notification.config({ placement: "bottomRight" });
	Spin.setDefaultIndicator(<Loading />);

	return (
		<StatusProvider>
			<IdentityProvider>
				<AuthorizationProvider>
					<ConfigProvider space={{ size: 16 }}>
						<ApplicationGuard>{props.children}</ApplicationGuard>
					</ConfigProvider>
				</AuthorizationProvider>
			</IdentityProvider>
		</StatusProvider>
	);
};
