import { Module } from "@/models/modules/Module";
import { AppstoreAddOutlined } from "@ant-design/icons";
import { CustomerManagementModuleContent } from "./CustomerManagementModuleContent";
import { CustomerManagementModuleContextProvider } from "./CustomerManagementModuleContext";
import { CustomerManagementModuleSubHeader } from "./CustomerManagementModuleSubHeader";

export const CustomerManagementModule: Module = {
	path: "/customers",
	title: "Clientes",
	resource: "Customer",
	icon: <AppstoreAddOutlined />,
	content: <CustomerManagementModuleContent />,
	subHeader: <CustomerManagementModuleSubHeader />,
	context: CustomerManagementModuleContextProvider,
};
