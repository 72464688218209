import { LaundryForm } from "@/components/LaundryForm";
import { Action } from "@/models/actions/Action";
import { ActionControls } from "@/models/actions/ActionControls";
import { Laundry } from "@/models/Laundry";
import { notification } from "antd";

export class EditLaundryAction implements Action {
	constructor(
		private readonly laundry?: Laundry,
		private readonly onLaundryEdited?: (user: Laundry) => void
	) {}

	getOptions() {
		return {
			width: "40vw",
			title: this.laundry ? "Editar Franquia" : "Adicionar Franquia",
		};
	}

	render(controls: ActionControls) {
		return (
			<LaundryForm
				laundry={this.laundry}
				onSubmitting={() => controls.setCloseable(false)}
				onSubmitted={(laundry) => this.onSuccess(laundry, controls)}
				onSubmissionFailed={(error) => this.onFailure(error, controls)}
			/>
		);
	}

	private onSuccess(laundry: Laundry, controls: ActionControls) {
		controls.setCloseable(true);
		controls.close();

		notification.success({ message: "Franquia salva com sucesso!" });

		this.onLaundryEdited?.(laundry);
	}

	private onFailure(_error: Error, controls: ActionControls) {
		controls.setCloseable(true);

		notification.error({ message: "Erro ao salvar Franquia" });
	}
}
