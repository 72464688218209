import { useDashboardManagementModuleContext } from "../DashboardModuleContext";
import { DashboardModuleContentProps } from "./types";
import { DashboardModuleContentView } from "./view";

type ComponentType = React.FC<DashboardModuleContentProps>;
export const DashboardModuleContent: ComponentType = (props) => {
	const { loading, reports, handleFilterChange } =
		useDashboardManagementModuleContext();
	return (
		<DashboardModuleContentView
			reports={reports}
			loading={loading}
			handleFilterChange={handleFilterChange}
		/>
	);
};
