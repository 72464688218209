import { AddressForm } from "@/components/AddressForm";
import { MultipleStep, Step } from "@/components/MultipleStep";
import { BusinessTimesForm } from "../BusinessTimesForm";
import { LaundryUnitIdentificationForm } from "./components/LaundryUnitIdentificationForm";
import { LaundryUnitFormViewProps } from "./types";

export const LaundryUnitFormView = (props: LaundryUnitFormViewProps) => {
	return (
		<MultipleStep>
			<Step title="Identificação">
				{({ moveToNextStep }) => (
					<LaundryUnitIdentificationForm
						identification={props.identification}
						onSubmit={(identification) =>
							props.onIdentificationSubmitted(identification).then(moveToNextStep)
						}
					/>
				)}
			</Step>
			<Step title="Endereço">
				{({ moveToPreviousStep, moveToNextStep }) => (
					<AddressForm
						address={props.address}
						onCancel={moveToPreviousStep}
						onSubmitted={(address) =>
							props.onAddressSubmitted(address).then(moveToNextStep)
						}
					/>
				)}
			</Step>
			<Step title="Horário de funcionamento">
				{({ moveToPreviousStep }) => (
					<BusinessTimesForm
						businessTimes={props.businessTimes}
						onCancel={moveToPreviousStep}
						onSubmit={(businessTimes) =>
							props.onBusinessTimesSubmitted(businessTimes).then(props.onSubmit)
						}
					/>
				)}
			</Step>
		</MultipleStep>
	);
};
