import { EditDeviceAction } from "@/actions/EditDeviceAction";
import { useHttp } from "@/application/HttpClient";
import { useStatusContext } from "@/contexts/Status";
import { Device } from "@/models/Device";
import { deviceService } from "@/services/device";
import { Modal, notification } from "antd";
import { useCallback, useState } from "react";
import { LogDeviceAction } from "../../actions/LogDeviceAction";
import { DeviceListProps } from "./types";
import { DeviceListView } from "./view";
import { pumpService } from "@/services/pump";

type ComponentType = React.FC<DeviceListProps>;
export const DeviceList: ComponentType = ({
	onDeviceEdited,
	onDeviceDeleted,
	handleSortChange,
	handleFilterChange,
	...props
}) => {
	const [pagination, setPagination] = useState({
		current: 1,
		pageSize: 5,
		total: 0,
	});

	const handleTableChange = (pagination: any, device: any) => {
		setPagination(pagination);
		setSubmitting(true);
		const resumeRequest = request(deviceService.get, {
			deviceId: device.id,
		});
		const logsRequest = request(deviceService.getLogs, {
			deviceId: device.id,
			page: 1,
			limit: 10,
		});

		Promise.all([resumeRequest, logsRequest])
			.then(([resume, logs]: any) => {
				onActionChange({
					action: new LogDeviceAction(
						device.id,
						device.serialCode,
						onOpen,
						resume,
						logs,
						{
							current: pagination.current,
							pageSize: pagination.pageSize,
							total: logs.totalItems,
						},
						handleTableChange
					),
				});
			})
			.catch((error) => {
				console.log(error);
				notification.error({ message: "Pelo menos uma solicitação falhou" });
			})
			.finally(() => setSubmitting(false));
	};

	const { request } = useHttp();
	const { onActionChange } = useStatusContext();

	const [submitting, setSubmitting] = useState<boolean>(false);
	const [open, setOpen] = useState(false);
	const [log, setLog] = useState(null);

	const onEditDevice = useCallback(
		(device: Device) => {
			onActionChange({
				action: new EditDeviceAction(device),
				onActionEnd: () => onDeviceEdited?.(),
			});
		},
		[onActionChange, onDeviceEdited]
	);

	const onSort = useCallback((field: string, sortOrder: string) => {
		handleSortChange(field, sortOrder);
		onDeviceDeleted?.();
	}, []);

	const onFilter = useCallback((field: string, text: string) => {
		handleFilterChange(field, text);
		onDeviceDeleted?.();
	}, []);

	const onOpen = (record: any) => {
		setOpen(!open);
		setLog(record?.raw);
	};

	const onLogDevice = (deviceId: string, serialCode: string) => {
		setSubmitting(true);
		const resumeRequest = request(deviceService.get, { deviceId, page: 1, limit: 10 });
		const logsRequest = request(deviceService.getLogs, {
			deviceId,
			page: 1,
			limit: 10,
		});

		Promise.all([resumeRequest, logsRequest])
			.then(([resume, logs]: any) => {
				setPagination({
					current: 1,
					pageSize: 5,
					total: logs.totalItems,
				});

				onActionChange({
					action: new LogDeviceAction(
						deviceId,
						serialCode,
						onOpen,
						resume,
						logs,
						{
							current: 1,
							pageSize: 5,
							total: logs.totalItems,
						},
						handleTableChange,
						false,
						undefined,
						onUpdateRec
					),
				});
			})
			.catch((error) => {
				console.log(error);
				notification.error({ message: "Pelo menos uma solicitação falhou" });
			})
			.finally(() => setSubmitting(false));
	};

	const onDeleteDevice = useCallback(
		(device: Device) => {
			setSubmitting(true);
			request(deviceService.delete, { deviceId: device.id })
				.then(() => onDeviceDeleted?.())
				.catch(() => notification.error({ message: "Erro ao remover equipamento" }))
				.finally(() => setSubmitting(false));
		},
		[onDeviceDeleted, request]
	);

	const onMoveToTrialDevice = useCallback(
		(device: Device) => {
			setSubmitting(true);
			request(deviceService.moveToTrial, { deviceId: device.id })
				.then(() => onDeviceDeleted?.())
				.catch(() => notification.error({ message: "Erro ao mover equipamento" }))
				.finally(() => setSubmitting(false));
		},
		[onDeviceDeleted, request]
	);

	const onUpdateRec = (recipientUpdate: any) => {
		setSubmitting(true);
		request(pumpService.edit, recipientUpdate)
			.then(() =>
				notification.success({
					message: recipientUpdate.recipientId
						? `Recipiente atribuido com sucesso!`
						: `Recipiente removido com sucesso!`,
				})
			)
			.catch(() => notification.error({ message: "Erro ao atribuir recipiente" }))
			.finally(() => setSubmitting(false));
	};

	const isDeviceDeletable = useCallback((_device: Device) => true, []);

	return (
		<>
			<Modal
				style={{
					maxHeight: "80vh",
					overflowY: "hidden",
				}}
				bodyStyle={{
					maxHeight: "80vh",
					overflowY: "auto",
				}}
				title="Detalhe do log"
				centered
				visible={open}
				onOk={() => setOpen(false)}
				onCancel={() => setOpen(false)}
				width={1000}
			>
				<pre>{JSON.stringify(log, null, 2)}</pre>
			</Modal>

			<DeviceListView
				onMoveToTrialDevice={onMoveToTrialDevice}
				loading={props.loading || submitting}
				devices={props.devices}
				onEditDevice={onEditDevice}
				onLogDevice={onLogDevice}
				onDeleteDevice={onDeleteDevice}
				isDeviceDeletable={isDeviceDeletable}
				onOpen={onOpen}
				onSort={onSort}
				onFilter={onFilter}
				total={props.total}
				onPagination={props.onPagination}

			/>
		</>
	);
};
