import { EditRecipientAction } from "@/actions/EditRecipientAction";
import { NewChemicalRecipientRefillAction } from "@/actions/NewChemicalRecipientRefillAction";
import { useHttp } from "@/application/HttpClient";
import { useStatusContext } from "@/contexts/Status";
import { ChemicalRecipient } from "@/models/ChemicalRecipient";
import { chemicalRecipientService } from "@/services/chemicalRecipient";
import { notification } from "antd";
import { useCallback, useState } from "react";
import { RecipientListProps } from "./types";
import { RecipientListView } from "./view";
import { ShowDevicesWithPumpAction } from "@/actions/ShowDevicesWithPumpAction";
import { ChemicalRecipientRefill } from "@/models/ChemicalRecipientRefill";
import { chemicalRecipientRefillService } from "@/services/chemicalRecipientRefill";

type ComponentType = React.FC<RecipientListProps>;
export const RecipientList: ComponentType = ({
	onRecipientEdited,
	onRecipientDeleted,
	handleSortChange,
	handleFilterChange,
	...props
}) => {
	const { request } = useHttp();
	const { onActionChange } = useStatusContext();

	const [submitting, setSubmitting] = useState<boolean>(false);

	const onEditRecipient = useCallback(
		(recipient: ChemicalRecipient) => {
			onActionChange({
				action: new EditRecipientAction(recipient),
				onActionEnd: () => onRecipientEdited?.(),
			});
		},
		[onActionChange, onRecipientEdited]
	);

	const onNewRefill = useCallback(
		(recipient: ChemicalRecipient) => {
			onActionChange({
				action: new NewChemicalRecipientRefillAction(undefined, recipient),
				onActionEnd: () => onRecipientEdited?.(),
			});
		},
		[onActionChange, onRecipientEdited]
	);

	const onDeleteChemicalRecipientRefill = useCallback(
		(refill: ChemicalRecipientRefill) => {
			setSubmitting(true);
			request(chemicalRecipientRefillService.delete, { chemicalRecipientRefillId: refill.id })
				.then(() => onRecipientDeleted?.())
				.catch(() => notification.error({ message: "Erro ao remover refil" }))
				.finally(() => setSubmitting(false));
		},
		[request]
	);

	const onSort = useCallback((field: string, sortOrder: string) => {
		console.log(field, sortOrder)
		handleSortChange(field, sortOrder);
		onRecipientDeleted?.();
	}, []);

	const onFilter = useCallback((field: string, text: string) => {
		handleFilterChange(field, text);
		onRecipientDeleted?.();
	}, []);

	const onShowDevices = useCallback(
		(recipient: ChemicalRecipient) => {
			onActionChange({
				action: new ShowDevicesWithPumpAction(recipient, onDeleteChemicalRecipientRefill),
				onActionEnd: () => onRecipientEdited?.(),
			});
		},
		[onActionChange, onRecipientEdited]
	);

	const onDeleteRecipient = useCallback(
		(recipient: ChemicalRecipient) => {
			setSubmitting(true);
			request(chemicalRecipientService.delete, { chemicalRecipientId: recipient.id })
				.then(() => onRecipientDeleted?.())
				.catch(() => notification.error({ message: "Erro ao remover recipiente" }))
				.finally(() => setSubmitting(false));
		},
		[onRecipientDeleted, request]
	);

	const isRecipientDeletable = useCallback((_pump: ChemicalRecipient) => true, []);

	return (
		<RecipientListView
			loading={props.loading || submitting}
			recipients={props.recipients}
			onNewRefill={onNewRefill}
			onShowDevices={onShowDevices}
			onEditRecipient={onEditRecipient}
			onDeleteRecipient={onDeleteRecipient}
			isRecipientDeletable={isRecipientDeletable}
			onSort={onSort}
			onFilter={onFilter}
		/>
	);
};
