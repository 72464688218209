import { EditLaundryUnitAction } from "@/actions/EditLaundryUnitAction";
import { useHttp } from "@/application/HttpClient";
import { useStatusContext } from "@/contexts/Status";
import { LaundryUnit } from "@/models/LaundryUnit";
import { laundryUnitService } from "@/services/laundryUnit";
import { notification } from "antd";
import { useCallback, useState } from "react";
import { LaundryUnitListProps } from "./types";
import { LaundryUnitListView } from "./view";

type ComponentType = React.FC<LaundryUnitListProps>;
export const LaundryUnitList: ComponentType = ({
	onLaundryUnitEdited,
	onLaundryUnitDeleted,
	handleSortChange,
	handleFilterChange,
	...props
}) => {
	const { request } = useHttp();
	const { onActionChange } = useStatusContext();

	const [submitting, setSubmitting] = useState<boolean>(false);

	const onEditLaundryUnit = useCallback(
		(unit: LaundryUnit) => {
			onActionChange({
				action: new EditLaundryUnitAction(unit),
				onActionEnd: () => onLaundryUnitEdited?.(),
			});
		},
		[onActionChange, onLaundryUnitEdited]
	);

	const onSort = useCallback((field: string, sortOrder: string) => {
		handleSortChange(field, sortOrder);
		onLaundryUnitEdited?.();
	}, []);

	const onFilter = useCallback((field: string, text: string) => {
		handleFilterChange(field, text);
		onLaundryUnitEdited?.();
	}, []);


	const onDeleteLaundryUnit = useCallback(
		(unit: LaundryUnit) => {
			setSubmitting(true);
			request(laundryUnitService.delete, { laundryUnitId: unit.id })
				.then(() => onLaundryUnitDeleted?.())
				.catch(() => notification.error({ message: "Erro ao remover unidade" }))
				.finally(() => setSubmitting(false));
		},
		[onLaundryUnitDeleted, request]
	);

	return (
		<LaundryUnitListView
			loading={props.loading || submitting}
			units={props.units}
			onEditLaundryUnit={onEditLaundryUnit}
			onDeleteLaundryUnit={onDeleteLaundryUnit}
			onSort={onSort}
			onFilter={onFilter}
		/>
	);
};
