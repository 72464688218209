import { ActionsComponent } from "@/components/ActionsComponent";
import { Content } from "./components/Content";
import { Context } from "./components/Context";
import { Header } from "./components/Header";
import { Sidebar } from "./components/Sidebar";
import { ContentLayoutWrapper, ContentWrapper, LayoutWrapper, SidebarWrapper } from "./styles";
import { DashboardPageViewProps } from "./types";

export const DashboardPageView = (props: DashboardPageViewProps) => {
	return (
		<LayoutWrapper>
			<SidebarWrapper collapsible>
				<Sidebar onModuleSelected={props.onModuleSelected} />
			</SidebarWrapper>
			<Context>
				{(currentModule) => (
					<ContentLayoutWrapper>
						<Header module={currentModule} />

						<ContentWrapper>
							<Content module={currentModule} />
						</ContentWrapper>

						<ActionsComponent />
					</ContentLayoutWrapper>
				)}
			</Context>
		</LayoutWrapper>
	);
};
