import { Button } from "antd";
import { Wrapper } from "./styles";
import { UserManagementModuleSubHeaderViewProps } from "./types";

type ComponentType = React.FC<UserManagementModuleSubHeaderViewProps>;
export const UserManagementModuleSubHeaderView: ComponentType = (props) => {
	return (
		<Wrapper>
			<Button disabled={props.addDisabled} onClick={() => props.onAddUser()}>
				Novo usuário
			</Button>
		</Wrapper>
	);
};
