import { EditUserAction } from "@/actions/EditUserAction";
import { useHttp } from "@/application/HttpClient";
import { useAuthorizationContext } from "@/contexts/Authorization";
import { useIdentityContext } from "@/contexts/Identity";
import { useStatusContext } from "@/contexts/Status";
import { User } from "@/models/User";
import { userService } from "@/services/user";
import { notification } from "antd";
import { useCallback, useState } from "react";
import { UserListProps } from "./types";
import { UserListView } from "./view";

export const UserList = ({
	onUserEdited,
	onUserDeleted,
	handleSortChange,
	handleFilterChange,
	...props
}: UserListProps) => {
	const { request } = useHttp();
	const { user, updateUser } = useIdentityContext();
	const { onActionChange } = useStatusContext();
	const authorization = useAuthorizationContext();

	const [submitting, setSubmitting] = useState<boolean>(false);

	const onCurrentUserEdited = useCallback(
		(editedUser: User) => editedUser.id === user?.id && updateUser(),
		[updateUser, user]
	);

	const onCurrentUserDeleted = useCallback(
		(deletedUser: User) => deletedUser.id === user?.id && updateUser(),
		[updateUser, user?.id]
	);

	const onEditUser = useCallback(
		(user: User) => {
			onActionChange({
				action: new EditUserAction(user),
				onActionEnd: () => {
					onCurrentUserEdited(user);
					onUserEdited?.();
				},
			});
		},
		[onActionChange, onCurrentUserEdited, onUserEdited]
	);

	const onDeleteUser = useCallback(
		(user: User) => {
			setSubmitting(true);
			request(userService.delete, { userId: user.id })
				.then(() => {
					onCurrentUserDeleted(user);
					onUserDeleted?.();

					notification.success({ message: "Usuário removido com sucesso!" });
				})
				.catch(() => notification.error({ message: "Erro ao remover usuário" }))
				.finally(() => setSubmitting(false));
		},
		[onCurrentUserDeleted, onUserDeleted, request]
	);

	const isUserEditable = useCallback(
		(user: User) => authorization.canUpdate(user),
		[authorization]
	);

	const isUserDeletable = useCallback(
		(user: User) => authorization.canDelete(user),
		[authorization]
	);

	const onSort = useCallback((field: string, sortOrder: string) => {
		handleSortChange(field, sortOrder);
		onUserDeleted?.();
	}, []);

	const onFilter = useCallback((field: string, text: string) => {
		handleFilterChange(field, text);
		onUserDeleted?.();
	}, []);

	return (
		<UserListView
			loading={props.loading || submitting}
			users={props.users}
			authenticatedUser={user}
			onEditUser={onEditUser}
			onDeleteUser={onDeleteUser}
			isUserEditable={isUserEditable}
			isUserDeletable={isUserDeletable}
			onFilter={onFilter}
			onSort={onSort}

		/>
	);
};
