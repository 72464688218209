import { Button, Form, Input, Row, Space } from "antd";
import React from "react";
import { BusinessTimesFormViewProps } from "./types";
import { required } from "@/models/rules";

type ComponentType = React.FC<BusinessTimesFormViewProps>;
export const BusinessTimesFormView: ComponentType = (props) => {
	return (
		<Form layout="vertical" form={props.form} onFinish={props.onSubmit}>
			<Form.Item  name="serialCode" label="Serial" rules={[required]}>
				<Input disabled={props.loading} />
			</Form.Item>
			<Form.Item name="emailDevice" label="Email" rules={[required]}>
				<Input disabled={props.loading} />
			</Form.Item>
			<Form.Item>
				<Row justify="end">
					<Space>
						{props.onCancel && (
							<Button disabled={props.loading} onClick={props.onCancel}>
								Cancelar
							</Button>
						)}
						<Button loading={props.loading} disabled={props.loading} type="primary" htmlType="submit">
							Salvar
						</Button>
					</Space>
				</Row>
			</Form.Item>
		</Form>
	);
};
