import { LoginForm } from "./components/LoginForm";
import { Content, PageWrapper } from "./styles";
import { LoginPageViewProps } from "./types";

export const LoginPageView = (props: LoginPageViewProps) => {
	return (
		<PageWrapper>
			<Content>
				<LoginForm onUserAuthenticated={props.onUserAuthenticated} />
			</Content>
		</PageWrapper>
	);
};
