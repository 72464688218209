import { useHttp } from "@/application/HttpClient";
import { useIdentityContext } from "@/contexts/Identity";
import { laundryService } from "@/services/laundry";
import { Form } from "antd";
import { useCallback, useMemo, useState } from "react";
import { LaundryFormData, LaundryFormProps } from "./types";
import { LaundryFormView } from "./view";

export const LaundryForm = (props: LaundryFormProps) => {
	const { request } = useHttp();
	const { user } = useIdentityContext();
	const { onSubmitting, onSubmitted, onSubmissionFailed } = props;

	const [form] = Form.useForm<LaundryFormData>();
	const [submitting, setSubmitting] = useState<boolean>(false);

	const initialValue = useMemo(
		() => props.laundry || { customerId: user?.scopedCustomer.id },
		[props.laundry, user?.scopedCustomer.id]
	);

	const onSubmit = useCallback(() => {
		if (submitting) return;

		const formData = form.getFieldsValue();
		// TODO: ajustar isso
		if (!formData.emailReport) {
			formData.emailReport = null;
		}
		if (!formData.phone) {
			formData.phone = null;
		}
		if (!formData.secondaryPhone) {
			formData.secondaryPhone = null;
		}

		const doRequest = () =>
			props.laundry
				? request(laundryService.edit, {
						laundryId: props.laundry.id,
						...formData,
				  })
				: request(laundryService.create, formData);

		onSubmitting?.();
		setSubmitting(true);

		doRequest()
			.then((laundry) => onSubmitted?.(laundry))
			.catch((error) => onSubmissionFailed?.(error))
			.finally(() => setSubmitting(false));
	}, [form, onSubmissionFailed, onSubmitted, onSubmitting, props.laundry, request, submitting]);

	return (
		<LaundryFormView
			form={form}
			submitting={submitting}
			isEditMode={!!props.laundry}
			initialValue={initialValue}
			onSubmit={onSubmit}
		/>
	);
};
