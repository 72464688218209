import { Dropdown, Menu } from "antd";
import { TitleWrapper } from "./styles";
import { UserOptionsDetailViewProps } from "./types";

export const UserOptionsDetailView = (props: UserOptionsDetailViewProps) => {
	return (
		<Dropdown overlay={<Menu items={props.options} />}>
			<TitleWrapper>{props.userIdentification}</TitleWrapper>
		</Dropdown>
	);
};
