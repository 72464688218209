import { Laundry } from "@/models/Laundry";
import { DeleteOutlined, EditOutlined, SearchOutlined, ClusterOutlined } from "@ant-design/icons";
import { Button, Input, Popconfirm, Row, Space, Table } from "antd";
import { LaundryListViewProps } from "./types";
import { useState } from "react";

export const LaundryListView = (props: LaundryListViewProps) => {
	const [sortOrder, setSortOrder] = useState<any | null>(null);
	const [searchText, setSearchText] = useState("");

	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		props.onFilter(dataIndex, selectedKeys[0]);
	};

	const handleReset = (clearFilters) => {
		clearFilters();
		setSearchText("");
		props.onFilter("", "");
	};

	const handleSort = (field: string, order: "ascend" | "descend") => {
		if (sortOrder?.field === field && sortOrder?.order === order) {
			setSortOrder({ field, order: order === "ascend" ? "descend" : "ascend" });
		} else {
			setSortOrder({ field, order });
		}

		props.onSort(field, order === "descend" ? "desc" : "asc");
	};

	return (
		<Table
			title={() => "Franquias"}
			rowKey="id"
			dataSource={props.laundries}
			loading={props.loading}
			style={{ width: "100%" }}
		>
			<Table.Column
				showSorterTooltip={false}
				sorter={true}
				onHeaderCell={() => ({
					onClick: () => handleSort("name", sortOrder?.order || "ascend"),
				})}
				filterDropdown={({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
					<div style={{ padding: 8 }}>
						<Input
							placeholder={`Buscar nome`}
							value={selectedKeys[0]?.toString()}
							onChange={(e) =>
								setSelectedKeys(e.target.value ? [e.target.value] : [])
							}
							onPressEnter={() => handleSearch(selectedKeys, confirm, "name")}
							style={{ width: 188, marginBottom: 8, display: "block" }}
						/>
						<Space>
							<Button
								type="primary"
								onClick={() => handleSearch(selectedKeys, confirm, "name")}
								icon={<SearchOutlined />}
								size="small"
								style={{ width: 90 }}
							>
								Buscar
							</Button>
							<Button
								onClick={() => handleReset(clearFilters)}
								size="small"
								style={{ width: 90 }}
							>
								Limpar
							</Button>
						</Space>
					</div>
				)}
				filterIcon={() => {
					return (
						<SearchOutlined style={{ color: searchText ? "#1890ff" : "#00000073" }} />
					);
				}}
				dataIndex="name"
				title="Nome"
			/>
			<Table.Column
				showSorterTooltip={false}
				sorter={true}
				onHeaderCell={() => ({
					onClick: () => handleSort("legalEntityId", sortOrder?.order || "ascend"),
				})}
				filterDropdown={({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
					<div style={{ padding: 8 }}>
						<Input
							placeholder={`Buscar CNPJ`}
							value={selectedKeys[0]?.toString()}
							onChange={(e) =>
								setSelectedKeys(e.target.value ? [e.target.value] : [])
							}
							onPressEnter={() =>
								handleSearch(selectedKeys, confirm, "legalEntityId")
							}
							style={{ width: 188, marginBottom: 8, display: "block" }}
						/>
						<Space>
							<Button
								type="primary"
								onClick={() => handleSearch(selectedKeys, confirm, "legalEntityId")}
								icon={<SearchOutlined />}
								size="small"
								style={{ width: 90 }}
							>
								Buscar
							</Button>
							<Button
								onClick={() => handleReset(clearFilters)}
								size="small"
								style={{ width: 90 }}
							>
								Limpar
							</Button>
						</Space>
					</div>
				)}
				filterIcon={() => {
					return (
						<SearchOutlined style={{ color: searchText ? "#1890ff" : "#00000073" }} />
					);
				}}
				dataIndex="legalEntityId"
				title="CNPJ"
			/>
			<Table.Column<Laundry>
				key="actions"
				title="Ações"
				render={(_, laundry) => (
					<Row justify="center">
						<Space>
							<Button
								ghost
								icon={<EditOutlined />}
								onClick={() => props.onEditLaundry(laundry)}
							/>

							<Button
								ghost
								icon={<ClusterOutlined />}
								onClick={() => props.onShowUnits(laundry)}
							/>

							<Popconfirm
								placement="topRight"
								title="Tem certeza que deseja remover essa Franquia? Essa ação é irreversível."
								onConfirm={() => props.onDeleteLaundry(laundry)}
							>
								<Button ghost danger icon={<DeleteOutlined />} />
							</Popconfirm>
						</Space>
					</Row>
				)}
			/>
		</Table>
	);
};
