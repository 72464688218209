import { CustomerForm } from "@/components/CustomerForm";
import { Action } from "@/models/actions/Action";
import { ActionControls } from "@/models/actions/ActionControls";
import { Customer } from "@/models/Customer";
import { notification } from "antd";

export class EditCustomerAction implements Action {
	constructor(
		private readonly customer?: Customer,
		private readonly onCustomerEdited?: (customer: Customer) => void
	) {}

	getOptions() {
		return {
			width: "40vw",
			title: this.customer ? "Editar cliente" : "Adicionar cliente",
		};
	}

	render(controls: ActionControls) {
		return (
			<CustomerForm
				customer={this.customer}
				onSubmitting={() => controls.setCloseable(false)}
				onSubmitted={(customer) => this.onSuccess(customer, controls)}
				onSubmissionFailed={(error) => this.onFailure(error, controls)}
			/>
		);
	}

	private onSuccess(customer: Customer, controls: ActionControls) {
		controls.setCloseable(true);
		controls.close();

		notification.success({ message: "Cliente salvo com sucesso!" });

		this.onCustomerEdited?.(customer);
	}

	private onFailure(_error: Error, controls: ActionControls) {
		controls.setCloseable(true);

		notification.error({ message: "Erro ao salvar cliente" });
	}
}
