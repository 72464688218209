import { AuthenticationService } from "./types";

export const authenticationService: AuthenticationService = {
	getCurrentUser: () => ({
		path: "/v1/authentication",
		method: "GET",
	}),
	login: ({ username, password }) => ({
		path: "/v1/authentication",
		method: "POST",
		json: { username, password },
	}),
	refresh: () => ({
		path: "/v1/authentication",
		method: "PUT",
	}),
	scope: (json) => ({
		json,
		method: "POST",
		path: "/v1/authentication/scope",
	}),
	logout: () => ({
		path: "/v1/authentication",
		method: "DELETE",
	}),
};
