import { DeviceForm } from "@/components/DeviceForm";
import { Action } from "@/models/actions/Action";
import { ActionControls } from "@/models/actions/ActionControls";
import { Device } from "@/models/Device";
import { notification } from "antd";

export class EditDeviceAction implements Action {
	constructor(private readonly device?: Device) {}

	getOptions() {
		return {
			width: "40vw",
			title: this.device ? "Editar equipamento" : "Adicionar equipamento",
		};
	}

	render(controls: ActionControls) {
		return (
			<DeviceForm
				device={this.device}
				onSubmitting={() => controls.setCloseable(false)}
				onSubmitted={(device) => this.onSuccess(device, controls)}
				onSubmissionFailed={(error) => this.onFailure(error, controls)}
			/>
		);
	}

	private onSuccess(_device: Device, controls: ActionControls) {
		controls.setCloseable(true);
		controls.close();

		notification.success({ message: "Equipamento salvo com sucesso!" });
	}

	private async onFailure(error: any, controls: ActionControls) {

		if (error?.response?.status === 406) {
			const errorMessage = await error?.response.json()
			controls.setCloseable(true);
			notification.error({ message: errorMessage.message });
			return
		}
		controls.setCloseable(true);

		notification.error({ message: "Erro ao salvar equipamento" });
	}
}
