import { useHttp } from "@/application/HttpClient";
import { TrialDevice } from "@/models/Trial";
import { trialService } from "@/services/trial";
import { useCollection } from "@/utilities/collections/useCollection";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { TrialManagementModuleContextValue } from "./types";
import { Filter } from "@/utilities/interface/Filter";

const TrialManagementModuleContext = React.createContext<TrialManagementModuleContextValue>(
	{} as any
);

export const TrialManagementModuleContextProvider = (props: React.PropsWithChildren) => {
	const { request } = useHttp();

	const [loading, setLoading] = useState<boolean>(true);

	const [order, setOrder] = useState<Filter>({
		sortField: "serialCode",
		sortOrder: "desc",
	});

	const [filter, setFilter] = useState<any>({
		filterField: "",
		filterText: "",
	});

	const handleSortChange = (field: string, sortOrder: string) =>
		setOrder({
			sortField: field,
			sortOrder: sortOrder,
		});

	const handleFilterChange = (field: string, text: string) =>
		setFilter({
			filterField: field,
			filterText: text,
		});

	const [devices, setDevices] = useCollection<TrialDevice>([]);

	const onUpdateList = useCallback(() => setLoading(true), []);

	useEffect(() => {
		if (loading) {
			request(trialService.list, { ...order, ...filter })
				.then((trialDevices) => setDevices(trialDevices))
				.catch(() => setDevices([]))
				.finally(() => setLoading(false));
		}
	}, [loading, request, setDevices]);

	return (
		<TrialManagementModuleContext.Provider
			value={{ loading, onUpdateList, devices, handleSortChange, handleFilterChange }}
		>
			{props.children}
		</TrialManagementModuleContext.Provider>
	);
};

export const useTrialManagementModuleContext = () => {
	return useContext(TrialManagementModuleContext);
};
