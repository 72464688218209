import { useMachineModuleContext } from "../MachineModuleContext";
import { MachineModuleContentProps } from "./types";
import { MachineModuleContentView } from "./view";

type ComponentType = React.FC<MachineModuleContentProps>;
export const MachineModuleContent: ComponentType = (props) => {
	const { machines, loading, onUpdateList, handleSortChange, handleFilterChange } =
		useMachineModuleContext();

	return (
		<MachineModuleContentView
			machines={machines}
			loading={loading}
			onDeviceEdited={onUpdateList}
			onDeviceDeleted={onUpdateList}
			handleSortChange={handleSortChange}
			handleFilterChange={handleFilterChange}
		/>
	);
};
