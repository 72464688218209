import { PageHeader, Space } from "antd";
import React from "react";
import { SubHeader } from "./SubHeader";
import { HeaderViewProps } from "./types";
import { UserOptionsDetail } from "./UserOptionsDetail";

export const HeaderView = (props: HeaderViewProps) => {
	const title = (
		<Space>
			{props.icon}
			{props.title}
		</Space>
	);

	return (
		<React.Fragment>
			<PageHeader
				ghost={false}
				title={title}
				extra={[...props.actions, <UserOptionsDetail />]}
			/>
			<SubHeader>{props.subHeader}</SubHeader>
		</React.Fragment>
	);
};
