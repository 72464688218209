import { useAuthorizationContext } from "@/contexts/Authorization";
import { useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { modules } from "../../modules";
import { SidebarProps } from "./types";
import { filterUnauthorizedModules } from "./utilities/filterUnauthorizedModules";
import { getKeysFromItems } from "./utilities/getKeysFromItems";
import { moduleToMenuItem } from "./utilities/moduleToMenuItem";
import { SidebarView } from "./view";

export const Sidebar = ({ onModuleSelected }: SidebarProps) => {
	const location = useLocation();
	const authorization = useAuthorizationContext();

	const visibleModules = useMemo(
		() => filterUnauthorizedModules(modules, (resource) => (authorization.canManage(resource) || authorization.canUpdate(resource))),
		[authorization]
	);

	const items = useMemo(() => visibleModules.map(moduleToMenuItem), [visibleModules]);
	const defaultOpenKeys = useMemo(() => items.map((item) => item!.key as string), [items]);
	const onItemSelected = useCallback((key: string) => onModuleSelected(key), [onModuleSelected]);

	const activeItem = useMemo(() => {
		const keys = getKeysFromItems(items);
		const activeItem =
			keys.find((key) => location.pathname.endsWith(key)) ||
			keys.find((key) => `${location.pathname}/`.endsWith(key));

		return activeItem;
	}, [items, location.pathname]);

	return (
		<SidebarView
			items={items}
			activeItem={activeItem}
			defaultOpenKeys={defaultOpenKeys}
			onItemSelected={onItemSelected}
		/>
	);
};
