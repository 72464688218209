import { useHttp } from "@/application/HttpClient";
import { ChemicalRecipient } from "@/models/ChemicalRecipient";
import { chemicalRecipientService } from "@/services/chemicalRecipient";
import { Button, Select } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ChemicalRecipientSelectInputProps, OptionType } from "./types";

type ComponentType = React.FC<ChemicalRecipientSelectInputProps>;
export const ChemicalRecipientSelectInputWithButton: ComponentType = ({
	onChange,
	onSave,
	...props
}) => {
	const { request } = useHttp();
	const [loading, setLoading] = useState<boolean>(true);
	const [recipients, setRecipients] = useState<ChemicalRecipient[]>([]);
	const [selectedValue, setSelectedValue] = useState<string | undefined>(undefined);
	const [selectedRecipient, setSelectedRecipient] = useState<any>(null);

	useEffect(() => {
		if (!loading) {
			if (typeof props.value !== "string") {
				setSelectedValue(props.value?.id);
			} else {
				setSelectedValue(props.value);
			}
		} else {
			setSelectedValue(undefined);
		}
	}, [loading, props.value]);

	const options = useMemo<OptionType[]>(
		() =>
			recipients.map((payload) => ({
				value: payload.id,
				label: `${payload.produto} - ${payload.volume} ml`,
				payload,
			})),
		[recipients]
	);

	const onFilter = useCallback((value: string, option?: OptionType) => {
		const reference = option?.label.toLowerCase();
		const test = value.toLowerCase();

		return reference?.includes(test) || false;
	}, []);

	const onChildChange = useCallback(
		(option?: OptionType) => {
			setSelectedRecipient(option?.payload);
			onChange?.(option?.payload);
		},
		[onChange]
	);

	useEffect(() => {
		if (loading) {
			if (props?.laundryUnitId) {
				request(chemicalRecipientService.byLaundryUnitId, {
					laundryUnitId: props?.laundryUnitId,
				})
					.then((recipients) => {
						setRecipients(recipients);
					})
					.catch(() => {
						setRecipients([]);
					})
					.finally(() => setLoading(false));
			} else {
				setLoading(false);
			}
		}
	}, [loading, request, setRecipients, props?.recipientId]);

	const handleButtonClick = () => {
		if (onSave) {
			onSave(selectedRecipient);
		}
	};

	return (
		<>
			<div style={{ display: "flex" }}>
				<Select
					style={{ flexGrow: 1, minWidth: "300px" }}
					allowClear
					showSearch
					value={selectedValue}
					disabled={props.disabled}
					loading={loading}
					options={options}
					filterOption={onFilter}
					onChange={(_value, option) => onChildChange(option as OptionType)}
					onClear={() => {
						setSelectedValue(undefined);
					}}
				/>
				<Button type="primary" onClick={handleButtonClick}>
					Salvar
				</Button>
			</div>
		</>
	);
};
