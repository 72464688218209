import { useLaundryUnitManagementModuleContext } from "../LaundryUnitManagementModuleContext";
import { LaundryUnitManagementModuleContentProps } from "./types";
import { LaundryUnitManagementModuleContentView } from "./view";

type ComponentType = React.FC<LaundryUnitManagementModuleContentProps>;
export const LaundryUnitManagementModuleContent: ComponentType = (props) => {
	const { units, loading, onUpdateList, handleSortChange, handleFilterChange } = useLaundryUnitManagementModuleContext();

	return (
		<LaundryUnitManagementModuleContentView
			loading={loading}
			units={units}
			onLaundryUnitEdited={onUpdateList}
			onLaundryUnitDeleted={onUpdateList}
			handleSortChange={handleSortChange}
			handleFilterChange={handleFilterChange}
		/>
	);
};
