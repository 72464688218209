export const calculateTimeRemaining = (date: Date, timeInDays: number) => {
	if (!date) {
		return timeInDays;
	}

	const now: Date = new Date();

	const future: Date = new Date(date);
	future.setDate(future.getDate() + timeInDays);

	return Math.ceil((future.getTime() - now.getTime()) / (1000 * 60 * 60 * 24));
};

export const flattenObject = (obj: any, columnsToRemove: string[] = []) => {
	for (let key in obj) {
		if (typeof obj[key] === "object" && obj[key] !== null) {
			let nestedObj = flattenObject(obj[key], columnsToRemove);
			for (let nestedKey in nestedObj) {
				obj[nestedKey] = nestedObj[nestedKey];
			}
			delete obj[key];
		}
	}
	columnsToRemove.forEach((column) => {
		delete obj[column];
	});
	return obj;
};

export const removeColumnsByKeys = (columns: any, keysToRemove: any) => {
	return columns.filter((column) => !keysToRemove.includes(column.key));
};
