import { useHttp } from "@/application/HttpClient";
import { useIdentityContext } from "@/contexts/Identity";
import { userService } from "@/services/user";
import { Form } from "antd";
import { useCallback, useMemo, useState } from "react";
import { UserFormData, UserFormProps } from "./types";
import { UserFormView } from "./view";
import { Laundry } from "@/models/Laundry";

export const UserForm = (props: UserFormProps) => {
	const { request } = useHttp();
	const { user } = useIdentityContext();
	const { onSubmitting, onSubmitted, onSubmissionFailed } = props;

	const [form] = Form.useForm<UserFormData>();
	const [submitting, setSubmitting] = useState<boolean>(false);

	const initialValue = useMemo(
		() => props.user || { customerId: user?.scopedCustomer.id },
		[props.user, user?.scopedCustomer.id]
	);

	const isEditMode = useMemo(() => !!props.user, [props.user]);
	const isSelfEdit = useMemo(
		() => isEditMode && props.user?.id === user?.id,
		[isEditMode, props.user?.id, user?.id]
	);

	const onSubmit = useCallback(() => {
		if (submitting) return;

		const formData = form.getFieldsValue();
		const laundry: any = formData?.laundryId;
		if (laundry?.id) {
			formData.laundryId = laundry?.id;
		}
		if (!formData?.laundryUnitId) {
			formData.laundryUnitId = null;
		}
		if (!formData?.laundryId) {
			formData.laundryId = null;
		}

		if (!formData?.password || !formData.repassword) {
			delete formData.password;
			delete formData.repassword;
		}
		const doRequest = () =>
			props.user
				? request(userService.edit, {
						userId: props.user.id,
						...formData,
				  })
				: request(userService.create, formData);

		onSubmitting?.();
		setSubmitting(true);

		doRequest()
			.then((user) => onSubmitted?.(user))
			.catch((error) => onSubmissionFailed?.(error))
			.finally(() => setSubmitting(false));
	}, [form, onSubmissionFailed, onSubmitted, onSubmitting, props.user, request, submitting]);

	return (
		<UserFormView
			form={form}
			submitting={submitting}
			isEditMode={isEditMode}
			isSelfEdit={isSelfEdit}
			initialValue={initialValue}
			authenticatedUser={user}
			onSubmit={onSubmit}
		/>
	);
};
