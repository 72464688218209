export enum StatusEmailTerms {
    WAITING = 'WAITING',
    ACCEPT_TERMS = 'ACCEPT',
    REFUSE = 'REFUSE',
	DONT_REQUEST = 'DONT_REQUEST',
	UNKNOWN = 'UNKNOWN',
}

export interface TrialDevice {
    readonly kind: "Trial";
	readonly id: string;
    readonly deviceTrialInfoId: string;
    readonly deviceTrialInfoStartedAt: Date;
    readonly deviceTrialInfoTempoTrial: number;
    readonly deviceTrialInfoPermiteLog: boolean;
    readonly deviceTrialInfoDeviceId: string;
    readonly deviceTrialInfoUpdatedAt: Date;
    readonly deviceTrialInfoCreatedAt: Date;
    readonly deviceTrialInfoDeleted: boolean;
    readonly deviceTrialInfoStatus: string;
    readonly trialEmailId: string;
    readonly trialEmailEmail: string;
    readonly trialEmailCreatedAt: Date;
    readonly trialEmailUpdatedAt: Date;
    readonly trialEmailRemovedAt: Date;
    readonly trialEmailDeleted: boolean;
    readonly trialEmailAceiteTermos: Date;
    readonly trialEmailStatus: StatusEmailTerms;
    readonly serialCode: string;
    readonly deviceName: string;
    readonly deviceIsTrial: boolean;
    readonly customerId: string;
	readonly trialRemaining: number;

}

