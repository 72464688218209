import { useIdentityContext } from "@contexts/Identity";
import { Navigate, Outlet } from "react-router-dom";
import { ProtectedRouteProps } from "./types";

export const ProtectedRoute = (props: ProtectedRouteProps) => {
	const { user } = useIdentityContext();

	const userIsIdentified = !!user;
	const userIsAuthorized = userIsIdentified && true;

	if (userIsAuthorized) return <Outlet />;
	return <Navigate to={props.redirectTo} replace />;
};
