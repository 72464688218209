import { Button, Result } from "antd";
import { PageWrapper } from "./styles";
import { NotFoundPageViewProps } from "./types";

export const NotFoundPageView = (props: NotFoundPageViewProps) => {
	return (
		<PageWrapper>
			<Result
				status="warning"
				title="Página não encontrada"
				extra={
					<Button type="primary" onClick={props.goToInitialPage}>
						Ir para a página inicial
					</Button>
				}
			/>
		</PageWrapper>
	);
};
