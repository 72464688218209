import React, { useEffect } from "react";

interface useDebouncedEffectProps {
	readonly effect: React.EffectCallback;
	readonly dependencies: React.DependencyList;
	readonly time: number;
}

export const useDebouncedEffect = ({ effect, dependencies, time }: useDebouncedEffectProps) => {
	useEffect(() => {
		const timeout = setTimeout(effect, time);
		return () => clearTimeout(timeout);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [...dependencies, time]);
};
