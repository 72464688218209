import { required } from "@/models/rules/required";
import { Button, Checkbox, Col, Form, Input, Row, Space, Typography } from "antd";
import { NumberInput } from "../NumberInput";
import { ChemicalRecipientRefillFormViewProps } from "./types";
import { useState } from "react";

type ComponentType = React.FC<ChemicalRecipientRefillFormViewProps>;
export const ChemicalRecipientRefillFormView: ComponentType = (props) => {
	const [isInputDisabled, setIsInputDisabled] = useState(false);

	const handleCheckboxChange = (e) => {
		setIsInputDisabled(e.target.checked);
		if (e.target.checked) {
			props.form.setFieldsValue({ volume: undefined });
		}
	};

	return (
		<Form
			layout="vertical"
			form={props.form}
			onFinish={props.onSubmit}
			initialValues={props.initialValue}
		>
			<Form.Item name="checkbox" valuePropName="checked">
				<Row justify="start"  align="middle">
					<Col style={{padding: 10}} >
						<div>Tudo:</div>
					</Col>
					<Col>
						<Checkbox onChange={handleCheckboxChange} />
					</Col>
				</Row>
			</Form.Item>

			<Form.Item
				name="volume"
				label="Volume Parcial"
				rules={isInputDisabled ? [] : [required]}
			>
				<NumberInput disabled={props.submitting || isInputDisabled} addonAfter="ml" />
			</Form.Item>
			<Row justify="end">
				<Button
					type="primary"
					htmlType="submit"
					disabled={props.submitting}
					loading={props.submitting}
				>
					Encher
				</Button>
			</Row>
		</Form>
	);
};
