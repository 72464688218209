import { Input } from "antd";
import { ChangeEvent, useCallback } from "react";
import MaskedInput from "react-text-mask";
import { LegalEntityNumberInputProps } from "./types";

const mask = [
	/\d/,
	/\d/,
	".",
	/\d/,
	/\d/,
	/\d/,
	".",
	/\d/,
	/\d/,
	/\d/,
	"/",
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	"-",
	/\d/,
	/\d/,
];

type ComponentType = React.FC<LegalEntityNumberInputProps>;
export const LegalEntityNumberInput: ComponentType = ({ value, onChange, ...props }) => {
	const onInputChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => onChange?.(event.target.value),
		[onChange]
	);

	return (
		<MaskedInput
			mask={mask}
			value={value}
			onChange={onInputChange}
			render={(ref, props) => (
				<Input
					ref={(inputRef) => inputRef?.input && ref(inputRef.input)}
					value={value}
					{...props}
				/>
			)}
		/>
	);
};
