import { Form } from "antd";
import React, { useCallback } from "react";
import { BusinessTimesFormData, BusinessTimesFormProps } from "./types";
import { BusinessTimesFormView } from "./view";

type ComponentType = React.FC<BusinessTimesFormProps>;
export const BusinessTimesForm: ComponentType = ({ onSubmit, ...props }) => {
	const [form] = Form.useForm<BusinessTimesFormData>();

	const onFormSubmit = useCallback(() => {
		const formData = form.getFieldsValue();
		console.log('submit')
		onSubmit?.(formData);
	}, [form, onSubmit]);

	return <BusinessTimesFormView loading={props.loading} form={form} onSubmit={onFormSubmit} onCancel={props.onCancel} />;
};
