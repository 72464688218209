import { CustomerService } from "./types";

export const customerService: CustomerService = {
	list: ({ sortField, sortOrder, filterField, filterText }) => ({
		method: "GET",
		path: `/v1/customer?sortField=${sortField}&sortOrder=${sortOrder}&filterField=${filterField}&filterText=${filterText}`,
	}),
	preRegister: ({ logo, name, legalEntityId, email, emailReport, deviceIdentification }) => {
		const { serialCode, emailDevice } = deviceIdentification;
		const body = new FormData();
		body.append("name", name);
		body.append("email", email);
		body.append("legalEntityId", legalEntityId);
		body.append("serialCode", serialCode);
		body.append("emailDevice", emailDevice);

		if (emailReport) {
			body.append("emailReport", emailReport);
		}

		if (logo) body.append("file", logo);

		return {
			body,
			method: "POST",
			path: "/v1/customer/pre-register",
		};
	},
	registerDevice: (json) => {
		return {
			json,
			method: "POST",
			path: "/v1/customer/register-device",
		};
	},
	create: ({ logo, name, legalEntityId, role, email, emailReport }) => {
		const body = new FormData();
		body.append("name", name);
		if (email) {
			body.append("email", email);
		}

		if (emailReport) {
			body.append("emailReport", emailReport);
		}

		body.append("role", role);
		if (logo) body.append("file", logo);
		body.append("legalEntityId", legalEntityId);

		return {
			body,
			method: "POST",
			path: "/v1/customer",
		};
	},
	edit: ({ customerId, logo, name, legalEntityId, role, email, emailReport }) => {
		const body = new FormData();
		body.append("name", name);
		if (email) {
			body.append("email", email);
		}
		if (emailReport) {
			body.append("emailReport", emailReport);
		}
		body.append("role", role);
		body.append("legalEntityId", legalEntityId);

		if (logo) body.append("file", logo);

		return {
			body,
			method: "PUT",
			path: `/v1/customer/${customerId}`,
		};
	},
	delete: ({ customerId }) => ({
		method: "DELETE",
		path: `/v1/customer/${customerId}`,
	}),
	getLogo: ({ customer, timestamp }) => `/v1/customer/${customer.id}/logo?${timestamp}`,
};
