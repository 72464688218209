import { RegisterDeviceForm } from "@/components/RegisterDeviceForm";
import { Action } from "@/models/actions/Action";
import { ActionControls } from "@/models/actions/ActionControls";
import { Device } from "@/models/Device";
import { notification } from "antd";

export class NewDeviceRegisterAction implements Action {
	getOptions() {
		return {
			width: "40vw",
			title: "Adicionar equipamento",
		};
	}

	render(controls: ActionControls) {
		return (
			<RegisterDeviceForm
				onSubmitting={() => controls.setCloseable(false)}
				onSubmitted={(device) => this.onSuccess(device, controls)}
				onSubmissionFailed={(error) => this.onFailure(error, controls)}
			/>
		);
	}

	private onSuccess(_device: Device, controls: ActionControls) {
		controls.setCloseable(true);
		controls.close();

		notification.success({ message: "Verifique o email!" });
	}

	private async onFailure(error: any, controls: ActionControls) {
		if (error?.response?.status === 406) {
			const errorMessage = await error?.response.json();
			controls.setCloseable(true);
			notification.error({ message: errorMessage.message });
			return;
		}
		controls.setCloseable(true);

		notification.error({ message: "Erro ao tentar registrar o equipamento" });
	}
}
