import { ItemType, MenuItemGroupType } from "antd/lib/menu/hooks/useItems";

const isGroup = (item: ItemType): item is MenuItemGroupType => {
	return (item as MenuItemGroupType).children !== undefined;
};

const recursiveHelper = (items?: ItemType[]): string[] => {
	if (!items || items.length === 0) return [];

	const childrenKeys = items
		.map((item) => (isGroup(item) ? recursiveHelper(item.children) : []))
		.flat();

	const thisLevelItems = items.map((item) => item!.key as string);

	return thisLevelItems.concat(childrenKeys);
};

export const getKeysFromItems = (items: ItemType[]): string[] => {
	return recursiveHelper(items).sort((a, b) => b.length - a.length);
};
