import { LocationService } from "./types";

export const locationService: LocationService = {
	listCountries: () => ({
		method: "GET",
		path: "/v1/location/countries",
	}),
	listStates: ({ countryId }) => ({
		method: "GET",
		path: `/v1/location/countries/${countryId}/states`,
	}),
	listCities: ({ countryId, stateId }) => ({
		method: "GET",
		path: `/v1/location/countries/${countryId}/states/${stateId}/cities`,
	}),
};
