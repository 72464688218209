import { Button, DatePicker } from "antd";
import { FilterReportViewProps } from "./types";
import { LaundryUnitSelectInput } from "../LaundryUnitSelectInput";
import { DeviceAllSelectInput } from "../DeviceAllSelectInput";
import { useState } from "react";
import locale from "antd/es/date-picker/locale/pt_BR";

export const FilterReportView = (props: FilterReportViewProps) => {
	const [selectedRange, setSelectedRange] = useState<any>();
	const [laundryUnit, setLaundryUnit] = useState<any>();
	const [device, setDevice] = useState<any>();

	const handleDateChange = (dates) => {
		setSelectedRange(dates);
	};

	const handleLaundryUnitChange = (laundryUnit) => {
		setLaundryUnit(laundryUnit);
	};

	const handleDeviceChange = (device) => {
		setDevice(device);
	};

	const generateReport = () => {
		const formattedStartDate = selectedRange[0] ? selectedRange[0].format("DD-MM-YYYY") : "";
		const formattedEndDate = selectedRange[1] ? selectedRange[1].format("DD-MM-YYYY") : "";

		if (selectedRange[0] && selectedRange[1] && laundryUnit !== null && device !== null) {
			props.handleFilterChange(
				formattedStartDate,
				formattedEndDate,
				laundryUnit.id,
				device.id
			);
		}
	};

	return (
		<div style={{ padding: "20px", display: "flex", alignItems: "center" }}>
			<DatePicker.RangePicker
			    locale={locale}
				format="DD/MM/YYYY"
				style={{ width: "50%" }}
				value={selectedRange}
				disabled={props.loading}
				onChange={handleDateChange}
			/>
			<LaundryUnitSelectInput
				disabled={props.loading}
				style={{ width: "35%" }}
				onChange={handleLaundryUnitChange}
			/>
			<DeviceAllSelectInput
				disabled={props.loading}
				laundryUnitId={laundryUnit?.id}
				style={{ width: "35%" }}
				onChange={handleDeviceChange}
			/>
			<Button type="primary" onClick={generateReport} disabled={!device || props.loading}>
				Gerar Relatório
			</Button>
		</div>
	);
};
