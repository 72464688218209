import { Loading } from "../Loading";
import { ReportListProps } from "./types";
import { WeeklyReportView } from "./view";
import "./style.css";

type ComponentType = React.FC<ReportListProps>;
export const WeeklyReport: ComponentType = (props) => {
	const { reports, loading } = props;

	return (
		<>
			{loading ? (
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Loading />
				</div>
			) : (
				reports?.map((report) => <WeeklyReportView report={report} />)
			)}
		</>
	);
};
