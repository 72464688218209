import { RecipientList } from "@/components/RecipientList";
import { RecipientManagementModuleContentViewProps } from "./types";

type ComponentType = React.FC<RecipientManagementModuleContentViewProps>;
export const RecipientManagementModuleContentView: ComponentType = (props) => {
	return (
		<RecipientList
		recipients={props.recipients}
			loading={props.loading}
			onRecipientEdited={props.onRecipientEdited}
			onRecipientDeleted={props.onRecipientDeleted}
			handleSortChange={props.handleSortChange}
			handleFilterChange={props.handleFilterChange}
		/>
	);
};
