import { useHttp } from "@/application/HttpClient";
import { Laundry } from "@/models/Laundry";
import { laundryService } from "@/services/laundry";
import { useCollection } from "@/utilities/collections/useCollection";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { LaundryManagementModuleContextValue } from "./types";
import { Filter } from "@/utilities/interface/Filter";

const LaundryManagementModuleContext = React.createContext<LaundryManagementModuleContextValue>(
	{} as any
);

export const LaundryManagementModuleContextProvider = (props: React.PropsWithChildren) => {
	const { request } = useHttp();

	const [order, setOrder] = useState<Filter>({
		sortField: "createdAt",
		sortOrder: "desc",
	});

	const [filter, setFilter] = useState<any>({
		filterField: "",
		filterText: "",
	});

	const handleSortChange = (field: string, sortOrder: string) =>
		setOrder({
			sortField: field,
			sortOrder: sortOrder,
		});

	const handleFilterChange = (field: string, text: string) =>
		setFilter({
			filterField: field,
			filterText: text,
		});

	const [loading, setLoading] = useState<boolean>(true);
	const [laundries, setLaundries] = useCollection<Laundry>([]);

	const onUpdateList = useCallback(() => setLoading(true), []);

	useEffect(() => {
		if (loading) {
			request(laundryService.list, { ...order, ...filter })
				.then((laundries) => setLaundries(laundries))
				.catch(() => setLaundries([]))
				.finally(() => setLoading(false));
		}
	}, [loading, request, setLaundries]);

	return (
		<LaundryManagementModuleContext.Provider value={{ loading, onUpdateList, laundries, handleSortChange, handleFilterChange }}>
			{props.children}
		</LaundryManagementModuleContext.Provider>
	);
};

export const useLaundryManagementModuleContext = () => {
	return useContext(LaundryManagementModuleContext);
};
