import { Subject } from "@/models/application/Subject";
import { Module } from "@/models/modules/Module";

type PermissionCheck = (resource: Subject) => boolean;

export const filterUnauthorizedModules = (
	modules: Module[],
	isAllowed: PermissionCheck
): Module[] => {
	const visibleModules = modules.filter(
		({ resource }) => {
			return resource === undefined || isAllowed(resource)
		}
	);
	const trimmedVisibleModules = visibleModules.map((module) => ({
		...module,
		children: module.children && filterUnauthorizedModules(module.children, isAllowed),
	}));

	const finalVisibleModulesList = trimmedVisibleModules.filter((module, index) => {
		const initialModule = visibleModules[index];
		const initialModuleHasChildren =
			initialModule.children !== undefined && initialModule.children.length > 0;

		if (!initialModuleHasChildren) return true;
		return module.children !== undefined && module.children.length > 0;
	});

	return finalVisibleModulesList;
};
