import { RangeDateLogSheet } from "@/components/RangeDateLogSheet";
import { Action } from "@/models/actions/Action";
import { Button, Popconfirm, Table } from "antd";
import { formatDateTime, formatDateTimeString } from "../utilities/format/date";
import { ChemicalRecipientSelectInputWithButton } from "@/components/ChemicalRecipientSelectInputWithButton";
import { formatAlert } from "@/utilities/format-alert";
import { flattenObject, removeColumnsByKeys } from "@/utilities/time";

export class LogDeviceAction implements Action {
	constructor(
		private readonly deviceId?: string,
		private readonly serialCode?: string,
		private readonly onOpen?: any,
		private readonly resume?: any,
		private readonly logs?: any,
		private readonly pagination?: any,
		private readonly handleTableChange?: any,
		private readonly trialDevice?: boolean,
		private readonly onAllLogsDelete?: Function | undefined,
		private readonly onAssignRecipient?: Function | undefined,
	) {}
	estiloJSON = {
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
	};

	dataSource = this.resume?.pumps
		.filter((item: any) => item != null)
		.map((item: any) => {
			return {
				index: item?.pump?.id,
				status: item?.pump?.status.toString(),
				currentCalibration: item?.pump?.currentCalibration,
				primeTime: item?.pump?.primeTime,
				pumpMaxTime: item?.pump?.inputSignalMaxTime,
				primeCounterTotalizer: item?.pump?.primeCounterTotalizer,
				primeVolumeTotalizer: item?.pump?.primeVolumeTotalizer,
				pumpCounterTotalizer: item?.pump?.inputSignalCounterTotalizer,
				pumpVolumeTotalizer: item?.pump?.inputSignalVolumeTotalizer,
				deviceId: this.resume?.deviceId,
				pumpId: item?.pumpId,
				recipientId: item?.recipientId,
				laundryUnitId: item?.laundryUnitId,
			};
		});

	columns = [
		{
			title: "Index",
			dataIndex: "index",
			key: "index",
			render: (value: number) => {
				return value + 1;
			},
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
		},
		{
			title: "Calibração Atual",
			dataIndex: "currentCalibration",
			key: "currentCalibration",
		},
		{
			title: "Tempo Prime",
			dataIndex: "primeTime",
			key: "primeTime",
		},
		{
			title: "Tempo Maximo Pump",
			dataIndex: "pumpMaxTime",
			key: "pumpMaxTime",
		},
		{
			title: "Total Contador Prime",
			dataIndex: "primeCounterTotalizer",
			key: "primeCounterTotalizer",
		},
		{
			title: "Total Volume Prime",
			dataIndex: "primeVolumeTotalizer",
			key: "primeVolumeTotalizer",
		},
		{
			title: "Total Contador Pump",
			dataIndex: "pumpCounterTotalizer",
			key: "pumpCounterTotalizer",
		},
		{
			title: "Total Volume Pump",
			dataIndex: "pumpVolumeTotalizer",
			key: "pumpVolumeTotalizer",
		},
		{
			title: "Recipiente",
			dataIndex: "pumpVolumeTotalizer",
			key: "pumpVolumeTotalizer",
			width: 500,
			render: (value, line) => {
				return (
					<>
						<div style={{ width: "100%" }}>
							<ChemicalRecipientSelectInputWithButton
								laundryUnitId={line?.laundryUnitId}
								value={line?.recipientId}
								onSave={(select) => {
									if (this.onAssignRecipient) {
										this.onAssignRecipient({
											recipientId: select?.id || null,
											index: line.index,
											deviceId: line.deviceId,
											pumpId: line.pumpId,
										});
									}
								}}
							/>
						</div>
					</>
				);
			},
		},
	];

	removeColumn = ["id", "updatedAt"];

	dataSourceLogs = this.logs?.logsDevice.map((item: any) => {
		return { ...flattenObject(item?.raw, this.removeColumn), id: item.id };
	});

	columnsLogs = removeColumnsByKeys(
		[
			{
				title: "Data e Hora",
				dataIndex: "dateTime",
				key: "dateTime",
				render: (value, line) => {
					return value instanceof Date
						? formatDateTime(value)
						: formatDateTimeString(value);
				},
			},
			{
				title: "Nome do Dispositivo",
				dataIndex: "deviceName",
				key: "deviceName",
			},
			{
				title: "Modo Iniciado",
				dataIndex: "startedMode",
				key: "startedMode",
			},
			{
				title: "Erro de Entrada da Máquina",
				dataIndex: "errorInputMachine",
				key: "errorInputMachine",
			},
			{
				title: "Alerta",
				dataIndex: "alert",
				key: "alert",
			},
			{
				title: "Versão do Firmware",
				dataIndex: "firmwareVersion",
				key: "firmwareVersion",
			},
			{
				title: "ID",
				dataIndex: "id",
				key: "id",
			},
			{
				title: "Nome",
				dataIndex: "Name",
				key: "Name",
			},
			{
				title: "Status",
				dataIndex: "status",
				key: "status",
			},
			{
				title: "Tempo Primário",
				dataIndex: "primeTime",
				key: "primeTime",
			},
			{
				title: "Origem do Sinal",
				dataIndex: "signalOrigin",
				key: "signalOrigin",
			},
			{
				title: "Calibração Atual",
				dataIndex: "currentCalibration",
				key: "currentCalibration",
			},
			{
				title: "Tempo Máximo do Sinal de Entrada",
				dataIndex: "inputSignalMaxTime",
				key: "inputSignalMaxTime",
			},
			{
				title: "Totalizador de Volume Primário",
				dataIndex: "primeVolumeTotalizer",
				key: "primeVolumeTotalizer",
			},
			{
				title: "Totalizador de Contagem Primário",
				dataIndex: "primeCounterTotalizer",
				key: "primeCounterTotalizer",
			},
			{
				title: "Totalizador de Volume do Sinal de Entrada",
				dataIndex: "inputSignalVolumeTotalizer",
				key: "inputSignalVolumeTotalizer",
			},
			{
				title: "Totalizador de Contagem do Sinal de Entrada",
				dataIndex: "inputSignalCounterTotalizer",
				key: "inputSignalCounterTotalizer",
			},
			{
				title: "E-mail do Cliente",
				dataIndex: "emailClient",
				key: "emailClient",
			},
			{
				title: "Hora",
				dataIndex: "time",
				key: "time",
			},
		],
		this.removeColumn
	);

	getOptions() {
		return {
			width: "90vw",
			title: `Equipamento: ${this.serialCode}`,
		};
	}

	render() {
		return (
			<>
				<h3>
					Data e hora utilmo log:{" "}
					<span>
						{this.resume?.lastLog instanceof Date
							? formatDateTime(this.resume?.lastLog)
							: this.resume?.lastLog}
					</span>
				</h3>
				<h3>
					Ultimo alerta:{" "}
					<span>
						<span>
							{" "}
							{formatAlert(
								JSON.stringify(this.resume?.alert?.alert, null, 2),
								this.resume?.alert?.alert
							)}{" "}
						</span>
					</span>
				</h3>
				<h3>
					Nome do dispositivo: <span>{this.resume?.deviceName || "-"}</span>
				</h3>{" "}
				<h3>
					Versão do Firmware: <span>{this.resume?.firmware || "-"}</span>
				</h3>
				<Table dataSource={this.dataSource} columns={this.columns} />
				<h3>Planilha de logs</h3>
				<RangeDateLogSheet deviceId={this.deviceId}></RangeDateLogSheet>
				{this.trialDevice ? (
					<>
						<h3 style={{ marginTop: "2rem" }}>Deletar todos os logs</h3>
						<Popconfirm
							placement="topRight"
							disabled={!this.trialDevice}
							title="Tem certeza que deseja remover todos os logs deste equipamento? Essa ação é irreversível."
							onConfirm={() => {
								if (this.onAllLogsDelete) {
									this.onAllLogsDelete();
								}
							}}
						>
							<Button ghost danger disabled={!this.trialDevice} type="primary">
								Deletar logs
							</Button>
						</Popconfirm>
					</>
				) : (
					<></>
				)}
				<Table dataSource={this.dataSourceLogs} columns={this.columnsLogs} />
			</>
		);
	}
}
