import { useIdentityContext } from "@/contexts/Identity";
import { authenticationService } from "@/services/authentication";
import { createUrl } from "@/utilities/text/createUrl";
import { saveAs } from "file-saver";
import ky from "ky";
import { useCallback, useMemo } from "react";
import { EndpointBuilder } from "./EndpointBuilder";
import { parseJsonAttribute } from "./parseJsonAttribute";
import { GenericRequestDispatcher, RequestDispatcher } from "./RequestDispatcher";
import { ResponseHook } from "./ResponseHook";
import { notification } from "antd";

const baseHttp = ky.create({
	credentials: "include",
	prefixUrl: process.env.REACT_APP_BASE_URL,
	parseJson: (text) => JSON.parse(text, parseJsonAttribute),
	timeout: 300000,
});

export const useHttp = () => {
	const { logout } = useIdentityContext();

	const baseHttpWithUserGuard = useMemo(() => {
		const logoutIfUnauthorized: ResponseHook = async (_request, _options, response) => {
			if (response.status === 401) await logout();
			console.log(response.status);
			return response;
		};

		return baseHttp.extend({
			hooks: { afterResponse: [logoutIfUnauthorized] },
		});
	}, [logout]);

	const dispatchRequest = useCallback<GenericRequestDispatcher>((description, request, http) => {
		const { path, method, json, body } = description(request);
		const pathWithoutInitialSlash = path.replace(/^\//, "");

		return http(pathWithoutInitialSlash, { json, body, method }).json();
	}, []);

	const http = useMemo(() => {
		const refreshAccessToken = async () => {
			await dispatchRequest(authenticationService.refresh, {}, baseHttpWithUserGuard);
		};

		const downloadIfContentDispositionExists: ResponseHook = async (
			_request,
			_options,
			response
		) => {
			const contentDisposition = response.headers.get("content-disposition");
			const contentDispositionMatchesFilename = contentDisposition?.match(/filename=.+/g);

			// debugger

			if (contentDispositionMatchesFilename) {
				const responseClone = response.clone();
				const blob = await responseClone.blob();
				const filename = contentDispositionMatchesFilename[0]
					.replace("filename=", "")
					// .replace(/"$/g, "")
					.trim();

				saveAs(blob, filename);
				return new Response("{}", { status: response.status });
			}

			return response;
		};

		const handleForbidden: ResponseHook = async (_request, _options, response) => {
			console.log("handleForbidden");
			if (response.status === 403) {
				notification.destroy();
				notification.error({
					message: "Erro",
					description:
						"Acesso proibido! Você não tem permissão para acessar este recurso.",
					duration: 10,
				});
			}
			return response;
		};

		return baseHttp.extend({
			retry: { statusCodes: [401], methods: ["get", "post", "patch", "delete", "options"] },
			hooks: {
				beforeRetry: [refreshAccessToken],
				afterResponse: [downloadIfContentDispositionExists, handleForbidden],
			},
		});
	}, [dispatchRequest, baseHttpWithUserGuard]);

	const request = useCallback<RequestDispatcher>(
		(description, request) => dispatchRequest(description, request, http),
		[dispatchRequest, http]
	);

	const endpoint = useCallback<EndpointBuilder>(
		(description, request) =>
			createUrl(`${process.env.REACT_APP_BASE_URL}`, description(request)),
		[]
	);

	return { request, endpoint };
};
