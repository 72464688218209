import { useHttp } from "@/application/HttpClient";
import { Country } from "@/models/location/Country";
import { locationService } from "@/services/location";
import { Select } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { CountryInputProps, CountryOption } from "./types";

type ComponentType = React.FC<CountryInputProps>;
export const CountryInput: ComponentType = ({ onChange, ...props }) => {
	const { request } = useHttp();
	const [loading, setLoading] = useState<boolean>(false);
	const [countries, setCountries] = useState<Country[]>();

	const disabled = useMemo(() => props.disabled || loading, [loading, props.disabled]);

	const childValue = useMemo(() => {
		if (props.value === undefined || typeof props.value === "string") return props.value;
		return props.value?.isoCode;
	}, [props.value]);

	const options = useMemo(() => {
		if (countries === undefined) return [];

		return countries.map<CountryOption>((country) => ({
			value: country?.isoCode,
			label: `${country?.flag} ${country?.name}`,
			payload: country,
		}));
	}, [countries]);

	const filterOption = useCallback((value: string, option?: CountryOption) => {
		if (option === undefined) return false;

		const referenceName = option.payload?.name?.toLowerCase();
		const userInput = value.toLowerCase();

		return referenceName.includes(userInput);
	}, []);

	const onChildChange = useCallback(
		(_value: string, option?: CountryOption | CountryOption[]) => {
			const changedValue =
				option !== undefined ? (option as CountryOption).payload : undefined;

			onChange?.(changedValue);
		},
		[onChange]
	);

	useEffect(() => {
		if (countries === undefined) {
			setLoading(true);
			request(locationService.listCountries, {})
				.then((countries) => setCountries(countries))
				.catch(() => setCountries([]))
				.finally(() => setLoading(false));
		}
	}, [countries, request]);

	return (
		<Select<string, CountryOption>
			showSearch
			allowClear
			loading={loading}
			value={childValue}
			disabled={disabled}
			options={options}
			filterOption={filterOption}
			onChange={onChildChange}
		/>
	);
};
