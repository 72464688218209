import { EditCustomerAction } from "@/actions/EditCustomerAction";
import { useHttp } from "@/application/HttpClient";
import { useAuthorizationContext } from "@/contexts/Authorization";
import { useIdentityContext } from "@/contexts/Identity";
import { useStatusContext } from "@/contexts/Status";
import { Customer } from "@/models/Customer";
import { authenticationService } from "@/services/authentication";
import { customerService } from "@/services/customer";
import { notification } from "antd";
import { useCallback, useState } from "react";
import { CustomerListProps } from "./types";
import { CustomerListView } from "./view";

export const CustomerList = (props: CustomerListProps) => {
	const { onCustomerEdited, onCustomerDeleted, handleSortChange, handleFilterChange } = props;
	const { request } = useHttp();
	const { onActionChange } = useStatusContext();
	const { user, updateUser } = useIdentityContext();
	const authorization = useAuthorizationContext();

	const [submitting, setSubmitting] = useState<boolean>(false);

	const onUserCustomerEdited = useCallback(
		(customer: Customer) => {
			const userCustomerWasEdited = customer.id === user?.customer.id;
			const scopedCustomerWasEdited = customer.id === user?.scopedCustomer.id;
			const shouldUpdateUser = userCustomerWasEdited || scopedCustomerWasEdited;

			if (shouldUpdateUser) updateUser();
		},
		[updateUser, user]
	);

	const onEditCustomer = useCallback(
		(customer: Customer) => {
			onActionChange({
				action: new EditCustomerAction(customer, onUserCustomerEdited),
				onActionEnd: () => onCustomerEdited?.(),
			});
		},
		[onActionChange, onCustomerEdited, onUserCustomerEdited]
	);

	const onScopeCustomer = useCallback(
		(customer: Customer) => {
			setSubmitting(true);
			setTimeout(() => {
				request(authenticationService.scope, { customerId: customer.id })
					.then(() => updateUser())
					.catch(() => updateUser())
					.finally(() => setSubmitting(false));
			}, 1000);
		},
		[request, updateUser]
	);

	const onDeleteCustomer = useCallback(
		(customer: Customer) => {
			setSubmitting(true);
			request(customerService.delete, { customerId: customer.id })
				.then(() => onCustomerDeleted?.())
				.catch(() => notification.error({ message: "Erro ao remover cliente" }))
				.finally(() => setSubmitting(false));
		},
		[onCustomerDeleted, request]
	);

	const isCustomerEditable = useCallback(
		(customer: Customer) => authorization.canUpdate(customer),
		[authorization]
	);

	const isCustomerScopable = useCallback(
		(customer: Customer) =>
			customer.id !== user?.scopedCustomer.id && authorization.canManage(customer),
		[authorization, user?.scopedCustomer.id]
	);

	const isCustomerDeletable = useCallback(
		(customer: Customer) =>
			customer.id !== user?.customer.id &&
			customer.id !== user?.scopedCustomer.id &&
			authorization.canDelete(customer),
		[authorization, user?.customer.id, user?.scopedCustomer.id]
	);

	const onSort = useCallback((field: string, sortOrder: string) => {
		handleSortChange(field, sortOrder);
		onCustomerDeleted?.();
	}, []);

	const onFilter = useCallback((field: string, text: string) => {
		handleFilterChange(field, text);
		onCustomerDeleted?.();
	}, []);

	return (
		<CustomerListView
			loading={props.loading || submitting}
			customers={props.customers}
			onEditCustomer={onEditCustomer}
			onScopeCustomer={onScopeCustomer}
			onDeleteCustomer={onDeleteCustomer}
			isCustomerEditable={isCustomerEditable}
			isCustomerScopable={isCustomerScopable}
			isCustomerDeletable={isCustomerDeletable}
			onSort={onSort}
			onFilter={onFilter}
		/>
	);
};
