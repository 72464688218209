import { ChemicalRecipient } from "@/models/ChemicalRecipient";
import { Button, DatePicker, Space } from 'antd';
import locale from 'antd/es/date-picker/locale/pt_BR';
import { useHttp } from "@/application/HttpClient";
import { deviceService } from "@/services/device";
import { RangeDateLogSheetProps, RangeDateLogSheetViewProps } from "./types";

const { RangePicker } = DatePicker;


type ComponentType = React.FC<RangeDateLogSheetViewProps>;
export const RangeDateLogSheetView: ComponentType = (props) => {

	return (
		<Space direction="horizontal" size={12}>
			<RangePicker
				placeholder={["Data início", "Data final"]}
				locale={locale}
				format="DD/MM/YYYY"
				onChange={props?.onDateChange}
			/>
			<Button type="primary" onClick={() => props?.onClickDownload()} >
				Baixar
			</Button>
			<Button type="primary" onClick={() => props?.onClickDownloadRaw()} >
				Baixar Raw
			</Button>
		</Space>
	);
};
