import { Module } from "@/models/modules/Module";
import { HomeFilled } from "@ant-design/icons";
import { DashboardDeviceModuleContent } from "./DashboardDeviceContent";
import { DashboardDeviceManagementModuleContextProvider } from "./DashboardDeviceContext";
import { BarChartOutlined } from "@ant-design/icons";

export const DashboardDeviceDeviceModule: Module = {
	path: "/report-1",
	title: "Equipamento",
	icon: <BarChartOutlined />,
	headerIcon: <HomeFilled />,
	content: <DashboardDeviceModuleContent />,
	context: DashboardDeviceManagementModuleContextProvider,
};
