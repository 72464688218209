import { format } from "date-fns";

export const formatDate = (date: Date) => {
	if (!date) {
		return;
	}
	return format(date, "dd/MM/yyyy");
};
export const formatDateTime = (date: Date) => {
	if (!date) {
		return;
	}

	return format(date, "dd/MM/yyyy - HH:mm:ss");
};
export const formatDateTimeString = (inputDataHora: string) => {
	if (!inputDataHora) {
		return;
	}
	const [dataPart, horaPart] = inputDataHora.split(" ");
	const [dia, mes, ano] = dataPart.split("-");
	const [horas, minutos, segundos] = horaPart.split(":");

	const data = new Date(
		Number(ano),
		Number(mes) - 1,
		Number(dia),
		Number(horas),
		Number(minutos),
		Number(segundos)
	);

	const dataFormatada = `${dia}/${mes}/${ano} - ${data.toLocaleTimeString()}`;

	return dataFormatada;
};
