import { useHttp } from "@/application/HttpClient";
import { useCollection } from "@/utilities/collections/useCollection";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { DashboardDeviceManagementModuleContextValue } from "./types";
import { deviceService } from "@/services/device";
import { DeviceInfo } from "@/models/DeviceInfo";

const DashboardDeviceManagementModuleContext =
	React.createContext<DashboardDeviceManagementModuleContextValue>({} as any);

export const DashboardDeviceManagementModuleContextProvider = (props: React.PropsWithChildren) => {
	const { request } = useHttp();

	const [order, setOrder] = useState<any>({
		sortField: "",
		sortOrder: "",
	});

	const [filter, setFilter] = useState<any>({
		filterField: "",
		filterText: "",
	});

	const [page, setPage] = useState<any>({
		page: 1,
	});

	const [loading, setLoading] = useState<boolean>(true);
	const [devices, setDevices] = useCollection<DeviceInfo>([]);
	const [total, setTotal] = useState<number>(0);

	const onUpdateList = useCallback(() => setLoading(true), []);

	const handleSortChange = (field: string, sortOrder: string) => {
		setOrder({
			sortField: field,
			sortOrder: sortOrder,
		});
		onUpdateList?.();
	};

	const handleFilterChange = (field: string, text: string) => {
		setFilter({
			filterField: field,
			filterText: text,
		});
		onUpdateList?.();
	};

	const handlePagination = (page: number) => {
		setPage({ page });
		onUpdateList?.();
	};

	useEffect(() => {
		if (loading) {
			request(deviceService.infoDevices, { ...order, ...filter, ...page })
				.then((device) => {
					setDevices(device.rows);
					setTotal(device.total);
				})
				.catch(() => setDevices([]))
				.finally(() => setLoading(false));
		}
	}, [loading, request, setDevices]);

	return (
		<DashboardDeviceManagementModuleContext.Provider
			value={{
				loading,
				onUpdateList,
				devices,
				total,
				handleSortChange,
				handleFilterChange,
				handlePagination,
			}}
		>
			{props.children}
		</DashboardDeviceManagementModuleContext.Provider>
	);
};

export const useDashboardDeviceManagementModuleContext = () => {
	return useContext(DashboardDeviceManagementModuleContext);
};
