import { TrialDeviceList } from "@/components/TrialDevicesList";
import { TrialManagementModuleContentViewProps } from "./types";

type ComponentType = React.FC<TrialManagementModuleContentViewProps>;
export const TrialManagementModuleContentView: ComponentType = (props) => {
	return (
		<TrialDeviceList
			devices={props.devices}
			loading={props.loading}
			onDeviceEdited={props.onDeviceEdited}
			onDeviceDeleted={props.onDeviceDeleted}
			handleSortChange={props.handleSortChange}
			handleFilterChange={props.handleFilterChange}
		/>
	);
};
