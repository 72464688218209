import { Menu } from "antd";
import { SidebarHeader } from "./SidebarHeader";
import { Wrapper } from "./style";
import { SidebarViewProps } from "./types";

export const SidebarView = (props: SidebarViewProps) => {
	return (
		<Wrapper>
			<SidebarHeader />

			<Menu
				theme="dark"
				mode="inline"
				items={props.items}
				selectedKeys={props.activeItem ? [props.activeItem] : []}
				defaultOpenKeys={props.defaultOpenKeys}
				onSelect={({ key }) => props.onItemSelected(key)}
			/>
		</Wrapper>
	);
};
