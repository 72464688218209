import { DayOfWeek } from "@/models/time/DayOfWeek";
import { enumToOptions } from "@/utilities/collections/enumToOptions";
import { sortBy } from "@/utilities/collections/sortBy";
import { formatDayOfWeek } from "@/utilities/format/dayOfWeek";
import { CloseOutlined } from "@ant-design/icons";
import { Button, Table } from "antd";
import { useMemo } from "react";
import { BusinessTimesTableProps } from "./types";

const daysOfWeek = enumToOptions(DayOfWeek);

export const BusinessTimesTable = (props: BusinessTimesTableProps) => {
	const businessTimes = useMemo(
		() => sortBy(props.businessTimes, daysOfWeek, (a, b) => a.dayOfWeek === b.value),
		[props.businessTimes]
	);

	return (
		<Table dataSource={businessTimes} pagination={false} bordered size="small">
			<Table.Column dataIndex="opening" title="Abertura" />
			<Table.Column dataIndex="closing" title="Fechamento" />
			<Table.Column dataIndex="dayOfWeek" title="Dia da semana" render={formatDayOfWeek} />
			<Table.Column
				render={(businessTime) => (
					<Button
						ghost
						icon={<CloseOutlined />}
						onClick={() => props.onBusinessTimeDeleted(businessTime)}
					/>
				)}
			/>
		</Table>
	);
};
