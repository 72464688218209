import locale from "antd/es/date-picker/locale/pt_BR";
import { LaundryUnit } from "@/models/LaundryUnit";
import { formatDateTime } from "@/utilities/format/date";
import { DeleteOutlined, EditOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Input, DatePicker, Popconfirm, Row, Space, Table } from "antd";
import { LaundryUnitListViewProps } from "./types";
import { useState } from "react";
import { formatOptional } from "@/utilities/format/optional";

export const LaundryUnitListView = (props: LaundryUnitListViewProps) => {
	const [sortOrder, setSortOrder] = useState<any | null>(null);
	const [searchText, setSearchText] = useState("");

	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		props.onFilter(dataIndex, selectedKeys[0]);
	};

	const handleSearchEnum = (selectedKeys, confirm, dataIndex) => {
		confirm();
		props.onFilter(dataIndex, selectedKeys);
	};

	const handleReset = (clearFilters) => {
		clearFilters();
		setSearchText("");
		props.onFilter("", "");
	};

	const handleSort = (field: string, order: "ascend" | "descend") => {
		if (sortOrder?.field === field && sortOrder?.order === order) {
			setSortOrder({ field, order: order === "ascend" ? "descend" : "ascend" });
		} else {
			setSortOrder({ field, order });
		}

		props.onSort(field, order === "descend" ? "desc" : "asc");
	};

	return (
		<Table
			rowKey="id"
			title={() => "Unidades"}
			dataSource={props.units}
			loading={props.loading}
			style={{ width: "100%" }}
		>
			<Table.Column
				showSorterTooltip={false}
				sorter={true}
				onHeaderCell={() => ({
					onClick: () => handleSort("name", sortOrder?.order || "ascend"),
				})}
				filterDropdown={({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
					<div style={{ padding: 8 }}>
						<Input
							placeholder={`Buscar unidade`}
							value={selectedKeys[0]?.toString()}
							onChange={(e) =>
								setSelectedKeys(e.target.value ? [e.target.value] : [])
							}
							onPressEnter={() => handleSearch(selectedKeys, confirm, "name")}
							style={{ width: 188, marginBottom: 8, display: "block" }}
						/>
						<Space>
							<Button
								type="primary"
								onClick={() => handleSearch(selectedKeys, confirm, "name")}
								icon={<SearchOutlined />}
								size="small"
								style={{ width: 90 }}
							>
								Buscar
							</Button>
							<Button
								onClick={() => handleReset(clearFilters)}
								size="small"
								style={{ width: 90 }}
							>
								Limpar
							</Button>
						</Space>
					</div>
				)}
				filterIcon={() => {
					return (
						<SearchOutlined style={{ color: searchText ? "#1890ff" : "#00000073" }} />
					);
				}}
				dataIndex="name"
				title="Nome"
			/>
			<Table.Column
				showSorterTooltip={false}
				sorter={true}
				onHeaderCell={() => ({
					onClick: () => handleSort("address", sortOrder?.order || "ascend"),
				})}
				filterDropdown={({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
					<div style={{ padding: 8 }}>
						<Input
							placeholder={`Buscar cep`}
							value={selectedKeys[0]?.toString()}
							onChange={(e) =>
								setSelectedKeys(e.target.value ? [e.target.value] : [])
							}
							onPressEnter={() => handleSearch(selectedKeys, confirm, "address")}
							style={{ width: 188, marginBottom: 8, display: "block" }}
						/>
						<Space>
							<Button
								type="primary"
								onClick={() => handleSearch(selectedKeys, confirm, "address")}
								icon={<SearchOutlined />}
								size="small"
								style={{ width: 90 }}
							>
								Buscar
							</Button>
							<Button
								onClick={() => handleReset(clearFilters)}
								size="small"
								style={{ width: 90 }}
							>
								Limpar
							</Button>
						</Space>
					</div>
				)}
				filterIcon={() => {
					return (
						<SearchOutlined style={{ color: searchText ? "#1890ff" : "#00000073" }} />
					);
				}}
				dataIndex={["address", "zipCode"]}
				title="CEP"
			/>

			<Table.Column
				showSorterTooltip={false}
				dataIndex={["laundry", "name"]}
				title="Franquia"
				sorter={true}
				onHeaderCell={() => ({
					onClick: () => handleSort("laundry", sortOrder?.order || "ascend"),
				})}
				filterDropdown={({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
					<div style={{ padding: 8 }}>
						<Input
							placeholder={`Buscar franquia`}
							value={selectedKeys[0]?.toString()}
							onChange={(e) =>
								setSelectedKeys(e.target.value ? [e.target.value] : [])
							}
							onPressEnter={() => handleSearch(selectedKeys, confirm, "laundry")}
							style={{ width: 188, marginBottom: 8, display: "block" }}
						/>
						<Space>
							<Button
								type="primary"
								onClick={() => handleSearch(selectedKeys, confirm, "laundry")}
								icon={<SearchOutlined />}
								size="small"
								style={{ width: 90 }}
							>
								Buscar
							</Button>
							<Button
								onClick={() => handleReset(clearFilters)}
								size="small"
								style={{ width: 90 }}
							>
								Limpar
							</Button>
						</Space>
					</div>
				)}
				filterIcon={() => {
					return (
						<SearchOutlined style={{ color: searchText ? "#1890ff" : "#00000073" }} />
					);
				}}
				render={formatOptional}
			/>
			<Table.Column
				showSorterTooltip={false}
				sorter={true}
				onHeaderCell={() => ({
					onClick: () => handleSort("updatedAt", sortOrder?.order || "ascend"),
				})}
				filterDropdown={({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
					<div style={{ padding: 8 }}>
						<DatePicker
							locale={locale}
							placeholder="Selecionar data"
							style={{ width: 188, marginBottom: 8, display: "block" }}
							format="DD/MM/YYYY"
							value={selectedKeys[0] as any}
							onChange={(value: any) => {
								if (value) {
									handleSearch(
										[value?.format().split("T")[0]],
										confirm,
										"updatedAt"
									);
								}
								setSelectedKeys(value ? [value] : []);
							}}
						/>
						<Space>
							<Button
								onClick={() => {
									setSelectedKeys([]);
									handleReset(clearFilters);
								}}
								size="small"
								style={{ width: 90 }}
							>
								Limpar
							</Button>
						</Space>
					</div>
				)}
				filterIcon={() => {
					return (
						<SearchOutlined style={{ color: searchText ? "#1890ff" : "#00000073" }} />
					);
				}}
				dataIndex="updatedAt"
				title="Última modificação"
				render={formatDateTime}
			/>
			<Table.Column
				showSorterTooltip={false}
				sorter={true}
				onHeaderCell={() => ({
					onClick: () => handleSort("createdAt", sortOrder?.order || "ascend"),
				})}
				filterDropdown={({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
					<div style={{ padding: 8 }}>
						<DatePicker
							locale={locale}
							placeholder="Selecionar data"
							style={{ width: 188, marginBottom: 8, display: "block" }}
							format="DD/MM/YYYY"
							value={selectedKeys[0] as any}
							onChange={(value: any) => {
								if (value) {
									handleSearch(
										[value?.format().split("T")[0]],
										confirm,
										"createdAt"
									);
								}
								setSelectedKeys(value ? [value] : []);
							}}
						/>
						<Space>
							<Button
								onClick={() => {
									setSelectedKeys([]);
									handleReset(clearFilters);
								}}
								size="small"
								style={{ width: 90 }}
							>
								Limpar
							</Button>
						</Space>
					</div>
				)}
				filterIcon={() => {
					return (
						<SearchOutlined style={{ color: searchText ? "#1890ff" : "#00000073" }} />
					);
				}}
				dataIndex="createdAt"
				title="Data de criação"
				render={formatDateTime}
			/>

			<Table.Column<LaundryUnit>
				key="actions"
				title="Ações"
				render={(_, unit) => (
					<Row justify="center">
						<Space>
							<Button
								ghost
								icon={<EditOutlined />}
								onClick={() => props.onEditLaundryUnit(unit)}
							/>

							<Popconfirm
								placement="topRight"
								title="Tem certeza que deseja remover essa unidade? Essa ação é irreversível."
								onConfirm={() => props.onDeleteLaundryUnit(unit)}
							>
								<Button ghost danger icon={<DeleteOutlined />} />
							</Popconfirm>
						</Space>
					</Row>
				)}
			/>
		</Table>
	);
};
