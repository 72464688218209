import { Module } from "@/models/modules/Module";
import { HomeFilled, HomeOutlined } from "@ant-design/icons";
import { DashboardModuleContent } from "./DashboardModuleContent";
import { DashboardManagementModuleContextProvider } from "./DashboardModuleContext";
import {
	BarChartOutlined,
} from "@ant-design/icons";
import { DashboardDeviceDeviceModule } from "../DashboardDeviceModule";

export const DashboardModule: Module = {
	path: "/",
	title: "Dashboard",
	icon: <HomeOutlined />,
	children: [
		DashboardDeviceDeviceModule,
		{
			path: "/report-2",
			title: "Relatório",
			icon: <BarChartOutlined />,
			headerIcon: <HomeFilled />,
			content: <DashboardModuleContent />,
			context: DashboardManagementModuleContextProvider,
		},
	],
};
