import { MultipleStep, Step } from "@/components/MultipleStep";
import { LaundryUnitFormViewProps } from "./types";
import { BusinessTimesForm } from "../BusinessTimesForm";
import { DeviceIdentificationForm } from "../DeviceForm";
import { Button, Row } from "antd";
import { useNavigate } from "react-router-dom";

export const LaundryUnitFormView = (props: LaundryUnitFormViewProps) => {
	const navigate = useNavigate();

	return (
		<>
			<MultipleStep>
				<Step title="Identificação">
					{({ moveToNextStep }) => (
						<DeviceIdentificationForm
							onCancel={moveToNextStep}
							onSubmit={(businessTimes) => {
								console.log("DeviceIdentificationForm");
								props.onIdentificationSubmitted(businessTimes).then(moveToNextStep);
							}}
						/>
					)}
				</Step>
				<Step title="Equipamento">
					{({ moveToPreviousStep }) => (
						<BusinessTimesForm
							loading={props.loading}
							onCancel={moveToPreviousStep}
							onSubmit={(businessTimes) => {
								console.log("BusinessTimesForm");
								props.onBusinessTimesSubmitted(businessTimes).then(props.onSubmit);
							}}
						/>
					)}
				</Step>
			</MultipleStep>
			<Row>
				<Button
					type="primary"
					onClick={() => {
						navigate("/");
					}}
				>
					Voltar
				</Button>
			</Row>
		</>
	);
};
