import { DashboardPage } from "@/pages/DashboardPage";
import { ProtectedRoute } from "@application/ProtectedRoute";
import { LoginPage } from "@pages/LoginPage";
import { NotFoundPage } from "@pages/NotFoundPage";
import { AnimatePresence } from "framer-motion";
import { Route, Routes, useLocation } from "react-router-dom";
import { AnimatedWrapper } from "../AnimatedWrapper";
import { RegisterClientPage } from "@/pages/RegisterClientPage";

const authenticationUrl = "/";
const dashboardUrl = "/dashboard";
const dashboardModulesUrl = `${dashboardUrl}/*`;

export const Router = () => {
	const location = useLocation();
	const routesKey = location.pathname.split("/")[1] || "default";

	return (
		<AnimatePresence mode="wait">
			<Routes key={routesKey} location={location}>
				<Route
					index
					element={
						<AnimatedWrapper>
							<LoginPage redirectTo={"/dashboard/report-2"} />
						</AnimatedWrapper>
					}
				/>
				<Route
					path="/novo-usuario"
					element={
						<AnimatedWrapper>
							<RegisterClientPage />
						</AnimatedWrapper>
					}
				/>
				<Route element={<ProtectedRoute redirectTo={authenticationUrl} />}>
					<Route
						path={dashboardModulesUrl}
						element={
							<AnimatedWrapper>
								<DashboardPage />
							</AnimatedWrapper>
						}
					/>
				</Route>
				<Route
					path="*"
					element={
						<AnimatedWrapper>
							<NotFoundPage />
						</AnimatedWrapper>
					}
				/>
			</Routes>
		</AnimatePresence>
	);
};
