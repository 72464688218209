import { useIdentityContext } from "@/contexts/Identity";
import { AuthenticatedUser } from "@/models/AuthenticatedUser";
import { Form, notification } from "antd";
import { useCallback, useState } from "react";
import { LoginFormData, LoginFormProps } from "./types";
import { LoginFormView } from "./view";

const notifyAuthenticationSuccess = (user: AuthenticatedUser) =>
	notification.success({
		message: "Login realizado",
		description: `Bem vindo(a), ${user.firstName}!`,
	});

const notifyAuthenticationFailure = () => notification.error({ message: "Erro durante login" });

export const LoginForm = ({ onUserAuthenticated }: LoginFormProps) => {
	const [form] = Form.useForm<LoginFormData>();
	const [loading, setLoading] = useState<boolean>(false);
	const { authenticate } = useIdentityContext();

	const onSubmit = useCallback(() => {
		const { username, password } = form.getFieldsValue();
		const authenticateRequest = authenticate(username, password);

		const onSuccess = (user: AuthenticatedUser) => {
			notifyAuthenticationSuccess(user);
			onUserAuthenticated();
		};

		const onFailure = () => {
			notifyAuthenticationFailure();
		};

		setLoading(true);
		authenticateRequest
			.then(onSuccess)
			.catch(onFailure)
			.finally(() => setLoading(false));
	}, [authenticate, form, onUserAuthenticated]);

	return <LoginFormView loading={loading} form={form} onSubmit={onSubmit} />;
};
