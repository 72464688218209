import { UserForm } from "@/components/UserForm";
import { Action } from "@/models/actions/Action";
import { ActionControls } from "@/models/actions/ActionControls";
import { User } from "@/models/User";
import { notification } from "antd";

export class EditUserAction implements Action {
	constructor(
		private readonly user?: User,
		private readonly onUserEdited?: (user: User) => void
	) {}

	getOptions() {
		return {
			width: "40vw",
			title: this.user ? "Editar usuário" : "Adicionar usuário",
		};
	}

	render(controls: ActionControls) {
		return (
			<UserForm
				user={this.user}
				onSubmitting={() => controls.setCloseable(false)}
				onSubmitted={(user) => this.onSuccess(user, controls)}
				onSubmissionFailed={(error) => this.onFailure(error, controls)}
			/>
		);
	}

	private onSuccess(user: User, controls: ActionControls) {
		controls.setCloseable(true);
		controls.close();

		notification.success({ message: "Usuário salvo com sucesso!" });

		this.onUserEdited?.(user);
	}

	private onFailure(_error: Error, controls: ActionControls) {
		controls.setCloseable(true);

		notification.error({ message: "Erro ao salvar usuário" });
	}
}
