import { Module } from "@/models/modules/Module";
import { ShopOutlined } from "@ant-design/icons";
import { LaundryUnitManagementModuleContent } from "./LaundryUnitManagementModuleContent";
import { LaundryUnitManagementModuleContextProvider } from "./LaundryUnitManagementModuleContext";
import { LaundryUnitManagementModuleSubHeader } from "./LaundryUnitManagementModuleSubHeader";

export const LaundryUnitManagementModule: Module = {
	path: "/units",
	title: "Unidades",
	resource: "LaundryUnit",
	icon: <ShopOutlined />,
	content: <LaundryUnitManagementModuleContent />,
	subHeader: <LaundryUnitManagementModuleSubHeader />,
	context: LaundryUnitManagementModuleContextProvider,
};
