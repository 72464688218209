import { useHttp } from "@/application/HttpClient";
import { useCollection } from "@/utilities/collections/useCollection";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { DashboardManagementModuleContextValue } from "./types";
import { reportService } from "@/services/report";
import { ReportFilter } from "@/utilities/interface/ReportFilter";
import { Report } from "@/models/Report";

const DashboardManagementModuleContext = React.createContext<DashboardManagementModuleContextValue>(
	{} as any
);

export const DashboardManagementModuleContextProvider = (props: React.PropsWithChildren) => {
	const { request } = useHttp();

	const [filterReport, setFilterReport] = useState<ReportFilter>({
		startDate: "",
		endDate: "",
		laundryUnitId: "",
		deviceId: "",
	});

	const handleFilterChange = (
		startDate: string,
		endDate: string,
		laundryUnitId: string,
		deviceId: string
	) => {
		setFilterReport({
			startDate,
			endDate,
			laundryUnitId,
			deviceId,
		})
		setLoading(true)
	};
	const [loading, setLoading] = useState<boolean>(false);
	const [reports, setReports] = useCollection<Report>([]);

	const onUpdateList = useCallback(() => setLoading(true), []);

	useEffect(() => {
		if (
			loading &&
			filterReport.startDate &&
			filterReport.endDate &&
			filterReport.laundryUnitId &&
			filterReport.deviceId
		) {
			request(reportService.list, {
				startDate: filterReport.startDate,
				endDate: filterReport.endDate,
				laundryUnitId: filterReport.laundryUnitId,
				deviceId: filterReport.deviceId,
			})
				.then((reports) => setReports(reports))
				.catch(() => setReports([]))
				.finally(() => setLoading(false));
		}
	}, [filterReport.deviceId, filterReport.endDate, filterReport.laundryUnitId, filterReport.startDate, loading, request, setReports]);
	return (
		<DashboardManagementModuleContext.Provider
			value={{ loading, onUpdateList, reports, handleFilterChange }}
		>
			{props.children}
		</DashboardManagementModuleContext.Provider>
	);
};

export const useDashboardManagementModuleContext = () => {
	return useContext(DashboardManagementModuleContext);
};
