import { CustomerList } from "@/components/CustomerList";
import { CustomerManagementModuleContentViewProps } from "./types";

type ComponentType = React.FC<CustomerManagementModuleContentViewProps>;
export const CustomerManagementModuleContentView: ComponentType = (props) => {
	return (
		<CustomerList
			loading={props.loading}
			customers={props.customers}
			onCustomerEdited={props.onCustomerEdited}
			onCustomerDeleted={props.onCustomerDeleted}
			handleSortChange={props.handleSortChange}
			handleFilterChange={props.handleFilterChange}
		/>
	);
};
