import { ApexOptions } from "apexcharts";
import Chart from "react-apexcharts";
import { DonutChartViewProps } from "./types";

type ComponentType = React.FC<DonutChartViewProps>;
export const DonutChartView: ComponentType = (props) => {
	const options: ApexOptions = {
		chart: {
			type: "donut",
		},
		labels: props.labels,
		series: props.series,
		legend: {
			show: true,
		},
		title: {
			text: props.title,
			align: "center",
		},
	};

	return <Chart options={options} series={props.series} type="donut" />;
};
