import { useLaundryManagementModuleContext } from "../LaundryManagementModuleContext";
import { LaundryManagementModuleContentProps } from "./types";
import { LaundryManagementModuleContentView } from "./view";

type ComponentType = React.FC<LaundryManagementModuleContentProps>;
export const LaundryManagementModuleContent: ComponentType = (props) => {
	const { laundries, loading, onUpdateList, handleSortChange, handleFilterChange } = useLaundryManagementModuleContext();

	return (
		<LaundryManagementModuleContentView
			laundries={laundries}
			loading={loading}
			onLaundryEdited={onUpdateList}
			onLaundryDeleted={onUpdateList}
			handleSortChange={handleSortChange}
			handleFilterChange={handleFilterChange}
		/>
	);
};
