import { motion } from "framer-motion";
import styled from "styled-components";

export const StepWrapper = styled(motion.div).attrs({
	exit: { opacity: 0 },
	initial: { opacity: 0 },
	animate: { opacity: 1 },
})`
	padding-top: 1em;
`;
