import { UserService } from "./types";

export const userService: UserService = {
	list: ({ sortField, sortOrder, filterField, filterText }) => ({
		method: "GET",
		path: `/v1/user?sortField=${sortField}&sortOrder=${sortOrder}&filterField=${filterField}&filterText=${filterText}`,
	}),
	create: (json) => ({
		json,
		method: "POST",
		path: "/v1/user",
	}),
	edit: ({ userId, ...json }) => ({
		json,
		method: "PUT",
		path: `/v1/user/${userId}`,
	}),
	delete: ({ userId }) => ({
		method: "DELETE",
		path: `/v1/user/${userId}`,
	}),
};
