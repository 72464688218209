import { useStatusContext } from "@/contexts/Status";
import { useCallback } from "react";
import { useMachineModuleContext } from "../MachineModuleContext";
import { MachineModuleSubHeaderProps } from "./types";
import { MachineModuleSubHeaderView } from "./view";
import { EditMachineAction } from "@/actions/EditMachineAction";

type ComponentType = React.FC<MachineModuleSubHeaderProps>;
export const MachineModuleSubHeader: ComponentType = (props) => {
	const { onActionChange } = useStatusContext();
	const { onUpdateList } = useMachineModuleContext();


	const onAddMachine = useCallback(() => {
		onActionChange({
			action: new EditMachineAction(),
			onActionEnd: () => onUpdateList(),
		});
	}, [onActionChange, onUpdateList]);

	return <MachineModuleSubHeaderView addDisabled={false} onAddMachine={onAddMachine} />;
};
