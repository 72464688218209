import { concatenatePaths } from "@/utilities/text/concatenatePaths";
import { Outlet, Route } from "react-router-dom";
import { ModuleRouteProps } from "./types";

export const buildModuleRoute = (props: ModuleRouteProps) => {
	const modulePath = concatenatePaths("", props.module.path);

	const renderOutlet = () => <Outlet />;
	const renderComponent = () => props.render(props.module);
	const resourceIsSpecified = props.module.resource !== undefined;
	const canReadResource = resourceIsSpecified
		? props.authorization.canManage(props.module.resource) || props.authorization.canUpdate(props.module.resource)
		: true;

	if (!canReadResource) return undefined;

	return (
		<Route
			key={modulePath}
			path={modulePath}
			element={props.module.children ? renderOutlet() : renderComponent()}
		>
			{props.module.children?.map((child) =>
				buildModuleRoute({
					module: child,
					authorization: props.authorization,
					render: props.render,
				})
			)}
		</Route>
	);
};
