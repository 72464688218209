import { Module } from "@/models/modules/Module";
import { ClusterOutlined } from "@ant-design/icons";
import { RecipientManagementModuleContent } from "./RecipientManagementModuleContent";
import { RecipientManagementModuleContextProvider } from "./RecipientManagementModuleContext";
import { RecipientManagementModuleSubHeader } from "./RecipientManagementModuleSubHeader";

export const RecipientManagementModule: Module = {
	path: "/recipients",
	title: "Recipientes",
	resource: "ChemicalRecipient",
	icon: <ClusterOutlined />,
	content: <RecipientManagementModuleContent />,
	subHeader: <RecipientManagementModuleSubHeader />,
	context: RecipientManagementModuleContextProvider,
};
