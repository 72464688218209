import { Rule } from "antd/lib/form";
import { ValidatorRule } from "rc-field-form/lib/interface";

const minimumLength = (value: number, unit: string): Rule => ({
	min: value,
	message: `Esse campo deve ter pelo menos ${value} ${unit}`,
});

export const minimumListLength = (minimumLength: number, unit: string): ValidatorRule => ({
	validator: (_rule, value) => {
		if (Array.isArray(value) && value.length >= minimumLength) return Promise.resolve();
		return Promise.reject(`Esse campo deve ter pelo menos ${minimumLength} ${unit}`);
	},
});

export const minimumStringLength = (value: number) => minimumLength(value, "caracteres");
