import { Panel } from "./styles";
import { LaundryUnitForm } from "../LaundryUnitForm";
import { notification } from "antd";
import { useNavigate } from "react-router-dom";

export const RegisterClientFormView = () => {
	const navigate = useNavigate();

	return (
		<Panel>
			<LaundryUnitForm
				onSubmitted={() => {
					notification.success({
						message: "Cadastro efetuado com sucesso! Verifique seu email.",
					});
					navigate("/");
				}}
				onSubmissionFailed={() => {
					notification.error({
						message: "Equipamento não configurado, entre em contato com a Tron",
					});
				}}
			/>
		</Panel>
	);
};
