import { useHttp } from "@/application/HttpClient";
import { Device } from "@/models/Device";
import { deviceService } from "@/services/device";
import { Select } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { DeviceAllSelectInputProps, OptionType } from "./types";

type ComponentType = React.FC<DeviceAllSelectInputProps>;
export const DeviceAllSelectInput: ComponentType = ({ onChange, ...props }) => {
	const { request } = useHttp();

	const [loading, setLoading] = useState<boolean>(false);
	const [devices, setDevices] = useState<Device[]>([]);

	const childValue = useMemo(
		() =>
			!loading
				? typeof props.value !== "string"
					? props.value?.id
					: props.value
				: undefined,
		[loading, props.value]
	);

	const options = useMemo<OptionType[]>(
		() => devices.map((payload) => ({ value: payload.id, label: `${payload.serialCode} - ${payload.name}`, payload })),
		[devices]
	);

	const onFilter = useCallback((value: string, option?: OptionType) => {
		const reference = option?.label.toLowerCase();
		const test = value.toLowerCase();

		return reference?.includes(test) || false;
	}, []);

	const onChildChange = useCallback(
		(option?: OptionType) => onChange?.(option?.payload),
		[onChange]
	);

	useEffect(() => {
		if (props?.laundryUnitId) {
			setLoading(true);

			request(deviceService.listByUnit, {
				laundryUnitId: props?.laundryUnitId,
			})
				.then((devices) => setDevices(devices))
				.catch(() => setDevices([]))
				.finally(() => setLoading(false));
		}
	}, [request, setDevices, props?.laundryUnitId]);

	return (
		<Select
			style={props.style}
			allowClear
			showSearch
			value={childValue}
			disabled={props.disabled}
			loading={loading}
			options={options}
			filterOption={onFilter}
			onChange={(_value, option) => onChildChange(option as OptionType)}
			placeholder='EQUIPAMENTO'
		/>
	);
};
