import { concatenatePaths } from "@/utilities/text/concatenatePaths";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { DashboardPageProps } from "./types";
import { DashboardPageView } from "./view";

export const DashboardPage = (props: DashboardPageProps) => {
	const navigate = useNavigate();
	const onModuleSelected = useCallback(
		(path: string) => navigate(concatenatePaths("", path)),
		[navigate]
	);

	return <DashboardPageView onModuleSelected={onModuleSelected} />;
};
