import { NewTrialDeviceAction } from "@/actions/NewTrialDeviceAction";
import { useHttp } from "@/application/HttpClient";
import { useStatusContext } from "@/contexts/Status";
import { TrialDevice } from "@/models/Trial";
import { trialService } from "@/services/trial";
import { Modal, notification } from "antd";
import { useCallback, useState } from "react";
import { TrialDeviceListProps } from "./types";
import { TrialDeviceListView } from "./view";
import { deviceService } from "@/services/device";
import { LogDeviceAction } from "@/actions/LogDeviceAction";

type ComponentType = React.FC<TrialDeviceListProps>;
export const TrialDeviceList: ComponentType = ({
	onDeviceEdited,
	onDeviceDeleted,
	handleSortChange,
	handleFilterChange,
	...props
}) => {
	const [pagination, setPagination] = useState({
		current: 1,
		pageSize: 5,
		total: 0,
	});

	const handleTableChange = (pagination: any, device: any) => {
		setPagination(pagination);
		setSubmitting(true);
	};

	const { request } = useHttp();
	const { onActionChange } = useStatusContext();

	const [submitting, setSubmitting] = useState<boolean>(false);
	const [open, setOpen] = useState(false);
	const [log, setLog] = useState(null);

	const isDeviceDeletable = useCallback((_device: TrialDevice) => true, []);

	const onLogDevice = useCallback(
		(deviceId: string, serialCode: string) => {
			setSubmitting(true);
			const resumeRequest = request(deviceService.get, { deviceId });
			const logsRequest = request(deviceService.getLogs, {
				deviceId,
				page: 1,
				limit: 10,
			});

			Promise.all([resumeRequest, logsRequest])
				.then(([resume, logs]: any) => {
					setPagination({
						current: 1,
						pageSize: 5,
						total: logs.totalItems,
					});

					onActionChange({
						action: new LogDeviceAction(
							deviceId,
							serialCode,
							onOpen,
							resume,
							logs,
							{
								current: 1,
								pageSize: 5,
								total: logs.totalItems,
							},
							handleTableChange,
							true,
							async () => {
								await request(trialService.deleteLogs, { deviceId });
							}
						),
					});
				})
				.catch((error) => {
					console.log(error);
					notification.error({ message: "Pelo menos uma solicitação falhou" });
				})
				.finally(() => setSubmitting(false));
		},
		[onActionChange, pagination]
	);

	const onEditDevice = useCallback(
		(device: TrialDevice) => {
			onActionChange({
				action: new NewTrialDeviceAction(device),
				onActionEnd: () => onDeviceEdited?.(),
			});
		},
		[onActionChange, onDeviceEdited]
	);

	const onDeleteDevice = useCallback(
		(device: TrialDevice) => {
			setSubmitting(true);
			request(trialService.delete, { deviceId: device?.deviceTrialInfoDeviceId })
				.then(() => onDeviceDeleted?.())
				.catch(() => notification.error({ message: "Erro ao deletar equipamento" }))
				.finally(() => setSubmitting(false));
		},
		[onDeviceDeleted, request]
	);

	const onMoveToPayment = useCallback(
		(device: TrialDevice) => {
			setSubmitting(true);
			request(trialService.moveToPayment, { deviceId: device?.deviceTrialInfoDeviceId })
				.then(() => onDeviceDeleted?.())
				.catch(() => notification.error({ message: "Erro ao mover equipamento" }))
				.finally(() => setSubmitting(false));
		},
		[onDeviceDeleted, request]
	);

	const onSort = useCallback((field: string, sortOrder: string) => {
		handleSortChange(field, sortOrder);
		onDeviceDeleted?.();
	}, []);

	const onFilter = useCallback((field: string, text: string) => {
		handleFilterChange(field, text);
		onDeviceDeleted?.();
	}, []);

	const onOpen = (record: any) => {
		setOpen(!open);
		setLog(record?.raw);
	};

	return (
		<>
			<Modal
				style={{
					maxHeight: "80vh",
					overflowY: "hidden",
				}}
				bodyStyle={{
					maxHeight: "80vh",
					overflowY: "auto",
				}}
				title="Detalhe do log"
				centered
				visible={open}
				onOk={() => setOpen(false)}
				onCancel={() => setOpen(false)}
				width={1000}
			>
				<pre>{JSON.stringify(log, null, 2)}</pre>
			</Modal>

			<TrialDeviceListView
				onMoveToPayment={onMoveToPayment}
				loading={props.loading || submitting}
				devices={props.devices}
				onEditDevice={onEditDevice}
				onLogDevice={onLogDevice}
				onSort={onSort}
				onDeleteDevice={onDeleteDevice}
				isDeviceDeletable={isDeviceDeletable}
				onOpen={onOpen}
				onFilter={onFilter}
			/>
		</>
	);
};
