import { Address } from "@/models/location/Address";
import { FormInstance } from "antd";
import { useCallback } from "react";
import { AddressFormData } from "../types";

export const useSubmit = (form: FormInstance<AddressFormData>) => {
	const submit = useCallback(() => {
		const formData = form.getFieldsValue();
		return Promise.resolve<Address>(formData);
	}, [form]);

	return submit;
};
