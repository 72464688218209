import { useUserManagementModuleContext } from "../UserManagementModuleContext";
import { UserManagementModuleContentProps } from "./types";
import { UserManagementModuleContentView } from "./view";

export const UserManagementModuleContent = (props: UserManagementModuleContentProps) => {
	const { users, loading, onUpdateList, handleSortChange, handleFilterChange } = useUserManagementModuleContext();

	return (
		<UserManagementModuleContentView
			users={users}
			loading={loading}
			onUserEdited={onUpdateList}
			onUserDeleted={onUpdateList}
			handleSortChange={handleSortChange}
			handleFilterChange={handleFilterChange}
		/>
	);
};
