import { Button } from "antd";
import { Wrapper } from "./style";
import { DeviceManagementModuleSubHeaderViewProps } from "./types";

type ComponentType = React.FC<DeviceManagementModuleSubHeaderViewProps>;
export const DeviceManagementModuleSubHeaderView: ComponentType = (props) => {
	return (
		<Wrapper>
			{!props.addDisabled && (
				<Button disabled={props.addDisabled} onClick={() => props.onAddDevice()}>
					Novo equipamento
				</Button>
			)}
			{props.addDisabled && (
				<Button onClick={() => props.onRegisterDevice()}>Novo equipamento</Button>
			)}
		</Wrapper>
	);
};
