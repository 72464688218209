import { formatMilliliters } from "@/utilities/format/milliliters";
import { formatOptional } from "@/utilities/format/optional";
import { DeleteOutlined, EditOutlined, PlusOutlined, ClusterOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Input, Popconfirm, Row, Space, Table } from "antd";
import { RecipientListViewProps } from "./types";
import { ChemicalRecipientResponse } from "@/models/ChemicalRecipientResponse";
import { useState } from "react";

type ComponentType = React.FC<RecipientListViewProps>;
export const RecipientListView: ComponentType = (props) => {
	const [sortOrder, setSortOrder] = useState<any | null>(null);
	const [searchText, setSearchText] = useState("");

	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		props.onFilter(dataIndex, selectedKeys[0]);
	};

	const handleSearchEnum = (selectedKeys, confirm, dataIndex) => {
		confirm();
		props.onFilter(dataIndex, selectedKeys);
	};

	const handleReset = (clearFilters) => {
		clearFilters();
		setSearchText("");
		props.onFilter("", "");
	};
	const handleSort = (field: string, order: "ascend" | "descend") => {
		if (sortOrder?.field === field && sortOrder?.order === order) {
			setSortOrder({ field, order: order === "ascend" ? "descend" : "ascend" });
		} else {
			setSortOrder({ field, order });
		}

		props.onSort(field, order === "descend" ? "desc" : "asc");
	};

	const renderLaundry = (recipient: any) => {
		return `${recipient?.laundry?.name}`;
	};

	const renderLaundryUnit = (recipient: any) => {
		return `${recipient?.laundryUnit?.name}`;
	};

	return (
		<Table
			rowKey="id"
			title={() => "Recipiente"}
			dataSource={props.recipients}
			loading={props.loading}
			style={{ width: "100%" }}
		>
			<Table.Column
				showSorterTooltip={false}
				sorter={true}
				onHeaderCell={() => ({
					onClick: () => handleSort("produto", sortOrder?.order || "ascend"),
				})}
				dataIndex="produto"
				title="Produto"
				filterDropdown={({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
					<div style={{ padding: 8 }}>
						<Input
							placeholder={`Buscar nome`}
							value={selectedKeys[0]?.toString()}
							onChange={(e) =>
								setSelectedKeys(e.target.value ? [e.target.value] : [])
							}
							onPressEnter={() => handleSearch(selectedKeys, confirm, "produto")}
							style={{ width: 188, marginBottom: 8, display: "block" }}
						/>
						<Space>
							<Button
								type="primary"
								onClick={() => handleSearch(selectedKeys, confirm, "produto")}
								icon={<SearchOutlined />}
								size="small"
								style={{ width: 90 }}
							>
								Buscar
							</Button>
							<Button
								onClick={() => handleReset(clearFilters)}
								size="small"
								style={{ width: 90 }}
							>
								Limpar
							</Button>
						</Space>
					</div>
				)}
				filterIcon={() => {
					return (
						<SearchOutlined style={{ color: searchText ? "#1890ff" : "#00000073" }} />
					);
				}}
				render={formatOptional}
			/>
			<Table.Column
				showSorterTooltip={false}
				sorter={true}
				onHeaderCell={() => ({
					onClick: () => handleSort("volume", sortOrder?.order || "ascend"),
				})}
				filterDropdown={({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
					<div style={{ padding: 8 }}>
						<Input
							placeholder={`Buscar volume`}
							value={selectedKeys[0]?.toString()}
							onChange={(e) =>
								setSelectedKeys(e.target.value ? [e.target.value] : [])
							}
							onPressEnter={() => handleSearch(selectedKeys, confirm, "volume")}
							style={{ width: 188, marginBottom: 8, display: "block" }}
						/>
						<Space>
							<Button
								type="primary"
								onClick={() => handleSearch(selectedKeys, confirm, "volume")}
								icon={<SearchOutlined />}
								size="small"
								style={{ width: 90 }}
							>
								Buscar
							</Button>
							<Button
								onClick={() => handleReset(clearFilters)}
								size="small"
								style={{ width: 90 }}
							>
								Limpar
							</Button>
						</Space>
					</div>
				)}
				filterIcon={() => {
					return (
						<SearchOutlined style={{ color: searchText ? "#1890ff" : "#00000073" }} />
					);
				}}
				dataIndex="volume"
				title="Volume ml/s"
				render={formatMilliliters}
			/>

			<Table.Column
				showSorterTooltip={false}
				sorter={true}
				onHeaderCell={() => ({
					onClick: () => handleSort("laundry", sortOrder?.order || "ascend"),
				})}
				filterDropdown={({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
					<div style={{ padding: 8 }}>
						<Input
							placeholder={`Buscar Franquia`}
							value={selectedKeys[0]?.toString()}
							onChange={(e) =>
								setSelectedKeys(e.target.value ? [e.target.value] : [])
							}
							onPressEnter={() => handleSearch(selectedKeys, confirm, "laundry")}
							style={{ width: 188, marginBottom: 8, display: "block" }}
						/>
						<Space>
							<Button
								type="primary"
								onClick={() => handleSearch(selectedKeys, confirm, "laundry")}
								icon={<SearchOutlined />}
								size="small"
								style={{ width: 90 }}
							>
								Buscar
							</Button>
							<Button
								onClick={() => handleReset(clearFilters)}
								size="small"
								style={{ width: 90 }}
							>
								Limpar
							</Button>
						</Space>
					</div>
				)}
				filterIcon={() => {
					return (
						<SearchOutlined style={{ color: searchText ? "#1890ff" : "#00000073" }} />
					);
				}}
				title="Franquia"
				render={renderLaundry}
			/>

			<Table.Column
				showSorterTooltip={false}
				sorter={true}
				onHeaderCell={() => ({
					onClick: () => handleSort("LaundryUnit", sortOrder?.order || "ascend"),
				})}
				filterDropdown={({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
					<div style={{ padding: 8 }}>
						<Input
							placeholder={`Buscar unidade`}
							value={selectedKeys[0]?.toString()}
							onChange={(e) =>
								setSelectedKeys(e.target.value ? [e.target.value] : [])
							}
							onPressEnter={() => handleSearch(selectedKeys, confirm, "LaundryUnit")}
							style={{ width: 188, marginBottom: 8, display: "block" }}
						/>
						<Space>
							<Button
								type="primary"
								onClick={() => handleSearch(selectedKeys, confirm, "LaundryUnit")}
								icon={<SearchOutlined />}
								size="small"
								style={{ width: 90 }}
							>
								Buscar
							</Button>
							<Button
								onClick={() => handleReset(clearFilters)}
								size="small"
								style={{ width: 90 }}
							>
								Limpar
							</Button>
						</Space>
					</div>
				)}
				filterIcon={() => {
					return (
						<SearchOutlined style={{ color: searchText ? "#1890ff" : "#00000073" }} />
					);
				}}
				title="Unidade"
				render={renderLaundryUnit}
			/>

			<Table.Column<ChemicalRecipientResponse>
				key="actions"
				title="Ações"
				render={(_, recipient) => {
					const deletable = props.isRecipientDeletable(recipient);

					return (
						<Row justify="center">
							<Space>
								<Button
									ghost
									icon={<PlusOutlined />}
									onClick={() => props.onNewRefill(recipient)}
								/>

								<Button
									ghost
									icon={<EditOutlined />}
									onClick={() => props.onEditRecipient(recipient)}
								/>

								<Button
									ghost
									icon={<ClusterOutlined />}
									onClick={() => props.onShowDevices(recipient)}
								/>

								<Popconfirm
									placement="topRight"
									disabled={!deletable}
									title="Tem certeza que deseja remover esse recipiente? Essa ação é irreversível."
									onConfirm={() => props.onDeleteRecipient(recipient)}
								>
									<Button
										ghost
										danger
										disabled={!deletable}
										icon={<DeleteOutlined />}
									/>
								</Popconfirm>
							</Space>
						</Row>
					);
				}}
			/>
		</Table>
	);
};
