import { Layout } from "antd";
import styled from "styled-components";

export const LayoutWrapper = styled(Layout)`
	display: flex;
	min-height: 100vh;
`;

export const SidebarWrapper = styled(Layout.Sider)``;

export const HeaderWrapper = styled(Layout.Header)`
	padding: 0;
`;

export const ContentWrapper = styled(Layout.Content)`
	width: 100%;
	padding: 2em;
	.ant-table-content {
		overflow-x: auto;
	}
`;

export const ContentLayoutWrapper = styled(Layout)`
	flex-grow: 100;
`;
