import { useHttp } from "@/application/HttpClient";
import { Customer } from "@/models/Customer";
import { customerService } from "@/services/customer";
import { compareByName } from "@/utilities/collections/compare";
import { useCollection } from "@/utilities/collections/useCollection";
import { Select } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { CustomerSelectInputProps, OptionType } from "./types";

type ComponentType = React.FC<CustomerSelectInputProps>;
export const CustomerSelectInput: ComponentType = ({ onChange, ...props }) => {
	const { request } = useHttp();

	const [loading, setLoading] = useState<boolean>(true);
	const [customers, setCustomers] = useCollection<Customer>([], compareByName);

	const childValue = useMemo(
		() =>
			!loading
				? typeof props.value !== "string"
					? props.value?.id
					: props.value
				: undefined,
		[loading, props.value]
	);

	const options = useMemo<OptionType[]>(
		() => customers.map((payload) => ({ value: payload.id, label: payload.name, payload })),
		[customers]
	);

	const onFilter = useCallback((value: string, option?: OptionType) => {
		const reference = option?.label.toLowerCase();
		const test = value.toLowerCase();

		return reference?.includes(test) || false;
	}, []);

	const onChildChange = useCallback(
		(option?: OptionType) => onChange?.(option?.payload),
		[onChange]
	);

	useEffect(() => {
		setLoading(true);
		request(customerService.list, { sortField: "createdAt", sortOrder: "desc", filterField: "", filterText: ""})
			.then((customers) => setCustomers(customers))
			.catch(() => setCustomers([]))
			.finally(() => setLoading(false));
	}, [request, setCustomers]);

	return (
		<Select
			allowClear
			showSearch
			value={childValue}
			disabled={props.disabled}
			loading={loading}
			options={options}
			filterOption={onFilter}
			onChange={(_value, option) => onChildChange(option as OptionType)}
		/>
	);
};
