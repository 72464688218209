import { ChemicalRecipientRefillService } from "./types";

export const chemicalRecipientRefillService: ChemicalRecipientRefillService = {
	list: () => ({
		method: "GET",
		path: "/v1/chemical-recipient-refill",
	}),
	create: (json) => ({
		json,
		method: "POST",
		path: `/v1/chemical-recipient-refill`,
	}),
	edit: ({ chemicalRecipientRefillId, ...json }) => ({
		json,
		method: "PUT",
		path: `/v1/chemical-recipient-refill/${chemicalRecipientRefillId}`,
	}),
	delete: ({ chemicalRecipientRefillId }) => ({
		method: "DELETE",
		path: `/v1/chemical-recipient-refill/${chemicalRecipientRefillId}`,
	}),
};
