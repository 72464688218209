import { Button } from "antd";
import { Wrapper } from "./style";
import { LaundryUnitManagementModuleSubHeaderViewProps } from "./types";

type ComponentType = React.FC<LaundryUnitManagementModuleSubHeaderViewProps>;
export const LaundryUnitManagementModuleSubHeaderView: ComponentType = (props) => {
	return (
		<Wrapper>
			<Button disabled={props.addDisabled} onClick={() => props.onAddLaundryUnit()}>
				Nova unidade
			</Button>
		</Wrapper>
	);
};
