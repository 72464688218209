import { useRecipientManagementModuleContext } from "../RecipientManagementModuleContext";
import { RecipientManagementModuleContentProps } from "./types";
import { RecipientManagementModuleContentView } from "./view";

type ComponentType = React.FC<RecipientManagementModuleContentProps>;
export const RecipientManagementModuleContent: ComponentType = (props) => {
	const { recipients, loading, onUpdateList, handleSortChange, handleFilterChange } =
		useRecipientManagementModuleContext();
	return (
		<RecipientManagementModuleContentView
			recipients={recipients}
			loading={loading}
			onRecipientEdited={onUpdateList}
			onRecipientDeleted={onUpdateList}
			handleSortChange={handleSortChange}
			handleFilterChange={handleFilterChange}
		/>
	);
};
