import { LaundryService } from "./types";

export const laundryService: LaundryService = {
	list: ({ sortField, sortOrder, filterField, filterText }) => ({
		method: "GET",
		path: `/v1/laundry?sortField=${sortField}&sortOrder=${sortOrder}&filterField=${filterField}&filterText=${filterText}`,
	}),
	create: (json) => ({
		json,
		method: "POST",
		path: `/v1/laundry`,
	}),
	edit: ({ laundryId, ...json }) => ({
		json,
		method: "PUT",
		path: `/v1/laundry/${laundryId}`,
	}),
	delete: ({ laundryId }) => ({
		method: "DELETE",
		path: `/v1/laundry/${laundryId}`,
	}),
};
