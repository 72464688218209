import { Form } from "antd";
import { useCallback } from "react";
import { LaundryUnitIdentificationFormData, LaundryUnitIdentificationFormProps } from "./types";
import { LaundryUnitIdentificationFormView } from "./view";

type ComponentType = React.FC<LaundryUnitIdentificationFormProps>;
export const LaundryUnitIdentificationForm: ComponentType = ({ onSubmit, ...props }) => {
	const [form] = Form.useForm<LaundryUnitIdentificationFormData>();

	const onFormSubmit = useCallback(() => {
		const value = form.getFieldsValue();
		onSubmit(value);
	}, [form, onSubmit]);

	return (
		<LaundryUnitIdentificationFormView
			form={form}
			initialValue={props.identification}
			onSubmit={onFormSubmit}
			onCancel={props.onCancel}
		/>
	);
};
