import { required } from "@/models/rules/required";
import { Button, Form, Input, Row } from "antd";
import { TrialDeviceFormViewProps } from "./types";

type ComponentType = React.FC<TrialDeviceFormViewProps>;
export const TrialDeviceFormView: ComponentType = (props) => {

	return (
		<Form
			layout="vertical"
			form={props.form}
			onFinish={props.onSubmit}
			initialValues={props.initialValue}
		>
			<Form.Item name="serialCode" label="Código" rules={[required]}>
			<Input disabled={props.submitting || !!props.initialValue?.serialCode} />
			</Form.Item>

            <Form.Item name="email" label="Email">
				<Input disabled={props.submitting} />
			</Form.Item>

			{props?.isEditMode &&
				<Form.Item name="tempoTrial" label="Tempo de trial" >
					<Input disabled={props.submitting} type="number" suffix="dias" min={0} />
				</Form.Item>
			}

			<Row justify="end">
				<Button
					type="primary"
					htmlType="submit"
					disabled={props.submitting}
					loading={props.submitting}
				>
					Salvar
				</Button>
			</Row>
		</Form>
	);
};
