import { useHttp } from "@/application/HttpClient";
import { useStatusContext } from "@/contexts/Status";
import { notification } from "antd";
import { useCallback, useState } from "react";
import { machineService } from "@/services/machine";
import { Machine } from "@/models/Machine";
import { MachineListProps } from "./types";
import { MachineListView } from "./view";
import { EditMachineAction } from "@/actions/EditMachineAction";

type ComponentType = React.FC<MachineListProps>;
export const MachineList: ComponentType = ({
	onMachineEdited,
	onMachineDeleted,
	handleSortChange,
	handleFilterChange,
	...props
}) => {
	const { request } = useHttp();
	const { onActionChange } = useStatusContext();

	const [submitting, setSubmitting] = useState<boolean>(false);

	const onEditMachine = useCallback(
		(machine: Machine) => {
			onActionChange({
				action: new EditMachineAction(machine),
				onActionEnd: () => onMachineEdited?.(),
			});
		},
		[onActionChange, onMachineEdited]
	);

	const onSort = useCallback((field: string, sortOrder: string) => {
		handleSortChange(field, sortOrder);
		onMachineDeleted?.();
	}, []);

	const onFilter = useCallback((field: string, text: string) => {
		handleFilterChange(field, text);
		onMachineDeleted?.();
	}, []);

	const onDeleteMachine = useCallback(
		(machine: Machine) => {
			setSubmitting(true);
			request(machineService.delete, { machineId: machine.id })
				.then(() => onMachineDeleted?.())
				.catch(() => notification.error({ message: "Erro ao remover a máquina" }))
				.finally(() => setSubmitting(false));
		},
		[onMachineDeleted, request]
	);

	return (
		<>
			<MachineListView
				loading={props.loading || submitting}
				machines={props.machines}
				onEditMachine={onEditMachine}
				onDeleteMachine={onDeleteMachine}
				onSort={onSort}
				onFilter={onFilter}
			/>
		</>
	);
};
