import { EditUserAction } from "@/actions/EditUserAction";
import { useAuthorizationContext } from "@/contexts/Authorization";
import { useStatusContext } from "@/contexts/Status";
import { useCallback, useMemo } from "react";
import { useUserManagementModuleContext } from "../UserManagementModuleContext";
import { UserManagementModuleSubHeaderProps } from "./types";
import { UserManagementModuleSubHeaderView } from "./view";

export const UserManagementModuleSubHeader = (props: UserManagementModuleSubHeaderProps) => {
	const { onActionChange } = useStatusContext();
	const { onUpdateList } = useUserManagementModuleContext();
	const authorization = useAuthorizationContext();

	const addDisabled = useMemo(() => !authorization.canCreate("User"), [authorization]);

	const onAddUser = useCallback(() => {
		onActionChange({
			action: new EditUserAction(),
			onActionEnd: () => onUpdateList(),
		});
	}, [onActionChange, onUpdateList]);

	return <UserManagementModuleSubHeaderView addDisabled={addDisabled} onAddUser={onAddUser} />;
};
