import { EditDeviceAction } from "@/actions/EditDeviceAction";
import { useAuthorizationContext } from "@/contexts/Authorization";
import { useStatusContext } from "@/contexts/Status";
import { useCallback, useMemo } from "react";
import { useDeviceManagementModuleContext } from "../DeviceManagementModuleContext";
import { DeviceManagementModuleSubHeaderProps } from "./types";
import { DeviceManagementModuleSubHeaderView } from "./view";
import { NewDeviceRegisterAction } from "@/actions/NewDeviceRegisterAction";

type ComponentType = React.FC<DeviceManagementModuleSubHeaderProps>;
export const DeviceManagementModuleSubHeader: ComponentType = (props) => {
	const { onActionChange } = useStatusContext();
	const { onUpdateList } = useDeviceManagementModuleContext();
	const authorization = useAuthorizationContext();

	const addDisabled = useMemo(() => !authorization.canCreate("Device"), [authorization]);

	const onAddDevice = useCallback(() => {
		onActionChange({
			action: new EditDeviceAction(),
			onActionEnd: () => onUpdateList(),
		});
	}, [onActionChange, onUpdateList]);

	const onRegisterDevice = useCallback(() => {
		onActionChange({
			action: new NewDeviceRegisterAction(),
			onActionEnd: () => onUpdateList(),
		});
	}, [onActionChange, onUpdateList]);

	return (
		<DeviceManagementModuleSubHeaderView
			addDisabled={addDisabled}
			onRegisterDevice={onRegisterDevice}
			onAddDevice={onAddDevice}
		/>
	);
};
