import { Button } from "antd";
import { Wrapper } from "./style";
import { LaundryManagementModuleSubHeaderViewProps } from "./types";

type ComponentType = React.FC<LaundryManagementModuleSubHeaderViewProps>;
export const LaundryManagementModuleSubHeaderView: ComponentType = (props) => {
	return (
		<Wrapper>
			<Button disabled={props.addDisabled} onClick={() => props.onAddLaundry()}>
				Nova Franquia
			</Button>
		</Wrapper>
	);
};
