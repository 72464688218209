import { useHttp } from "@/application/HttpClient";
import { chemicalRecipientRefillService } from "@/services/chemicalRecipientRefill";
import { Form } from "antd";
import { useCallback, useState } from "react";
import { NewChemicalRecipientRefillFormData, ChemicalRecipientRefillFormProps } from "./types";
import { ChemicalRecipientRefillFormView } from "./view";

type ComponentType = React.FC<ChemicalRecipientRefillFormProps>;
export const NewChemicalRecipientRefillForm: ComponentType = (props) => {
	const { request } = useHttp();
	const { onSubmitting, onSubmitted, onSubmissionFailed, recipient } = props;

	const [form] = Form.useForm<NewChemicalRecipientRefillFormData>();
	const [submitting, setSubmitting] = useState<boolean>(false);

	const onSubmit = useCallback(() => {
		if (submitting) return;
		const formData = form.getFieldsValue();
		if (recipient) {
			formData.volume = formData.volume ? formData.volume : recipient.volume;
			formData.produto = recipient.produto;
		}

		const doRequest = () =>
			props.refill
				? request(chemicalRecipientRefillService.edit, {
						chemicalRecipientRefillId: props.refill.id,
						volume: formData.volume,
						produto: formData.produto
				  })
				: request(chemicalRecipientRefillService.create, {
						volume: formData.volume,
						produto: formData.produto,
						recipientId: props.recipientId,
				  });

		onSubmitting?.();
		setSubmitting(true);

		doRequest()
			.then((product) => onSubmitted?.(product))
			.catch((error) => onSubmissionFailed?.(error))
			.finally(() => setSubmitting(false));
	}, [
		form,
		onSubmissionFailed,
		onSubmitted,
		onSubmitting,
		props.refill,
		props.recipientId,
		request,
		submitting,
	]);

	return (
		<ChemicalRecipientRefillFormView
			form={form}
			submitting={submitting}
			isEditMode={!!props.refill}
			initialValue={props.refill}
			onSubmit={onSubmit}
		/>
	);
};
