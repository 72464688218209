import { useHttp } from "@/application/HttpClient";
import { ChemicalRecipient } from "@/models/ChemicalRecipient";
import { chemicalRecipientService } from "@/services/chemicalRecipient";
import { useCollection } from "@/utilities/collections/useCollection";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { RecipientManagementModuleContextValue } from "./types";
import { Filter } from "@/utilities/interface/Filter";

const RecipientManagementModuleContext = React.createContext<RecipientManagementModuleContextValue>(
	{} as any
);

export const RecipientManagementModuleContextProvider = (props: React.PropsWithChildren) => {
	const { request } = useHttp();

	const [order, setOrder] = useState<Filter>({
		sortField: "createdAt",
		sortOrder: "desc",
	});

	const [filter, setFilter] = useState<any>({
		filterField: "",
		filterText: "",
	});

	const handleSortChange = (field: string, sortOrder: string) =>
	setOrder({
			sortField: field,
			sortOrder: sortOrder,
		});

		const handleFilterChange = (field: string, text: string) =>
		setFilter({
			filterField: field,
			filterText: text,
		});

	const [loading, setLoading] = useState<boolean>(true);
	const [recipients, setRecipients] = useCollection<ChemicalRecipient>([]);

	const onUpdateList = useCallback(() => setLoading(true), []);

	useEffect(() => {
		if (loading) {
			request(chemicalRecipientService.list, { ...order, ...filter})
				.then((recipients) => setRecipients(recipients))
				.catch(() => setRecipients([]))
				.finally(() => setLoading(false));
		}
	}, [loading, request, setRecipients]);
	return (
		<RecipientManagementModuleContext.Provider value={{ loading, onUpdateList, recipients, handleSortChange, handleFilterChange }}>
			{props.children}
		</RecipientManagementModuleContext.Provider>
	);
};

export const useRecipientManagementModuleContext = () => {
	return useContext(RecipientManagementModuleContext);
};
