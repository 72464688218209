import { Divider } from "antd";
import styled from "styled-components";

export const ThinDivider = styled(Divider)`
	margin: 0.2em 0;
`;

export const ButtonGroupWrapper = styled.div`
	padding: 0.4em;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	& > * {
		width: 100%;
	}
`;
