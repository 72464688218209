import { TrialService } from "./types";

export const trialService: TrialService = {
	list: ({ sortField, sortOrder, filterField, filterText }) => ({
		method: "GET",
		path: `/v1/device-trial?sortField=${sortField}&sortOrder=${sortOrder}&filterField=${filterField}&filterText=${filterText}`,
	}),
	create: ({ ...json }) => ({
		json,
		method: "POST",
		path: `/v1/device-trial`,
	}),
	edit: ({ deviceId, ...json }) => ({
		json,
		method: "PUT",
		path: `/v1/device-trial/${deviceId}`,
	}),
	moveToPayment: ({ deviceId }) => ({
		method: "POST",
		path: `/v1/device-trial/update-trial-to-payment/${deviceId}`,
	}),
	delete: ({ deviceId }) => ({
		method: "DELETE",
		path: `/v1/device-trial/${deviceId}`,
	}),
	deleteLogs: ({ deviceId }) => ({
		method: "DELETE",
		path: `/v1/device-trial/${deviceId}/logs`,
	}),
};
