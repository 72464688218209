import { ApexOptions } from "apexcharts";
import Chart from "react-apexcharts";
import { BarChartViewProps } from "./types";

type ComponentType = React.FC<BarChartViewProps>;
export const BarChartView: ComponentType = (props) => {
	const options: ApexOptions = {
		series: [
			{
				data: props.series,
			},
		],
		chart: {
			type: "bar",
			height: 380,
		},
		plotOptions: {
			bar: {
				barHeight: "100%",
				distributed: true,
				horizontal: true,
				dataLabels: {
					position: "bottom",
				},
			},
		},
		colors: ["#33b2df", "#33b2df", "#33b2df", "#33b2df", "#33b2df", "#33b2df", "#33b2df"],
		dataLabels: {
			enabled: true,
			textAnchor: "start",
			style: {
				colors: ["#fff"],
			},
			formatter: function (val, opt) {
				return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
			},
			offsetX: 0,
			dropShadow: {
				enabled: true,
			},
		},
		stroke: {
			width: 1,
			colors: ["#fff"],
		},
		xaxis: {
			categories: props.labels,
		},
		yaxis: {
			labels: {
				show: false,
			},
		},
		title: {
			text: props.title,
			align: "center",
			floating: true,
		},
		tooltip: {
			theme: "dark",
			x: {
				show: false,
			},
			y: {
				title: {
					formatter: function () {
						return "";
					},
				},
			},
		},
	};

	return <Chart options={options} series={options.series} type="bar" height="400" />;
};
