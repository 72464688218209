import { Module } from "@/models/modules/Module";
import { ClusterOutlined } from "@ant-design/icons";
import { DeviceManagementModuleContent } from "./DeviceManagementModuleContent";
import { DeviceManagementModuleContextProvider } from "./DeviceManagementModuleContext";
import { DeviceManagementModuleSubHeader } from "./DeviceManagementModuleSubHeader";

export const DeviceManagementModule: Module = {
	path: "/devices",
	title: "Equipamentos",
	resource: "Device",
	icon: <ClusterOutlined />,
	content: <DeviceManagementModuleContent />,
	subHeader: <DeviceManagementModuleSubHeader />,
	context: DeviceManagementModuleContextProvider,
};
