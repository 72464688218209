import { ActionStatus } from "@/models/actions/ActionStatus";
import { createContext, useCallback, useContext, useMemo, useState } from "react";
import { ActionChangeSettings, StatusContextValue, StatusProviderProps } from "./types";

const StatusContext = createContext<StatusContextValue>({} as any);

export const StatusProvider = (props: StatusProviderProps) => {
	const [actionStatus, setActionStatus] = useState<ActionStatus>();
	const [identityContextReady, setIdentityContextReady] = useState<boolean>(false);

	const applicationReady = useMemo(() => identityContextReady, [identityContextReady]);

	const onIdentityContextReady = useCallback(() => {
		setIdentityContextReady(true);
	}, []);

	const onActionChange = useCallback((changeSettings?: ActionChangeSettings) => {
		setActionStatus(() => {
			if (changeSettings === undefined) return undefined;

			const { action, ...settings } = changeSettings;
			const actionStatus = Object.assign(changeSettings.action, settings);

			return actionStatus;
		});
	}, []);

	return (
		<StatusContext.Provider
			value={{
				action: actionStatus,
				applicationReady,
				onIdentityContextReady,
				onActionChange,
			}}
		>
			{props.children}
		</StatusContext.Provider>
	);
};

export const useStatusContext = () => {
	return useContext(StatusContext);
};
