import { RegisterClientForm } from "./components/RegisterClientForm";
import { Content, PageWrapper } from "./styles";

export const RegisterClientPageView = () => {
	return (
		<PageWrapper>
			<Content>
				<RegisterClientForm />
			</Content>
		</PageWrapper>
	);
};
