import { DonutChartProps } from "./types";
import { DonutChartView } from "./view";
import { v4 as uuidv4 } from "uuid";

type ComponentType = React.FC<DonutChartProps>;
export const DonutChart: ComponentType = (props) => {
	const { data } = props;

	return (
		<DonutChartView
			key={uuidv4()}
			labels={data.labels}
			series={data.series}
			title={data.title}
		/>
	);
};
