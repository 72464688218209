import { useOptionalState } from "@/utilities/hooks/useOptionalState";
import { useCallback } from "react";
import { LaundryUnitFormProps } from "./types";
import { useSubmit } from "./utilities/useSubmit";
import { LaundryUnitFormView } from "./view";
import { DeviceIdentification } from "@/models/DeviceIdentification";
import { LaundryUnitIdentification } from "../../types";

export const LaundryUnitForm = ({ ...props }: LaundryUnitFormProps) => {

	const [businessTimes, setBusinessTimes] =
		useOptionalState<DeviceIdentification>();
	const [identification, setIdentification] =
		useOptionalState<LaundryUnitIdentification>();

	const { submit, submitting } = useSubmit({
		businessTimes,
		identification,
		...props,
	});

	const onBusinessTimesSubmitted = useCallback(
		(deviceIdentification: DeviceIdentification) =>
			new Promise<void>((resolve) => {
				setBusinessTimes(deviceIdentification);
				resolve();
			}),
		[setBusinessTimes]
	);

	const onIdentificationSubmitted = useCallback(
		(identification: LaundryUnitIdentification) =>
			new Promise<void>((resolve) => {
				setIdentification(identification);
				resolve();
			}),
		[setIdentification]
	);

	return (
		<LaundryUnitFormView
			loading={submitting}
			businessTimes={businessTimes}
			identification={identification}
			onSubmit={submit}
			onBusinessTimesSubmitted={onBusinessTimesSubmitted}
			onIdentificationSubmitted={onIdentificationSubmitted}
		/>
	);
};
