import { Form, FormInstance } from "antd";
import { useEffect } from "react";
import { AddressFormData } from "../types";

const useOptionalControlledEffect = (
	form: FormInstance<AddressFormData>,
	controlledField: keyof AddressFormData,
	controlField: keyof AddressFormData
) => {
	const controlValue = Form.useWatch(controlField, form);

	useEffect(() => {
		if (controlValue) {
			form.resetFields([controlledField]);
			form.setFieldValue(controlledField, "");
		}
	}, [controlValue, controlledField, form]);
};

export const useFormEffects = (form: FormInstance<AddressFormData>) => {
	useOptionalControlledEffect(form, "complement", "noComplement");
	useOptionalControlledEffect(form, "streetNumber", "noStreetNumber");
};
