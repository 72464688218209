import { Module } from "@/models/modules/Module";
import { SolutionOutlined } from "@ant-design/icons";
import { LaundryManagementModuleContent } from "./LaundryManagementModuleContent";
import { LaundryManagementModuleContextProvider } from "./LaundryManagementModuleContext";
import { LaundryManagementModuleSubHeader } from "./LaundryManagementModuleSubHeader";

export const LaundryManagementModule: Module = {
	path: "/franchises",
	title: "Franquias",
	resource: "Laundry",
	icon: <SolutionOutlined />,
	content: <LaundryManagementModuleContent />,
	subHeader: <LaundryManagementModuleSubHeader />,
	context: LaundryManagementModuleContextProvider,
};
