import { Address } from "@/models/location/Address";
import { BusinessTime } from "@/models/time/BusinessTime";
import { useOptionalState } from "@/utilities/hooks/useOptionalState";
import { useCallback } from "react";
import { LaundryUnitFormProps, LaundryUnitIdentification } from "./types";
import { useSubmit } from "./utilities/useSubmit";
import { LaundryUnitFormView } from "./view";

export const LaundryUnitForm = ({ laundryUnit, ...props }: LaundryUnitFormProps) => {
	const { address: defaultAddress, businessTimes: defaultBusinessTimes } = laundryUnit || {};

	const [address, setAddress] = useOptionalState<Address>(defaultAddress);
	const [businessTimes, setBusinessTimes] =
		useOptionalState<BusinessTime[]>(defaultBusinessTimes);
	const [identification, setIdentification] =
		useOptionalState<LaundryUnitIdentification>(laundryUnit);

	const { submit, submitting } = useSubmit({
		address,
		businessTimes,
		identification,
		laundryUnit,
		...props,
	});

	const onAddressSubmitted = useCallback(
		(address: Address) =>
			new Promise<void>((resolve) => {
				setAddress(address);
				resolve();
			}),
		[setAddress]
	);

	const onBusinessTimesSubmitted = useCallback(
		(businessTimes: BusinessTime[]) =>
			new Promise<void>((resolve) => {
				setBusinessTimes(businessTimes);
				resolve();
			}),
		[setBusinessTimes]
	);

	const onIdentificationSubmitted = useCallback(
		(identification: LaundryUnitIdentification) =>
			new Promise<void>((resolve) => {
				setIdentification(identification);
				resolve();
			}),
		[setIdentification]
	);

	return (
		<LaundryUnitFormView
			loading={submitting}
			address={address}
			businessTimes={businessTimes}
			identification={identification}
			onSubmit={submit}
			onAddressSubmitted={onAddressSubmitted}
			onBusinessTimesSubmitted={onBusinessTimesSubmitted}
			onIdentificationSubmitted={onIdentificationSubmitted}
		/>
	);
};
