import { PumpService } from "./types";

export const pumpService: PumpService = {
	list: () => ({
		method: "GET",
		path: "/v1/pump",
	}),
	filterByDevice: ({ deviceId }) => ({
		method: "GET",
		path: `/v1/pump?filterField=deviceId&filterText=${deviceId}`,
	}),
	create: (json) => ({
		json,
		method: "POST",
		path: `/v1/pump`,
	}),
	getLogs: ({ deviceId, page, limit }) => ({
		method: "GET",
		path: `/v1/log-device/pump/${deviceId}?page=${page}&limit=${limit}`,
	}),
	edit: ({ pumpId, ...json }) => ({
		json,
		method: "PUT",
		path: `/v1/pump/${pumpId}`,
	}),
	delete: ({ pumpId }) => ({
		method: "DELETE",
		path: `/v1/pump/${pumpId}`,
	}),
};
