import { LaundryList } from "@/components/LaundryList";
import { LaundryManagementModuleContentViewProps } from "./types";

type ComponentType = React.FC<LaundryManagementModuleContentViewProps>;
export const LaundryManagementModuleContentView: ComponentType = (props) => {
	return (
		<LaundryList
			laundries={props.laundries}
			loading={props.loading}
			onLaundryEdited={props.onLaundryEdited}
			onLaundryDeleted={props.onLaundryDeleted}
			handleSortChange={props.handleSortChange}
			handleFilterChange={props.handleFilterChange}
		/>
	);
};
