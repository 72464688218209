import { CustomerSelectInput } from "@/components/CustomerSelectInput";
import { Customer } from "@/models/Customer";
import { Laundry } from "@/models/Laundry";
import { LaundryUnit } from "@/models/LaundryUnit";
import { required } from "@/models/rules/required";
import { Button, Form, Input, Row } from "antd";
import { LaundryUnitSelectInput } from "../LaundryUnitSelectInput";
import { LaundrySelectInput } from "../LaundrySelectInput";
import { DeviceFormViewProps } from "./types";
import { useEffect, useState } from "react";

type ComponentType = React.FC<DeviceFormViewProps>;
export const DeviceFormView: ComponentType = (props) => {
	const [laundryId, setLaundryId] = useState<string>();
	const [customerId, setCustomerId] = useState<string>();

	useEffect(() => {
		setLaundryId(props.initialValue?.laundryId);
		setCustomerId(props.initialValue?.customerId);

	}, [props.initialValue?.laundryId, props.initialValue?.customerId]);

	return (
		<Form
			layout="vertical"
			form={props.form}
			onFinish={props.onSubmit}
			initialValues={props.initialValue}
		>
			<Form.Item name="serialCode" label="Código" rules={[required]}>
				<Input disabled={props.submitting || !!props.initialValue?.serialCode} />
			</Form.Item>
			<Form.Item name="name" label="Nome">
				<Input disabled={props.submitting} />
			</Form.Item>
			<Form.Item
				name="customerId"
				label="Cliente"
				normalize={(customer?: Customer) => customer?.id}
			>
				<CustomerSelectInput disabled={props.submitting}

				onChange={(customer?: Customer | undefined) => {
					setCustomerId(customer?.id);
					props.form.setFieldValue("laundryId", undefined);
					props.form.setFieldValue("laundryUnitId", undefined);

				}}


				/>
			</Form.Item>

			<Form.Item
				name="laundryId"
				label="Franquia"
				normalize={(laundry?: Laundry) => laundry?.id}

			>
				<LaundrySelectInput
					customerId={customerId}
					onChange={(laundry: Laundry | undefined) => {
						setLaundryId(laundry?.id);
						props.form.setFieldValue("laundryUnitId", undefined);
					}}
					disabled={props.submitting || !customerId}
				/>
			</Form.Item>
			<Form.Item
				rules={[
					({ getFieldValue }) => ({
						validator(_, value) {
							if (getFieldValue("laundryId")) {
								if (!value) {
									return Promise.reject("Esse campo é obrigatório");
								}
							}
							return Promise.resolve();
						},
					}),
				]}
				name="laundryUnitId"
				label="Unidade"
				normalize={(laundryUnit?: LaundryUnit) => laundryUnit?.id}
			>
				<LaundryUnitSelectInput
					laundryId={laundryId}
					laundryUnitId={props.initialValue?.laundryUnitId}
					disabled={props.submitting || !laundryId}
				/>
			</Form.Item>

			<Row justify="end">
				<Button
					type="primary"
					htmlType="submit"
					disabled={props.submitting}
					loading={props.submitting}
				>
					Salvar
				</Button>
			</Row>
		</Form>
	);
};
