import { required } from "@/models/rules/required";
import { Button, Form, Input, Row } from "antd";
import { RecipientFormViewProps } from "./types";
import { NumberInput } from "../NumberInput";
import { LaundrySelectInput } from "../LaundrySelectInput";
import { Laundry } from "@/models/Laundry";
import { useEffect, useState } from "react";
import { LaundryUnit } from "@/models/LaundryUnit";
import { LaundryUnitSelectInput } from "../LaundryUnitSelectInput";
import { useIdentityContext } from "@/contexts/Identity";

type ComponentType = React.FC<RecipientFormViewProps>;
export const RecipientFormView: ComponentType = (props) => {
	const { user } = useIdentityContext();

	const [laundryId, setLaundryId] = useState<string>();

	useEffect(() => {
		setLaundryId(props.initialValue?.laundryId);
	}, [props.initialValue?.laundryId]);

	return (
		<Form
			layout="vertical"
			form={props.form}
			onFinish={props.onSubmit}
			initialValues={props.initialValue}
		>

			<Form.Item name="volume" label="Volume" rules={[required]}>
				<NumberInput disabled={props.submitting} addonAfter="ml" />
			</Form.Item>

			<Form.Item name="produto" label="Produto" rules={[required]}>
				<Input disabled={props.submitting} />
			</Form.Item>

			<Form.Item
				name="laundryId"
				label="Franquia"
				normalize={(laundry?: Laundry) => laundry?.id}
				rules={[required]}
			>
				<LaundrySelectInput
					customerId={user?.scopedCustomer?.id}
					onChange={(laundry: Laundry | undefined) => {
						setLaundryId(laundry?.id);
						props.form.setFieldValue("laundryUnitId", undefined);
					}}
				/>
			</Form.Item>
			<Form.Item
				rules={[
					required,
					({ getFieldValue }) => ({
						validator(_, value) {
							if (getFieldValue("laundryId")) {
								if (!value) {
									return Promise.reject("Esse campo é obrigatório");
								}
							}
							return Promise.resolve();
						},
					}),
				]}

				name="laundryUnitId"
				label="Unidade"
				normalize={(laundryUnit?: LaundryUnit) => laundryUnit?.id}
			>
				<LaundryUnitSelectInput
					laundryId={laundryId}
					laundryUnitId={props.initialValue?.laundryUnitId}
					disabled={props.submitting || !laundryId}
				/>
			</Form.Item>

			<Row justify="end">
				<Button
					type="primary"
					htmlType="submit"
					disabled={props.submitting}
					loading={props.submitting}
				>
					Salvar
				</Button>
			</Row>
		</Form>
	);
};
