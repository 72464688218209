import locale from "antd/es/date-picker/locale/pt_BR";
import { emailTermStatusToString } from "@/utilities/format/optional";
import {
	SwitcherOutlined,
	DeleteOutlined,
	EditOutlined,
	BarsOutlined,
	SearchOutlined,
} from "@ant-design/icons";
import { Button, DatePicker, Input, Popconfirm, Row, Select, Space, Table } from "antd";
import { TrialDeviceListViewProps } from "./types";
import { TrialDevice } from "@/models/Trial";
import { formatDateTime } from "@/utilities/format/date";
import { useState } from "react";
const { Option } = Select;

type ComponentType = React.FC<TrialDeviceListViewProps>;
export const TrialDeviceListView: ComponentType = (props) => {
	const [sortOrder, setSortOrder] = useState<any | null>(null);
	const [searchText, setSearchText] = useState("");

	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		props.onFilter(dataIndex, selectedKeys[0]);
	};

	const handleSearchEnum = (selectedKeys, confirm, dataIndex) => {
		confirm();
		props.onFilter(dataIndex, selectedKeys);
	};

	const handleReset = (clearFilters) => {
		clearFilters();
		setSearchText("");
		props.onFilter("", "");
	};

	const handleSort = (field: string, order: "ascend" | "descend") => {
		if (sortOrder?.field === field && sortOrder?.order === order) {
			setSortOrder({ field, order: order === "ascend" ? "descend" : "ascend" });
		} else {
			setSortOrder({ field, order });
		}

		props.onSort(field, order === "descend" ? "desc" : "asc");
	};


	return (
		<Table
			rowKey="id"
			title={() => "Equipamentos com plano gratuíto"}
			dataSource={props.devices}
			loading={props.loading}
			style={{ width: "100%" }}
		>
			<Table.Column
				showSorterTooltip={false}
				sorter={true}
				onHeaderCell={() => ({
					onClick: () => handleSort("serialCode", sortOrder?.order || "ascend"),
				})}
				filterDropdown={({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
					<div style={{ padding: 8 }}>
						<Input
							placeholder={`Buscar código`}
							value={selectedKeys[0]?.toString()}
							onChange={(e) =>
								setSelectedKeys(e.target.value ? [e.target.value] : [])
							}
							onPressEnter={() => handleSearch(selectedKeys, confirm, "serialCode")}
							style={{ width: 188, marginBottom: 8, display: "block" }}
						/>
						<Space>
							<Button
								type="primary"
								onClick={() => handleSearch(selectedKeys, confirm, "serialCode")}
								icon={<SearchOutlined />}
								size="small"
								style={{ width: 90 }}
							>
								Buscar
							</Button>
							<Button
								onClick={() => handleReset(clearFilters)}
								size="small"
								style={{ width: 90 }}
							>
								Limpar
							</Button>
						</Space>
					</div>
				)}
				filterIcon={() => {
					return (
						<SearchOutlined style={{ color: searchText ? "#1890ff" : "#00000073" }} />
					);
				}}
				title="Código"
				render={(device: TrialDevice) => device?.serialCode}
			/>

			<Table.Column
				showSorterTooltip={false}
				sorter={true}
				title="Nome"
				filterDropdown={({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
					<div style={{ padding: 8 }}>
						<Input
							placeholder={`Buscar nome`}
							value={selectedKeys[0]?.toString()}
							onChange={(e) =>
								setSelectedKeys(e.target.value ? [e.target.value] : [])
							}
							onPressEnter={() => handleSearch(selectedKeys, confirm, "deviceName")}
							style={{ width: 188, marginBottom: 8, display: "block" }}
						/>
						<Space>
							<Button
								type="primary"
								onClick={() => handleSearch(selectedKeys, confirm, "deviceName")}
								icon={<SearchOutlined />}
								size="small"
								style={{ width: 90 }}
							>
								Buscar
							</Button>
							<Button
								onClick={() => handleReset(clearFilters)}
								size="small"
								style={{ width: 90 }}
							>
								Limpar
							</Button>
						</Space>
					</div>
				)}
				filterIcon={() => {
					return (
						<SearchOutlined style={{ color: searchText ? "#1890ff" : "#00000073" }} />
					);
				}}
				render={(device: TrialDevice) => device?.deviceName}
			/>

			<Table.Column
				showSorterTooltip={false}
				sorter={true}
				title="Email"
				render={(device: TrialDevice) => {
					return device?.trialEmailEmail ? device?.trialEmailEmail : "Não associado";
				}}
				onHeaderCell={() => ({
					onClick: () => handleSort("trialEmailEmail", sortOrder?.order || "ascend"),
				})}
				filterDropdown={({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
					<div style={{ padding: 8 }}>
						<Input
							placeholder={`Buscar email`}
							value={selectedKeys[0]?.toString()}
							onChange={(e) =>
								setSelectedKeys(e.target.value ? [e.target.value] : [])
							}
							onPressEnter={() =>
								handleSearch(selectedKeys, confirm, "trialEmailEmail")
							}
							style={{ width: 188, marginBottom: 8, display: "block" }}
						/>
						<Space>
							<Button
								type="primary"
								onClick={() =>
									handleSearch(selectedKeys, confirm, "trialEmailEmail")
								}
								icon={<SearchOutlined />}
								size="small"
								style={{ width: 90 }}
							>
								Buscar
							</Button>
							<Button
								onClick={() => handleReset(clearFilters)}
								size="small"
								style={{ width: 90 }}
							>
								Limpar
							</Button>
						</Space>
					</div>
				)}
				filterIcon={() => {
					return (
						<SearchOutlined style={{ color: searchText ? "#1890ff" : "#00000073" }} />
					);
				}}
			/>

			<Table.Column
				showSorterTooltip={false}
				sorter={true}
				title="Data e Hora"
				render={(device: TrialDevice) =>
					device?.trialEmailAceiteTermos
						? formatDateTime(device?.trialEmailAceiteTermos)
						: "--"
				}
				onHeaderCell={() => ({
					onClick: () =>
						handleSort("trialEmailAceiteTermos", sortOrder?.order || "ascend"),
				})}
				filterDropdown={({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
					<div style={{ padding: 8 }}>
						<DatePicker
							locale={locale}
							placeholder="Selecionar data"
							style={{ width: 188, marginBottom: 8, display: "block" }}
							format="DD/MM/YYYY"
							value={selectedKeys[0] as any}
							onChange={(value: any) => {
								if (value) {
									handleSearch(
										[value?.format().split("T")[0]],
										confirm,
										"trialEmailAceiteTermos"
									);
								}
								setSelectedKeys(value ? [value] : []);
							}}
						/>
						<Space>
							<Button
								onClick={() => {
									setSelectedKeys([]);
									handleReset(clearFilters);
								}}
								size="small"
								style={{ width: 90 }}
							>
								Limpar
							</Button>
						</Space>
					</div>
				)}
				filterIcon={() => {
					return (
						<SearchOutlined style={{ color: searchText ? "#1890ff" : "#00000073" }} />
					);
				}}
			/>

			<Table.Column
				showSorterTooltip={false}
				sorter={true}
				title="Status"
				render={(device: TrialDevice) =>
					device?.trialEmailStatus
						? emailTermStatusToString(device?.trialEmailStatus)
						: "Desconhecido"
				}
				onHeaderCell={() => ({
					onClick: () => handleSort("trialEmailStatus", sortOrder?.order || "ascend"),
				})}
				filterDropdown={({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
					<div style={{ padding: 8 }}>
						<Space>
							<Select
								placeholder="Selecionar status"
								style={{ width: 350, marginBottom: 8, display: "block" }}
								value={selectedKeys[0]}
								onChange={(value) => {
									setSelectedKeys(value ? [value] : []);
									handleSearchEnum(value, confirm, "trialEmailStatus");
								}}
							>
								<Option value="WAITING">Aguardando</Option>
								<Option value="ACCEPT">Aceito</Option>
								<Option value="REFUSE">Recusado</Option>
								<Option value="DONT_REQUEST">Não solicitei</Option>
								<Option value="UNKNOWN">Desconhecido</Option>
							</Select>
							<Button
								onClick={() => handleReset(clearFilters)}
								size="small"
								style={{ width: 90 }}
							>
								Limpar
							</Button>
						</Space>
					</div>
				)}
				filterIcon={() => {
					return (
						<SearchOutlined style={{ color: searchText ? "#1890ff" : "#00000073" }} />
					);
				}}
			/>

			<Table.Column
				showSorterTooltip={false}
				sorter={true}
				onHeaderCell={() => ({
					onClick: () => handleSort("trialRemaining", sortOrder?.order || "ascend"),
				})}
				filterDropdown={({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
					<div style={{ padding: 8 }}>
						<Input
							placeholder={`Buscar status`}
							value={selectedKeys[0]?.toString()}
							onChange={(e) =>
								setSelectedKeys(e.target.value ? [e.target.value] : [])
							}
							onPressEnter={() =>
								handleSearch(selectedKeys, confirm, "trialRemaining")
							}
							style={{ width: 188, marginBottom: 8, display: "block" }}
						/>
						<Space>
							<Button
								type="primary"
								onClick={() =>
									handleSearch(selectedKeys, confirm, "trialRemaining")
								}
								icon={<SearchOutlined />}
								size="small"
								style={{ width: 90 }}
							>
								Buscar
							</Button>
							<Button
								onClick={() => handleReset(clearFilters)}
								size="small"
								style={{ width: 90 }}
							>
								Limpar
							</Button>
						</Space>
					</div>
				)}
				filterIcon={() => {
					return (
						<SearchOutlined style={{ color: searchText ? "#1890ff" : "#00000073" }} />
					);
				}}
				title="Tempo restante"
				render={(device: TrialDevice) =>
					device.trialRemaining ? `${device.trialRemaining} dias` : ""
				}
			/>

			<Table.Column<TrialDevice>
				key="actions"
				title="Ações"
				render={(_, device) => {
					const deletable = props.isDeviceDeletable(device);

					return (
						<Row justify="center">
							<Space>
								<Button
									ghost
									icon={<BarsOutlined />}
									onClick={() =>
										props.onLogDevice(
											device?.deviceTrialInfoDeviceId,
											device?.serialCode,
											props.onOpen
										)
									}
								/>
								<Button
									ghost
									icon={<EditOutlined />}
									onClick={() => props.onEditDevice(device)}
								/>

								<Popconfirm
									placement="topRight"
									disabled={!deletable}
									title="Tem certeza que deseja move esse equipamento para o plano pago?"
									onConfirm={() => props.onMoveToPayment(device)}
								>
									<Button
										ghost
										title="Mover para o plano pago?"
										disabled={!deletable}
										icon={<SwitcherOutlined />}
									/>
								</Popconfirm>

								<Popconfirm
									placement="topRight"
									disabled={!deletable}
									title="Tem certeza que deseja remover esse equipamento? Essa ação é irreversível."
									onConfirm={() => props.onDeleteDevice(device)}
								>
									<Button
										ghost
										danger
										disabled={!deletable}
										icon={<DeleteOutlined />}
									/>
								</Popconfirm>
							</Space>
						</Row>
					);
				}}
			/>
		</Table>

	);
};
