import { useHttp } from "@/application/HttpClient";
import { Device } from "@/models/Device";
import { deviceService } from "@/services/device";
import { useCollection } from "@/utilities/collections/useCollection";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { DeviceManagementModuleContextValue } from "./types";
import { Filter } from "@/utilities/interface/Filter";

const DeviceManagementModuleContext = React.createContext<DeviceManagementModuleContextValue>(
	{} as any
);

export const DeviceManagementModuleContextProvider = (props: React.PropsWithChildren) => {
	const { request } = useHttp();

	const [pagination, setPagination] = useState<any>({
		page: 1,
		pageSize: 5,
	});
	const [total, setTotal] = useState<number>(0);

	const handleSetPaginationChange = (page: number) => {
		console.log({ page });
		setLoading(true);
		setPagination({
			page,
			pageSize: 5,
		});
	};

	const [order, setOrder] = useState<Filter>({
		sortField: "createdAt",
		sortOrder: "desc",
	});

	const [filter, setFilter] = useState<any>({
		filterField: "",
		filterText: "",
	});

	const handleSortChange = (field: string, sortOrder: string) =>
		setOrder({
			sortField: field,
			sortOrder: sortOrder,
		});

	const handleFilterChange = (field: string, text: string) =>
		setFilter({
			filterField: field,
			filterText: text,
		});
	const [loading, setLoading] = useState<boolean>(true);
	const [devices, setDevices] = useCollection<Device>([]);

	const onUpdateList = useCallback(() => setLoading(true), []);

	useEffect(() => {
		if (loading) {
			request(deviceService.list, { ...order, ...filter, ...pagination })
				.then(({ total, rows }) => {
					// setPagination(total);
					setTotal(total);
					setDevices(rows);
				})
				.catch(() => setDevices([]))
				.finally(() => setLoading(false));
		}
	}, [loading, request, setDevices]);

	return (
		<DeviceManagementModuleContext.Provider
			value={{
				loading,
				onUpdateList,
				devices,
				total,
				handleSortChange,
				handleFilterChange,
				handleSetPaginationChange,
			}}
		>
			{props.children}
		</DeviceManagementModuleContext.Provider>
	);
};

export const useDeviceManagementModuleContext = () => {
	return useContext(DeviceManagementModuleContext);
};
