import { EditRecipientAction } from "@/actions/EditRecipientAction";
import { useAuthorizationContext } from "@/contexts/Authorization";
import { useStatusContext } from "@/contexts/Status";
import { useCallback, useMemo } from "react";
import { useRecipientManagementModuleContext } from "../RecipientManagementModuleContext";
import { RecipientManagementModuleSubHeaderProps } from "./types";
import { RecipientManagementModuleSubHeaderView } from "./view";

type ComponentType = React.FC<RecipientManagementModuleSubHeaderProps>;
export const RecipientManagementModuleSubHeader: ComponentType = (props) => {
	const { onActionChange } = useStatusContext();
	const { onUpdateList } = useRecipientManagementModuleContext();
	const authorization = useAuthorizationContext();

	const addDisabled = useMemo(() => !authorization.canCreate("ChemicalRecipient"), [authorization]);

	const onAddRecipient = useCallback(() => {
		onActionChange({
			action: new EditRecipientAction(),
			onActionEnd: () => onUpdateList(),
		});
	}, [onActionChange, onUpdateList]);

	return (
		<RecipientManagementModuleSubHeaderView addDisabled={addDisabled} onAddRecipient={onAddRecipient} />
	);
};
