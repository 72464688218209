import { Button, Form, Image, Input } from "antd";

import { emailPattern } from "@/models/rules/emailPattern";
import { ReverseRow } from "@components/ReverseRow";
import { minimumStringLength } from "@models/rules/minimumLength";
import { required } from "@models/rules/required";

import { LoginOutlined } from "@ant-design/icons";
import { Panel } from "./styles";
import { LoginFormViewProps } from "./types";
import { useNavigate } from "react-router-dom";

export const LoginFormView = (props: LoginFormViewProps) => {
	const navigate = useNavigate();
	const handleRegisterRedirect = () => {
		navigate("/novo-usuario");
	};

	return (
		<Panel>
			<Image src="/image/tron-azul.png" preview={false} />
			<Form
				layout="vertical"
				form={props.form}
				onFinish={props.onSubmit}
				validateTrigger="onBlur"
			>
				<Form.Item name="username" label="E-mail" rules={[emailPattern, required]}>
					<Input data-form-type="username" disabled={props.loading} />
				</Form.Item>

				<Form.Item name="password" label="Senha" rules={[required, minimumStringLength(8)]}>
					<Input.Password data-form-type="password" disabled={props.loading} />
				</Form.Item>

				<ReverseRow>
					<Button
						type="primary"
						htmlType="submit"
						disabled={props.loading}
						loading={props.loading}
						icon={<LoginOutlined />}
					>
						Login
					</Button>

					<Button type="link">Esqueci a senha</Button>
				</ReverseRow>
				<Button type="link" onClick={handleRegisterRedirect}>
					Cadastrar
				</Button>
			</Form>
		</Panel>
	);
};
