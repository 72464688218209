import { useDeviceManagementModuleContext } from "../DeviceManagementModuleContext";
import { DeviceManagementModuleContentProps } from "./types";
import { DeviceManagementModuleContentView } from "./view";

type ComponentType = React.FC<DeviceManagementModuleContentProps>;
export const DeviceManagementModuleContent: ComponentType = (props) => {
	const {
		devices,
		loading,
		onUpdateList,
		handleSortChange,
		handleFilterChange,
		total,
		handleSetPaginationChange,
	} = useDeviceManagementModuleContext();

	return (
		<DeviceManagementModuleContentView
			devices={devices}
			loading={loading}
			onDeviceEdited={onUpdateList}
			onDeviceDeleted={onUpdateList}
			handleSortChange={handleSortChange}
			handleFilterChange={handleFilterChange}
			total={total}
			onPagination={handleSetPaginationChange}
		/>
	);
};
