import { ZipCodeLocation } from "@/models/location/ZipCodeLocation";
import { zipCodePattern } from "@/models/rules";
import { useDebouncedEffect } from "@/utilities/hooks/useDebouncedEffect";
import { Form, FormInstance } from "antd";
import cep from "cep-promise";
import { useEffect, useMemo, useState } from "react";
import { AddressFormData, Feedback } from "../types";

export const useZipCodeLocation = (form: FormInstance<AddressFormData>) => {
	const [location, setLocation] = useState<ZipCodeLocation | null>();
	const [loading, setLoading] = useState<boolean>(false);

	const zipCode = Form.useWatch("zipCode", form);
	const feedback = useMemo<Feedback | undefined>(() => {
		if (location === undefined) return undefined;
		if (location === null) return { message: "CEP não encontrado", status: "warning" };

		return { status: loading ? "validating" : "success" };
	}, [loading, location]);

	useDebouncedEffect({
		time: 300,
		dependencies: [zipCode],
		effect: () => {
			const isValid = zipCode?.match(zipCodePattern.pattern);

			if (isValid) {
				const sanitizedZipCode = zipCode?.replace(/[^0-9]/g, "");
				if (sanitizedZipCode) {
					setLoading(true);
					setTimeout(() => {
						cep(sanitizedZipCode)
							.then((location) => setLocation(location))
							.catch(() => setLocation(null))
							.finally(() => setLoading(false));
					}, 500);
				}
			} else {
				setLocation(undefined);
			}
		},
	});

	useEffect(() => {
		if (location) {
			form.setFieldsValue({
				city: location.city,
				state: location.state,
				street: location.street,
				neighborhood: location.neighborhood,
			});
		} else {
			form.resetFields(["city", "state", "street", "neighborhood"]);

			form.setFieldsValue({
				city: undefined,
				state: undefined,
				street: undefined,
				neighborhood: undefined,
			});
		}
	}, [form, location]);

	return feedback;
};
