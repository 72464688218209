import { Module } from "@/models/modules/Module";
import { concatenatePaths } from "@/utilities/text/concatenatePaths";
import { ItemType } from "antd/lib/menu/hooks/useItems";

export const moduleToMenuItem = (module: Module): ItemType => ({
	key: module.path,
	label: module.title,
	icon: module.icon,
	...(module.children && {
		children: module.children?.map((child) => ({
			...moduleToMenuItem(child),
			key: concatenatePaths(module.path, child.path),
		})),
	}),
});
