import { Button } from "antd";
import { Wrapper } from "./style";
import { RecipientManagementModuleSubHeaderViewProps } from "./types";

type ComponentType = React.FC<RecipientManagementModuleSubHeaderViewProps>;
export const RecipientManagementModuleSubHeaderView: ComponentType = (props) => {
	return (
		<Wrapper>
			<Button disabled={props.addDisabled} onClick={() => props.onAddRecipient()}>
				Novo recipiente
			</Button>
		</Wrapper>
	);
};
