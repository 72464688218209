import { Card } from "antd";
import styled from "styled-components";

export const Panel = styled(Card)`
	width: 80vw;
	border-top: 15px solid #354052;
	border-bottom: 15px solid #354052;

	@media (min-width: 820px) {
		width: 724px;
	}

	& .ant-card-body {
		display: flex;
		flex-direction: column;
	}
`;
