export const normalize = (value?: string) => {
	if (value === "0") return 0;
	if (value === undefined) return undefined;

	const decimalSeparatorTestValue = 1.1;
	const decimalSeparator = decimalSeparatorTestValue.toLocaleString().substring(1, 2);
	const cleanupPattern = new RegExp(`[^0-9${decimalSeparator}]`, "g");
	const cleanValue = value.replace(cleanupPattern, "");

	if (cleanValue === "") return undefined;
	return Number(cleanValue);
};
