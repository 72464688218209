import { Loading } from "@/components/Loading";
import { Overlay } from "@/components/Overlay";
import { useStatusContext } from "@/contexts/Status";
import { ChildrenWrapper } from "./style";
import { ApplicationGuardProps } from "./types";

export const ApplicationGuard = (props: ApplicationGuardProps) => {
	const { applicationReady } = useStatusContext();

	return (
		<Overlay visible={!applicationReady} content={<Loading tip="Por favor, aguarde" />}>
			<ChildrenWrapper>{applicationReady && props.children}</ChildrenWrapper>
		</Overlay>
	);
};
