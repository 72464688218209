import { useHttp } from "@/application/HttpClient";
import { Customer } from "@/models/Customer";
import { customerService } from "@/services/customer";
import { useCollection } from "@/utilities/collections/useCollection";
import { createContext, useCallback, useContext, useEffect, useState } from "react";
import {
	CustomerManagementModuleContextProviderProps,
	CustomerManagementModuleContextValue,
} from "./types";
import { Filter } from "@/utilities/interface/Filter";

const CustomerManagementModuleContext = createContext<CustomerManagementModuleContextValue>(
	{} as any
);

type ComponentType = (props: CustomerManagementModuleContextProviderProps) => React.ReactElement;
export const CustomerManagementModuleContextProvider: ComponentType = (props) => {
	const { request } = useHttp();

	const [loading, setLoading] = useState<boolean>(true);

	const [order, setOrder] = useState<Filter>({
		sortField: "createdAt",
		sortOrder: "desc",
	});

	const [filter, setFilter] = useState<any>({
		filterField: "",
		filterText: "",
	});

	const handleSortChange = (field: string, sortOrder: string) =>
		setOrder({
			sortField: field,
			sortOrder: sortOrder,
		});

	const handleFilterChange = (field: string, text: string) =>
		setFilter({
			filterField: field,
			filterText: text,
		});

	const [customers, setCustomers] = useCollection<Customer>([]);

	const onUpdateList = useCallback(() => setLoading(true), []);

	useEffect(() => {
		if (loading) {
			request(customerService.list, {...order, ...filter})
				.then((customers) => setCustomers(customers))
				.catch(() => setCustomers([]))
				.finally(() => setLoading(false));
		}
	}, [loading, request, setCustomers]);

	return (
		<CustomerManagementModuleContext.Provider value={{ loading, onUpdateList, customers, handleSortChange, handleFilterChange }}>
			{props.children}
		</CustomerManagementModuleContext.Provider>
	);
};

export const useCustomerManagementModuleContext = () => {
	return useContext(CustomerManagementModuleContext);
};
