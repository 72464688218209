import { DeviceService } from "./types";

export const deviceService: DeviceService = {
	list: ({ sortField, sortOrder, filterField, filterText, page, pageSize }) => ({
		method: "GET",
		path: `/v1/device?sortField=${sortField}&sortOrder=${sortOrder}&filterField=${filterField}&filterText=${filterText}&page=${page}&pageSize=${pageSize}`,
	}),
	infoDevices: ({ sortField, sortOrder, filterField, filterText, page }) => ({
		method: "GET",
		path: `/v1/info-devices?sortField=${sortField}&sortOrder=${sortOrder}&filterField=${filterField}&filterText=${filterText}&page=${page}`,
	}),
	listByUnit: ({ laundryUnitId }) => ({
		method: "GET",
		path: `/v1/device/laundryUnit/${laundryUnitId}`,
	}),
	filter: ({ deviceId }) => ({
		method: "GET",
		path: `/v1/device/${deviceId}/available`,
	}),
	get: ({ deviceId }) => ({
		method: "GET",
		path: `/v1/log-device/resume/device/${deviceId}`,
	}),
	getLogs: ({ deviceId, page, limit }) => ({
		method: "GET",
		path: `/v1/log-device/device/${deviceId}?page=${page}&limit=${limit}`,
	}),
	available: () => ({
		method: "GET",
		path: "/v1/device/available",
	}),
	create: (json) => ({
		json,
		method: "POST",
		path: `/v1/device`,
	}),
	edit: ({ deviceId, ...json }) => ({
		json,
		method: "PUT",
		path: `/v1/device/${deviceId}`,
	}),
	delete: ({ deviceId }) => ({
		method: "DELETE",
		path: `/v1/device/${deviceId}`,
	}),
	moveToTrial: ({ deviceId }) => ({
		method: "POST",
		path: `/v1/device/update-payment-to-trial/${deviceId}`,
	}),
	getLogSheet: (json) => ({
		json,
		method: "POST",
		path: "/v1/log-device/complete-log-sheet",
		responseType: "blob",
	}),
	getRawSheet: (json) => ({
		json,
		method: "POST",
		path: "/v1/log-device/raw",
		responseType: "blob",
	}),
};
