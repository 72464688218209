import { EditUserAction } from "@/actions/EditUserAction";
import { useIdentityContext } from "@/contexts/Identity";
import { useStatusContext } from "@/contexts/Status";
import { getFullName } from "@/utilities/users/getFullName";
import { EditOutlined, LogoutOutlined } from "@ant-design/icons";
import { notification } from "antd";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { useMemo } from "react";
import { UserOptionsDetailProps } from "./types";
import { UserOptionsDetailView } from "./view";

export const UserOptionsDetail = (props: UserOptionsDetailProps) => {
	const { user, updateUser, logout } = useIdentityContext();
	const { onActionChange } = useStatusContext();

	const userIdentification = useMemo(() => {
		return user ? getFullName(user) : "";
	}, [user]);

	const options = useMemo<ItemType[]>(
		() => [
			{
				key: "edit",
				label: "Editar",
				icon: <EditOutlined />,
				onClick: () => {
					onActionChange({
						action: new EditUserAction(user),
						onActionEnd: () => updateUser(),
					});
				},
			},
			{
				danger: true,
				key: "logout",
				label: "Sair",
				icon: <LogoutOutlined />,
				onClick: () => {
					logout().then(() => {
						notification.info({ message: "Logout realizado" });
					});
				},
			},
		],
		[logout, onActionChange, updateUser, user]
	);

	return <UserOptionsDetailView userIdentification={userIdentification} options={options} />;
};
