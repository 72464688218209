import { Input } from "antd";
import { ChangeEvent, useCallback } from "react";
import MaskedInput from "react-text-mask";
import { LegalEntityNumberInputProps } from "./types";
const phoneMask = (value: string) => {
	const numbers = value.replace(/\D/g, "");
	if (numbers.length <= 10) {
		return ["(", /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];
	}
	return ["(", /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];
};

type ComponentType = React.FC<LegalEntityNumberInputProps>;
export const PhoneNumberInput: ComponentType = ({ value, onChange, ...props }) => {
	const onInputChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => onChange?.(event.target.value),
		[onChange]
	);

	return (
		<MaskedInput
			mask={phoneMask}
			value={value}
			onChange={onInputChange}
			render={(ref, props) => (
				<Input
					ref={(inputRef) => inputRef?.input && ref(inputRef.input)}
					value={value}
					{...props}
				/>
			)}
		/>
	);
};
