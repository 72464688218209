import { DayOfWeek } from "@/models/time/DayOfWeek";

const translations = {
	[DayOfWeek.Monday]: "Segunda-feira",
	[DayOfWeek.Tuesday]: "Terça-feira",
	[DayOfWeek.Wednesday]: "Quarta-feira",
	[DayOfWeek.Thursday]: "Quinta-feira",
	[DayOfWeek.Friday]: "Sexta-feira",
	[DayOfWeek.Saturday]: "Sábado",
	[DayOfWeek.Sunday]: "Domingo",
};

export const formatDayOfWeek = (dayOfWeek: DayOfWeek) => translations[dayOfWeek];
