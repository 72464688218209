import { useHttp } from "@/application/HttpClient";
import { CustomerRole } from "@/models/CustomerRole";
import { customerService } from "@/services/customer";
import { Form } from "antd";
import { useCallback, useMemo, useState } from "react";
import { CustomerFormData, CustomerFormProps } from "./types";
import { CustomerFormView } from "./view";

type ComponentType = React.FC<CustomerFormProps>;
export const CustomerForm: ComponentType = (props) => {
	const { request } = useHttp();
	const { onSubmitting, onSubmitted, onSubmissionFailed } = props;

	const [form] = Form.useForm<CustomerFormData>();
	const [submitting, setSubmitting] = useState<boolean>(false);

	const initialValue = useMemo(
		() => props.customer || { role: CustomerRole.Customer },
		[props.customer]
	);

	const onSubmit = useCallback(() => {
		if (submitting) return;

		const formData = form.getFieldsValue();
		const doRequest = () =>
			props.customer
				? request(customerService.edit, {
						customerId: props.customer.id,
						...formData,
				  })
				: request(customerService.create, formData);

		onSubmitting?.();
		setSubmitting(true);

		doRequest()
			.then((customer) => onSubmitted?.(customer))
			.catch((error) => onSubmissionFailed?.(error))
			.finally(() => setSubmitting(false));
	}, [form, onSubmissionFailed, onSubmitted, onSubmitting, props.customer, request, submitting]);

	return (
		<CustomerFormView
			form={form}
			submitting={submitting}
			isEditMode={!!props.customer}
			initialValue={initialValue}
			onSubmit={onSubmit}
		/>
	);
};
