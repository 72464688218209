import { Button } from "antd";
import { Wrapper } from "./style";
import { TrialManagementModuleSubHeaderViewProps } from "./types";

type ComponentType = React.FC<TrialManagementModuleSubHeaderViewProps>;
export const TrialManagementModuleSubHeaderView: ComponentType = (props) => {
	return (
		<Wrapper>
			<Button disabled={props.addDisabled} onClick={() => props.onAddDevice()}>
				Novo equipamento
			</Button>
		</Wrapper>
	);
};
