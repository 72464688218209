import { motion } from "framer-motion";
import { AnimatedWrapperProps } from "./types";

const animations = {
	initial: { opacity: 0 },
	animate: { opacity: 1 },
	exit: { opacity: 0 },
};

export const AnimatedWrapper = (props: AnimatedWrapperProps) => {
	return (
		<motion.div variants={animations} initial="initial" animate="animate" exit="exit">
			{props.children}
		</motion.div>
	);
};
