import { Card } from "antd";
import { DashboardModuleContentViewProps } from "./types";
import { FilterReportView } from "@/components/FilterReport/view";
import { WeeklyReport } from "@/components/WeeklyReport";


type ComponentType = React.FC<DashboardModuleContentViewProps>;
export const DashboardModuleContentView: ComponentType = (props) => {


	const { reports, handleFilterChange, loading } = props


	return (
		<div style={{ height: "calc(100vh - 170px)", overflow: "auto" }}>
			<Card style={{ height: "100%" }} bodyStyle={{ height: "100%" }}>
				<h2 style={{ textAlign: "center" }}>RELATÓRIO POR PERÍODO</h2>

				<FilterReportView  loading={loading} handleFilterChange={handleFilterChange}  />
				<WeeklyReport loading={loading} reports={reports} />
			</Card>
		</div>
	);
};
