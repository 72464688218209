import { Module } from "@/models/modules/Module";
import { ToolOutlined } from "@ant-design/icons";
import { CustomerManagementModule } from "../CustomerManagementModule";
import { DeviceManagementModule } from "../DeviceManagementModule";
import { LaundryManagementModule } from "../LaundryManagementModule";
import { LaundryUnitManagementModule } from "../LaundryUnitManagementModule";
import { RecipientManagementModule } from "../RecipientManagementModule";
import { UserManagementModule } from "../UserManagementModule";
import { TrialManagementModule } from "../TrialManagementModule";
import { MachineModule } from "../MachineModule";

export const ManagementModule: Module = {
	path: "/management",
	title: "Gestão",
	icon: <ToolOutlined />,
	children: [
		CustomerManagementModule,
		UserManagementModule,
		DeviceManagementModule,
		LaundryManagementModule,
		LaundryUnitManagementModule,
		RecipientManagementModule,
		TrialManagementModule,
		MachineModule,
	],
};
