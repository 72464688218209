import { UserList } from "@/components/UserList";
import { UserManagementModuleContentViewProps } from "./types";

export const UserManagementModuleContentView = (props: UserManagementModuleContentViewProps) => {
	return (
		<UserList
			users={props.users}
			loading={props.loading}
			onUserEdited={props.onUserEdited}
			onUserDeleted={props.onUserDeleted}
			handleSortChange={props.handleSortChange}
			handleFilterChange={props.handleFilterChange}
		/>
	);
};
