import { Steps } from "antd";
import React, { useCallback, useMemo, useState } from "react";
import { StepWrapper } from "./style";
import { MultipleStepProps, StepProps } from "./types";

export const MultipleStep = (props: MultipleStepProps) => {
	const [current, setCurrent] = useState<number>(0);

	const moveToNextStep = useCallback(() => setCurrent((x) => x + 1), []);
	const moveToPreviousStep = useCallback(() => setCurrent((x) => Math.max(x - 1, 0)), []);

	const stepControls = useMemo(
		() => ({ moveToNextStep, moveToPreviousStep }),
		[moveToNextStep, moveToPreviousStep]
	);

	return (
		<Steps current={current} direction="vertical">
			{React.Children.map(props.children, (child, index) => {
				return (
					<React.Fragment key={child.key}>
						<Steps.Step
							title={child.props.title}
							subTitle={child.props.description}
							description={
								index === current && (
									<StepWrapper key={child.key}>
										{typeof child.props.children === "function"
											? child.props.children(stepControls)
											: child.props.children}
									</StepWrapper>
								)
							}
						/>
					</React.Fragment>
				);
			})}
		</Steps>
	);
};

export const Step = (props: StepProps) => {
	return <h1>{props.title}</h1>;
};
