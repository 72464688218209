import { DependencyList, EffectCallback, useEffect, useRef } from "react";

export const useComponentDidUpdate = (effect: EffectCallback, dependencies: DependencyList) => {
	const mounted = useRef(false);

	useEffect(() => {
		if (!mounted.current) {
			mounted.current = true;
		} else {
			return effect();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, dependencies);
};
