import { useHttp } from "@/application/HttpClient";
import { LaundryUnit } from "@/models/LaundryUnit";
import { Address } from "@/models/location/Address";
import { BusinessTime } from "@/models/time/BusinessTime";
import { laundryUnitService } from "@/services/laundryUnit";
import { useCallback, useEffect, useMemo, useState } from "react";
import { LaundryUnitFormData, LaundryUnitIdentification } from "../types";

interface SubmitHookProps {
	readonly address?: Address;
	readonly businessTimes?: BusinessTime[];
	readonly identification?: LaundryUnitIdentification;
	readonly laundryUnit?: LaundryUnit;

	readonly onSubmitting?: () => void;
	readonly onSubmitted?: (laundryUnit: LaundryUnit) => void;
	readonly onSubmissionFailed?: (error: Error) => void;
}

export const useSubmit = (props: SubmitHookProps) => {
	const { request } = useHttp();
	const { onSubmissionFailed, onSubmitted, onSubmitting } = props;

	const [submitting, setSubmitting] = useState<boolean>(false);
	const [submitRequested, setSubmitRequested] = useState<boolean>(false);

	const laundryUnitId = useMemo(() => props.laundryUnit?.id, [props.laundryUnit]);

	const formData = useMemo<LaundryUnitFormData | undefined>(() => {
		const addressIsInvalid = props.address === undefined;
		const businessTimesIsInvalid = props.businessTimes === undefined;
		const identificationIsInvalid = props.identification === undefined;

		if (addressIsInvalid || businessTimesIsInvalid || identificationIsInvalid) return undefined;

		return {
			...props.identification,
			address: props.address,
			businessTimes: props.businessTimes,
		};
	}, [props.address, props.businessTimes, props.identification]);

	const submit = useCallback(() => setSubmitRequested(true), []);

	useEffect(() => {
		if (formData !== undefined && submitRequested) {
			// TODO: ajustar esse ponto
			if (!formData.emailReport) {
				formData.emailReport = null;
			}
			if (!formData.address?.complement) {
				formData.address.complement = null;
			}
			if (!formData.address?.streetNumber) {
				formData.address.streetNumber = null;
			}
			if (!formData.address.zipCode) {
				formData.address.zipCode = null;
			}

			if (!formData.address.street) {
				formData.address.street = null;
			}
			if (!formData.phone) {
				formData.phone = null;
			}
			if (!formData.secondaryPhone) {
				formData.secondaryPhone = null;
			}

			setSubmitting(true);
			setSubmitRequested(false);
			onSubmitting?.();

			const doRequest = () =>
				laundryUnitId
					? request(laundryUnitService.edit, { laundryUnitId, ...formData })
					: request(laundryUnitService.create, formData);

			doRequest()
				.then((laundryUnit) => onSubmitted?.(laundryUnit))
				.catch((error) => onSubmissionFailed?.(error))
				.finally(() => setSubmitting(false));
		}
	}, [
		formData,
		laundryUnitId,
		onSubmissionFailed,
		onSubmitted,
		onSubmitting,
		request,
		submitRequested,
	]);

	return { submit, submitting };
};
