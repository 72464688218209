import styled from "styled-components";

export const OverlayWrapper = styled.div.attrs({ className: "overlay-wrapper" })`
	display: flex;
	position: relative;
`;

export const OverlayPanel = styled.div.attrs({ className: "overlay-panel" })`
	display: flex;
	position: absolute;
	align-items: center;
	justify-content: center;

	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 2000;
	background-color: #f5f5f5;
`;
