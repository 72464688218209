import { useHttp } from "@/application/HttpClient";
import { Select } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { PumpSelectInputProps, OptionType } from "./types";
import { Pump } from "@/models/Pump";
import { pumpService } from "@/services/pump";

type ComponentType = React.FC<PumpSelectInputProps>;
export const PumpSelectInput: ComponentType = ({ onChange, ...props }) => {
	const { request } = useHttp();

	const [loading, setLoading] = useState<boolean>(false);
	const [pumps, setPumps] = useState<Pump[]>([]);

	const childValue = useMemo(
		() =>
			!loading
				? typeof props.value !== "string"
					? props.value?.id
					: props.value
				: undefined,
		[loading, props.value]
	);

	const options = useMemo<OptionType[]>(
		() =>
			pumps.map((payload) => ({
				value: payload.id,
				index: payload.index,
				label: `${payload.device.name} - ${payload.index + 1} `,
				payload,
			})),
		[pumps]
	);

	const onChildChange = useCallback(
		(option?: any) => onChange?.(option),
		[onChange]
	);

	useEffect(() => {
		if (props?.deviceId) {
			setLoading(true);

			request(pumpService.filterByDevice, {
				deviceId: props?.deviceId,
			})
				.then((pumps) => setPumps(pumps))
				.catch(() => setPumps([]))
				.finally(() => setLoading(false));
		}
	}, [request, setPumps, props?.deviceId]);

	return (
		<Select
			style={props.style}
			maxTagCount="responsive"
			placeholder="Bomba"
			allowClear
			showSearch
			value={childValue}
			disabled={props.disabled}
			loading={loading}
			options={options}
			onChange={(_value, option) => onChildChange(option as OptionType)}
		/>
	);
};
