import { useHttp } from "@/application/HttpClient";
import { Form } from "antd";
import { useCallback, useState } from "react";
import { RegisterDeviceFormProps } from "./types";
import { customerService } from "@/services/customer";
import { BusinessTimesFormView } from "@/pages/RegisterClientPage/components/BusinessTimesForm/view";
import { BusinessTimesFormData } from "@/pages/RegisterClientPage/components/BusinessTimesForm/types";

type ComponentType = React.FC<RegisterDeviceFormProps>;
export const RegisterDeviceForm: ComponentType = (props) => {
	const { request } = useHttp();
	const { onSubmitting, onSubmitted, onSubmissionFailed } = props;

	const [form] = Form.useForm<BusinessTimesFormData>();
	const [submitting, setSubmitting] = useState<boolean>(false);

	const onSubmit = useCallback(() => {
		if (submitting) return;

		const formData = form.getFieldsValue();
		const doRequest = () => request(customerService.registerDevice, formData);

		onSubmitting?.();
		setSubmitting(true);

		doRequest()
			.then((customer) => onSubmitted?.(customer))
			.catch((error) => onSubmissionFailed?.(error))
			.finally(() => setSubmitting(false));
	}, [form, onSubmissionFailed, onSubmitted, onSubmitting, props.device, request, submitting]);

	return (
		// <DeviceIdentificationFormView form={form} onSubmit={onSubmit} />

		<BusinessTimesFormView loading={submitting} form={form} onSubmit={onSubmit} />
	);
};
