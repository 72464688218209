import { useHttp } from "@/application/HttpClient";
import { useCollection } from "@/utilities/collections/useCollection";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { MachineModuleContextValue } from "./types";
import { Filter } from "@/utilities/interface/Filter";
import { machineService } from "@/services/machine";
import { Machine } from "@/models/Machine";

const MachineModuleContext = React.createContext<MachineModuleContextValue>({} as any);

export const MachineModuleContextProvider = (props: React.PropsWithChildren) => {
	const { request } = useHttp();

	const [order, setOrder] = useState<Filter>({
		sortField: "createdAt",
		sortOrder: "desc",
	});

	const [filter, setFilter] = useState<any>({
		filterField: "",
		filterText: "",
	});

	const handleSortChange = (field: string, sortOrder: string) =>
		setOrder({
			sortField: field,
			sortOrder: sortOrder,
		});

	const handleFilterChange = (field: string, text: string) =>
		setFilter({
			filterField: field,
			filterText: text,
		});
	const [loading, setLoading] = useState<boolean>(true);
	const [machines, setMachines] = useCollection<Machine>([]);

	const onUpdateList = useCallback(() => setLoading(true), []);

	useEffect(() => {
		if (loading) {
			request(machineService.list, { ...order, ...filter })
				.then((devices) => setMachines(devices))
				.catch(() => setMachines([]))
				.finally(() => setLoading(false));
		}
	}, [loading, request, setMachines]);

	return (
		<MachineModuleContext.Provider
			value={{ loading, onUpdateList, machines, handleSortChange, handleFilterChange }}
		>
			{props.children}
		</MachineModuleContext.Provider>
	);
};

export const useMachineModuleContext = () => {
	return useContext(MachineModuleContext);
};
