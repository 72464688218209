import { minimumListLength, required } from "@/models/rules";
import { BusinessTime } from "@/models/time/BusinessTime";
import { DayOfWeek } from "@/models/time/DayOfWeek";
import { enumToOptions } from "@/utilities/collections/enumToOptions";
import { doNothing } from "@/utilities/doNothing";
import { formatDayOfWeek } from "@/utilities/format/dayOfWeek";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Form, Select, TimePicker } from "antd";
import React, { useCallback } from "react";
import { ButtonGroupWrapper, ThinDivider } from "./style";
import { SingleBusinessTimeFormData, SingleBusinessTimeFormProps } from "./types";

const format = "HH[h]mm[m]";
const weekdaysOptions = enumToOptions(DayOfWeek, formatDayOfWeek);

const weekends = [DayOfWeek.Saturday, DayOfWeek.Sunday];
const weekdays = [
	DayOfWeek.Monday,
	DayOfWeek.Tuesday,
	DayOfWeek.Wednesday,
	DayOfWeek.Thursday,
	DayOfWeek.Friday,
];

export const SingleBusinessTimeForm = ({ onSubmit, ...props }: SingleBusinessTimeFormProps) => {
	const [form] = Form.useForm<SingleBusinessTimeFormData>();
	const daysOfWeek = Form.useWatch("daysOfWeek", form);

	const addDaysOfWeek = useCallback(
		(days: DayOfWeek[]) => {
			const updatedDaysOfWeek = new Set((daysOfWeek || []).concat(days));
			const valueToSet = Array.from(updatedDaysOfWeek);

			form.setFieldValue("daysOfWeek", valueToSet);
		},
		[daysOfWeek, form]
	);

	const addWeekdays = useCallback(() => addDaysOfWeek(weekdays), [addDaysOfWeek]);
	const addWeekends = useCallback(() => addDaysOfWeek(weekends), [addDaysOfWeek]);

	const onFormSubmit = useCallback(() => {
		const submit = ({ daysOfWeek, timeRange }: SingleBusinessTimeFormData) => {
			const businessTimes = daysOfWeek.map<BusinessTime>((dayOfWeek) => ({
				dayOfWeek,
				opening: timeRange[0].format(format),
				closing: timeRange[1].format(format),
			}));

			onSubmit(businessTimes);
		};

		form.validateFields().then(submit).catch(doNothing);
	}, [form, onSubmit]);

	return (
		<Form form={form} layout="vertical" component={false}>
			<Form.Item name="timeRange" label="Horário" rules={[required]}>
				<TimePicker.RangePicker format={format} minuteStep={15} style={{ width: "100%" }} />
			</Form.Item>

			<Form.Item
				label="Dias"
				name="daysOfWeek"
			>
				<Select
					allowClear
					mode="multiple"
					maxTagCount="responsive"
					options={weekdaysOptions}
					dropdownRender={(menu) => (
						<React.Fragment>
							{menu}

							<ThinDivider />
							<ButtonGroupWrapper>
								<Button.Group>
									<Button block icon={<PlusOutlined />} onClick={addWeekdays}>
										Dias de semana
									</Button>
									<Button block icon={<PlusOutlined />} onClick={addWeekends}>
										Final de semana
									</Button>
								</Button.Group>
							</ButtonGroupWrapper>
						</React.Fragment>
					)}
				/>
			</Form.Item>

			<Button icon={<PlusOutlined />} onClick={onFormSubmit} block>
				Adicionar horário
			</Button>
		</Form>
	);
};
