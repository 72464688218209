import { useHttp } from "@/application/HttpClient";
import { chemicalRecipientService } from "@/services/chemicalRecipient";
import { Form } from "antd";
import { useCallback, useMemo, useState } from "react";
import { RecipientFormData, RecipientFormProps } from "./types";
import { RecipientFormView } from "./view";

type ComponentType = React.FC<RecipientFormProps>;
export const RecipientForm: ComponentType = (props) => {
	const { request } = useHttp();
	const { onSubmitting, onSubmitted, onSubmissionFailed } = props;

	const [form] = Form.useForm<RecipientFormData>();
	const [submitting, setSubmitting] = useState<boolean>(false);

	const initialValue = useMemo(
		() => props.recipient && { ...props.recipient },
		[props.recipient]
	);

	const onSubmit = useCallback(() => {
		if (submitting) return;

		const formData = form.getFieldsValue();

		const doRequest = () =>
			props.recipient
				? request(chemicalRecipientService.edit, {
					chemicalRecipientId: props.recipient.id,
						...formData,
				  })
				: request(chemicalRecipientService.create, formData);

		onSubmitting?.();
		setSubmitting(true);

		doRequest()
			.then((customer) => onSubmitted?.(customer))
			.catch((error) => onSubmissionFailed?.(error))
			.finally(() => setSubmitting(false));
	}, [form, onSubmissionFailed, onSubmitted, onSubmitting, props.recipient, request, submitting]);

	return (
		<RecipientFormView
			form={form}
			submitting={submitting}
			isEditMode={!!props.recipient}
			initialValue={initialValue}
			onSubmit={onSubmit}
		/>
	);
};
