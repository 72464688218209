import { Form } from "antd";
import { useCallback, useMemo } from "react";
import { AddressFormData, AddressFormProps } from "./types";
import { useFormEffects } from "./utilities/useFormEffects";
import { useSubmit } from "./utilities/useSubmit";
import { useZipCodeLocation } from "./utilities/useZipCodeLocation";
import { AddressFormView } from "./view";

type ComponentType = React.FC<AddressFormProps>;
export const AddressForm: ComponentType = (props) => {
	const { onSubmitted, onSubmissionFailed, onSubmitting } = props;

	const [form] = Form.useForm<AddressFormData>();

	const submit = useSubmit(form);
	const zipCodeFeedback = useZipCodeLocation(form);
	const initialValue = useMemo(
		() => props.address || { country: "BR", noComplement: false, noStreetNumber: false },
		[props.address]
	);

	const onSubmit = useCallback(() => {
		onSubmitting?.();
		submit()
			.then((address) => onSubmitted?.(address))
			.catch((error) => onSubmissionFailed?.(error));
	}, [onSubmissionFailed, onSubmitted, onSubmitting, submit]);

	useFormEffects(form);

	return (
		<AddressFormView
			form={form}
			initialValue={initialValue}
			zipCodeFeedback={zipCodeFeedback}
			onSubmit={onSubmit}
			onCancel={props.onCancel}
		/>
	);
};
