import { ChemicalRecipientService } from "./types";

export const chemicalRecipientService: ChemicalRecipientService = {
	list: ({ sortField, sortOrder, filterField, filterText }) => ({
		method: "GET",
		path: `/v1/chemical-recipient?sortField=${sortField}&sortOrder=${sortOrder}&filterField=${filterField}&filterText=${filterText}`,
	}),
	create: (json) => ({
		json,
		method: "POST",
		path: `/v1/chemical-recipient`,
	}),
	filter: ({ chemicalRecipientId }) => ({
		method: "GET",
		path: `/v1/chemical-recipient/${chemicalRecipientId}/available`,
	}),
	byLaundryUnitId: ({ laundryUnitId }) => ({
		method: "GET",
		path: `/v1/chemical-recipient/by-laundry-unit/${laundryUnitId}`,
	}),
	available: () => ({
		method: "GET",
		path: "/v1/chemical-recipient/available",
	}),
	edit: ({ chemicalRecipientId, ...json }) => ({
		json,
		method: "PUT",
		path: `/v1/chemical-recipient/${chemicalRecipientId}`,
	}),
	delete: ({ chemicalRecipientId }) => ({
		method: "DELETE",
		path: `/v1/chemical-recipient/${chemicalRecipientId}`,
	}),
};
