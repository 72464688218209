import { legalEntityPattern, required } from "@/models/rules";
import { Button, Form, Input, Row, Space, Upload } from "antd";
import { LegalEntityNumberInput } from "@/components/LegalEntityNumberInput";
import { UploadOutlined } from "@ant-design/icons";
import { LaundryUnitIdentificationFormViewProps } from "./types";

type ComponentType = React.FC<LaundryUnitIdentificationFormViewProps>;
export const DeviceIdentificationFormView: ComponentType = (props) => {

	const validateEmail = (rule: any, value: any, callback: any) => {
		if (value && !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
			callback("Por favor, insira um email válido.");
		} else {
			callback();
		}
	};

	return (
		<Form
			layout="vertical"
			form={props.form}
			onFinish={props.onSubmit}
			initialValues={props.initialValue}
		>
			<Form.Item name="name" label="Nome" rules={[required]}>
				<Input />
			</Form.Item>
			<Form.Item name="email" label="Email Administrativo" rules={[{ validator: validateEmail }]}>
				<Input />
			</Form.Item>

			<Form.Item name="legalEntityId" label="CNPJ" rules={[required, legalEntityPattern]}>
				<LegalEntityNumberInput />
			</Form.Item>

			<Form.Item name="logo" label="Logo" normalize={(file) => file?.file} rules={[]}>
				<Upload beforeUpload={() => false} accept="image/*">
					<Button ghost block icon={<UploadOutlined />}>
						Selecionar arquivo
					</Button>
				</Upload>
			</Form.Item>
			<Row justify="end">
				<Space>
					{props.onCancel && <Button onClick={props.onCancel}>Cancelar</Button>}

					<Button type="primary" htmlType="submit">
						Salvar
					</Button>
				</Space>
			</Row>
		</Form>
	);
};
